<template>
    <div class="contactsContainer">
        <Header :style="{position: `relative`}"
                :theme="$store.state.theme == 'light' ? 'dark' : 'light'"/>

        <div class="containerMap">
            <YandexMap/>
            <ContactsOfficeCompany/>
            <ContactsRequisites/>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ContactsOfficeCompany from "@/components/ContactsOfficeCompany.vue";
import ContactsRequisites from "@/components/ContactsRequisites.vue";
import YandexMap from "@/components/ui/YandexMap.vue";
import {useHead} from "@vueuse/head"
import store from "@/store";

export default {
    name: "ContactsPage",
    components: {YandexMap, ContactsRequisites, ContactsOfficeCompany, Header, Footer},

    setup() {

        useHead({
            title: `Контакты и реквизиты - ООО «Трансмаш»`,
            meta: [
                {
                    name: `description`,
                    content: `На странице контакты нашего сайта Вы найдете все необходимые данные для связи с нами. Мы предлагаем индивидуальный подход к каждому клиенту и всегда готовы помочь в решении любых вопросов, связанных с горно-шахтным оборудованием`
                },
                {
                    name: `keywords`,
                    content: `контакты, связь, связь с нами, номер телефона, сотрудники, реквизиты, трансмаш, бухгалтерия, отдел снабжения, охрана труда, отдел сбыта, время работы`
                }
            ]

        })
    },
    mounted() {
      // store.commit('checkAdminStatus')
    }
}
</script>

<style scoped>
.contactsContainer {
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100vh;
}

.containerMap {
    padding-top: 55px;
}

</style>