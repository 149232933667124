<template>
    <div class="container">
        <div class="sub-container">
            <slot></slot>
        </div>
    </div>
</template>
  
  <script>


export default {
  name: "WorkerCreator",
  components: {
      
  },

  data() {
      return {
          
      }
  },

  methods: {

  },

  

  props: {
   
  },

  created() {
        
    },

  computed: {
      
  }

}


</script>
  
<style scoped>

.container{
    display: flex;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.40); 
    /* position: fixed */
    z-index: 9999;

    
}

.sub-container{
    width: 1000px;
    max-height: 840px;
    padding-bottom: 2vh;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05), 0px 5px 11px 0px rgba(0, 0, 0, 0.05), 0px 19px 19px 0px rgba(0, 0, 0, 0.04), 0px 43px 26px 0px rgba(0, 0, 0, 0.03), 0px 77px 31px 0px rgba(0, 0, 0, 0.01), 0px 120px 34px 0px rgba(0, 0, 0, 0.00), 0px 0px 33px 0px rgba(0, 0, 0, 0.09); 
}



</style>
