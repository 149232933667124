<template>
    <div class="IndustrialSafetyContainer">
        <Header :style="{position: `relative`}" :theme="$store.state.theme == 'light' ? 'dark' : 'light'"/>

        <DocumentationAboutPage :style="{backgroundColor: `var(--default-bg)`}"
                                :title="$store.state.lang === 'ru-RU' ? industrialSafetysRU : industrialSafetysEN"
                                link="industrialSafety"
                                :is-more="false"
                                :array-element="industrialSafetys"/>


        <Footer/>
    </div>

</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {HTTP} from "@/plugins/http-common";
import DocumentationAboutPage from "@/components/DocumentationAboutPage.vue";

export default {
    name: "IndustrialSafety",
    components: {
        DocumentationAboutPage,
        Header,
        Footer
    },
    data() {
        return {
            industrialSafetys: [],

            industrialSafetysRU: "Охрана труда и промышленная безопасность ",
            industrialSafetysEN: "Occupational health and industrial safety"

        }
    },
    created() {
        HTTP.get(`/safety/get_safety_number/4`).then(response => {
            for (let i = 0; i < response.data.length; i++) {
                // console.log(response.data[i].id);
                this.industrialSafetys[i] = {
                    id: response.data[i].id,
                    titleRU: JSON.parse(response.data[i].device).title_ru,
                    titleEN: JSON.parse(response.data[i].device).title_eng,
                    type: 'safety'
                }
            }
        })
    },
    mounted() {

    }
}
</script>

<style scoped>
.IndustrialSafetyContainer {
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100vh;
}
</style>