import {createStore} from "vuex";
import {HTTP} from "@/plugins/http-common";

export default createStore({
    state: {
        lang: (localStorage.getItem('language') || 'ru') == 'ru' ? 'ru-RU' : 'en-EN',
        theme: 'light',
    },

    getters: {},

    mutations: {
        changeLanguageEn(state) {
            state.lang = 'en-EN';
        },

        changeLanguageRu(state) {
            state.lang = 'ru-RU';
        },

        changeThemeDark(state) {
            state.theme = 'dark';
        },

        changeThemeLight(state) {
            state.theme = 'light';
        },
        checkAdminStatus() {

            if (localStorage.user === 'admin') {
                HTTP.get(`/auth/check_auth?token=${localStorage.token}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (response) {
                    }).catch(() => {
                    localStorage.setItem('user', 'user')
                    localStorage.setItem('token', '')
                    window.open("/admin", "_self");
                })
            }
        }
    },

    actions: {},

    modules: {}
})