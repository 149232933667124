<script setup>
import { useI18n } from 'vue-i18n';
const { t } = useI18n({useScope: 'global'});
</script>

<template>

  <div class="container" >

    <div class="subContainer">

      <div class="imageContainer">

        <div class="black-gradient"></div>
        
      </div>


      <div class="text-container">

        <div class="h1-container">

          <div class="h5"></div>
          <div class="h3"></div>
          
          <div class="btn-date">
            <div class="h4"></div>
            <div class="h4"></div>
          </div>
          

          
          <div class="containerButtonAndDate">
            
          </div>

        </div>
      </div>


    </div>

    <div class="dots" >
      <div
          class="dot"
          :key="index"
          :class="{'picked-dot':index === backgroundNumber}">
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name:'SceletonNewsSlider',
  data(){
    return {


    }
  },
  props: {

  },


}
</script>

<style scoped>
.container  {
  width: 100%;
  height: 70vh;
  background-color: #040405;
  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.subContainer {
  display: flex;
  flex-direction: row-reverse;
  max-width: 1300px;

  height: 100%;
}

.imageContainer {
  width: 100%;
  height: 100%;
}

.black-gradient {
  width: 100vw;
  height: 100%;

  position: absolute;

  background: radial-gradient(circle, rgba(255,255,255,0) 20%, rgba(2,2,2,1) 100%);
  background-position-x: 200px;

  pointer-events: none;

  z-index: 2;
}

@keyframes image-smoother {
  0% {
    transform: translateX(50px) scale(1.1);
    opacity: 0;
  }

  100% {
    transform: translateX(0px) scale(1.1);
    opacity: 1;
  }

  to {
    background-position-x: -200%;
  }
}

.slider-image {
  width: 100vw;
  height: 100vh;
  animation: image-smoother 1s ease-in-out;
  /*background-image: url(@/assets/backgrounds/background1.png);*/

  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;

  /*transform: scale(1.1);*/
  pointer-events: none;

  position: absolute;

  transition: 0.5s;
  background-attachment: fixed;

}


.decorate-gradient {
  width: 500px;
  height: 500px;

  position: absolute;
  z-index: 3;

  top: 40%;
  left: -10%;
  
  background-color: rgba(29,113,184,0.4);
  filter: blur(100px);

  border-radius: 100%;

  background-position: center;
  background-repeat: no-repeat;

  transition: 1s;
}

@keyframes left-opacity {
  0% {
    transform: translateX(-400px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}



.h1-container {
  width: 100%;
  /*margin-top: 3%;*/

  height: 400px;
  padding-left: 20px;
  padding-top: 200px;
  
  display: flex;
  row-gap: 25px;
  /*position: relative;*/
  flex-direction: column;
  overflow: hidden;
}
.text-container {
  /*position: absolute;*/
  overflow: hidden;
  width: 1300px;
  height: 100%;
  display: flex;
  align-items: center;

  z-index: 3;

}

.btn-date{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.h5 {
  height: 100px;
  width: 580px;
  
  /*height: 30%;*/

  padding-top: 5px;

    
    background: linear-gradient(to right, #656871 0%, #888b94 20%, #656871 40%, #656871 100%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;

}

.h3 {
  height: 50px;
 width: 580px;
  
  /*height: 30%;*/

  padding-top: 5px;

    background: #eee;
    background: linear-gradient(to right, #656871 0%, #888b94 20%, #656871 40%, #656871 100%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;

}

.h4 {
  height: 30px;
 width: 240px;
  
  /*height: 30%;*/

  padding-top: 5px;

    background: #eee;
    background: linear-gradient(to right, #656871 0%, #888b94 20%, #656871 40%, #656871 100%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;

}

.containerButtonAndDate {
  display: flex;
  justify-content: space-between;
}

.date {
  /*position: absolute;*/
  /*right: 40px;*/
  /*bottom: 0;*/
  font-size: 20px;
  color: #808080;
}

.readMore {
  /*position: absolute;*/
  /*bottom: 0;*/
  color: white;
  font-size: 20px;
  z-index: 20;
  /*left: 100px;*/
  cursor: pointer;
  height: 38px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
  width: 250px;
  background: none;
  border: 1px solid white;
  border-radius: 15px;
}

.readMore:hover {
  letter-spacing: 2px;
  transition: 0.3s;
}

.dots {
  /*position: absolute;*/
  /*bottom: 32px;*/

  width: 62px;
  height: 14px;

  display: flex;
  justify-content: space-around;
  align-items: center;

  /*left: calc(50% - 31px);*/
  z-index: 3;
  margin: 0 auto;
  padding: 30px 0;
  /* pointer-events: none; */
}

.picked-dot{
  width: 14px !important;
  height: 14px !important;
}
.dot {
  width: 8px;
  height: 8px;

  border-radius: 100%;

  background-color: white;
  cursor: pointer;

  transition: 0.5s;
}


@keyframes top-down-opacity {
  0% {
    transform: translateX(-300px) rotate(-90deg);
    opacity: 0;
  }

  100% {
    transform: translateX(0px) rotate(-90deg);
    opacity: 1;
  }
}

.decorate-circles {
  animation: top-down-opacity .8s ease-in-out forwards;

  opacity: 0;
  left: 0;

  width: 500px;
  height: 500px;
  background-repeat: no-repeat;

  position: absolute;
  bottom: 0;

  background-image: url(@/assets/backgrounds/decorate_circles.svg);
  transform: rotate(-90deg);

  z-index: 3;
}



@media (max-width: 1300px){
  
  
  .h1-container {
    display: flex;
    /*justify-content: center;*/
    flex-direction: column;
    
    margin-left: 50px;
    margin-top: 40px;
  }

  .slider-image {
    background-position-x: 50% !important;
  }

  h1 {
    font-size: 12vw !important;
    
  }

  .text-container{
    padding-left: 100px;
  }
  

  

}

@media (max-width: 1050px){
  .containerButtonAndDate{
    flex-direction: column;
    
  }
  .h1-container{
    align-items: center;
  }

  .btn-date{
    row-gap: 10px;
    flex-direction: column;
    
    
  }
  
  .date{
    margin-top: 20px;

  }

  .h3{
    width: 400px;
  }

  .h5{
    width: 400px;
  }
}

@media (max-width: 900px){
  .imageContainer{
    width: 100%;
    position: absolute;
  }

  .text-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
     
  }

  .btn-date{
    flex-direction: column;
    align-items: center;
  }

  .h1-container{
    width: 100%;
    margin-left: 0px;
  }

  .h1-container h2{
    text-align: center;
    font-size: 28px;
  }

  .h1-container span{
    text-align: center;
    font-size: 18px;
  }
  
  .decorate-gradient {
    display: none;
  }

  .black-gradient{
    display: none;
  }

  .containerButtonAndDate{
    flex-direction: column;
    align-items: center;
  }

  .h3{
    width: 380px;
  }

  .h5{
    width: 380px;
  }
}



@media (max-width: 550px){
  .h1-container{
    width: 80%;
    
    justify-content: center;
    align-items: center;
    padding-left: 0%;
    padding-bottom: 200px;
  }

  .btn-date{
    padding-top: 50px;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    row-gap: 10px;
    align-items: center;
  }

  .h5 {
  height: 20px;
    
  
  /*height: 30%;*/

  padding-top: 60px;
    opacity: 0;
    
    
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;

}

.h3 {
  height: 250px;
   width: 70%;
  
  /*height: 30%;*/

  padding-top: 50px;

    
    
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;

}

.h4 {
  height: 120px;
 width: 70%;
  
  /*height: 30%;*/

  padding-top: 5px;

    
    
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;

}
}

@media (max-width: 400px){
  .h1-container h2{
    
    font-size: 24px;
  }

  .h1-container span{
    
    font-size: 18px;
  }

  .dots{
    height: 20px;
  }
}


</style>
 
