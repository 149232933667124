<template>
    <div class="container">
        <div class="sub-container">
            <div v-for="(element, index, number) in 1" class="flex-container">
                <!--              {{element[0]}}-->

                <h2>
<!--                    {{element[1]}}-->
                </h2>

                <div class="paragraph"></div>

                <div v-if="element[0] === 'list'">
                    <ul v-for="(render) in 1">
                        <li class="list">
                            {{render}}
                        </li>
                    </ul>
                </div>

                <div v-if="element[0] === 'table'">
                    <table>
                        <tr>
                            <th v-for="(columnName) in element[1]"
                                class="column"
                            >
                                {{columnName}}
                            </th>
                        </tr>

                        <tr v-for="(rowName, i ) in element.slice(2)"
                            class="row"
                        >
                            <td v-for="(rowNames, number) in rowName"
                                class="rowTD"
                            >
                                {{rowName[number]}}
                            </td>
                        </tr>
                    </table>
                </div>
                <div v-if="element[0] === 'image'"
                     class="imageContainer">
                    <div
                            v-for="(image, imageNumber) in 1"
                            class="image"
                            :style="{backgroundImage: `url('` + image + `')`}"
                    ></div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: "SkeletonParser",

    data () {
        return {
            ArrayLength: 2
        }
    },
    props: {
        header: {
            type: Array,
            default: []
        },

    },


}
</script>

<style scoped>
.container {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    align-items: start;
    color: var(--text);
    background: var(--default-bg);
}
.sub-container {
    width: 1000px;
    display: flex;
    flex-direction: column;
    /*row-gap: 20px;*/
    justify-content: center;
    column-gap: 30px;
}
.flex-container {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}
table {
    color: black;
}
h2 {
    font-weight: 500;
    padding-top: 20px;
    font-size: 25px;
    padding-bottom: 10px;
    background: var(--sceleton-card);
    animation: 1.5s shine linear infinite;
    background-size: 200% 100%;
    height: 50px;
}
.paragraph {
    text-indent: 20px;
    background: var(--sceleton-card);
    animation: 1.5s shine linear infinite;
    background-size: 200% 100%;
    height: 500px;
}
.list {
    /*text-indent: 20px*/
    padding-left: 25px;
}
th {
    font-weight: 300;
    /*font-size: 20px;*/
    background: #DFEFFD;
    text-align: start;
    padding: 10px 5px;

}
.row:nth-child(even) {
    background: #FFFFFF;

}
.row:nth-child(odd) {
    background: #DFEFFD;
}
th:not(:first-child) {
    text-align: center;
}

th:last-child {
    text-align: end !important;
}

.rowTD:last-child {
    text-align: end !important;
}

td {
    padding: 10px 5px;
}
.rowTD:not(:first-child) {
    text-align: center;
}
.imageContainer {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-top: 20px;
}
.image {
    width: 100%;
    height: 280px;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
</style>