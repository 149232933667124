<template>
    <div class="modal-header">
        <h3>{{ title == "Документация" ? "Добавить новую документацию" : "Добавить новый сертификат" }}</h3>
        <img src="@/assets/icons/exit.svg" 
                alt=""
                @click="exitBtn"
                class="exitBtn"
        >
    </div>
    <div class="form-container">
        <form action="" @submit.prevent="onSubmit">
        <div class="fio-row">
            <p class="fio-text">Наименование</p>
            <div class="fioInputContainer">
                <input class="fio-input" type="text" id="fio" v-model="pdfName.title_ru">
                
            </div>

        </div>
       
        <div
            class="image-input-container"
            @drop="handleDropPDF"
            @dragenter="handleDragEnterPDF"
            @dragleave="handleDragLeavePDF"
            @dragover.prevent

            :class="{ 'drag-over': isDraggingDocument }"
        >

           
            <div class="image-input-sub-container">
                <div class="input-image-text">Вы можете перетащить или</div>
                <input type="file" ref="fileInputPDF"  @change="handleFileInputPDF" style="display:none;">
                <button class="input-image-button" @click="triggerFileInputPDF">Открыть на компьютере</button>
                <div class="input-image-text">Файл(ы) в формате pdf в эту область.</div>
                <div class="input-image-text">Максимальный размер файла 20Мб</div>
            </div>

            <div class="uploaded-images">
                <div v-if="uploadedNamesDocument !== '' && headerImageBase64Document !== '' ">
                    {{ uploadedNamesDocument }}
                    <button class="delete-button"
                            @click="clean"></button>
                </div>
                <img v-if="uploadedNamesDocument !== '' && headerImageBase64Document !== '' " src="@/assets/pdf.svg" alt="">

            </div>

        </div>


            
            <div class="btn-container">
                <button 
                    @click="public"  
                    class="btn"
                    :disabled="this.requestStatus == 'loading'"
                    :style="this.requestStatus == 'loading' ? {opacity: `0.5`} : {}"
                >
                    Coхранить
                </button>
            </div>
            <!-- <div v-if="editStatus == false" class="btn-container">
                <button @click="public"  class="btn">Coхранить</button>
            </div> -->
            <!-- <div v-else class="btn-container">
                <button @click="edit(element)"  class="btn">Изменить</button>
            </div> -->

            
       

        </form>
    </div>

</template>

<script>

import {HTTP} from "@/plugins/http-common";



export default {
    name:'AddSafetyModal',
    components: {
        
    },
    data() {
        return {
    
            pdfFile: {},
            requestStatus: '',
            uploadedNamesDocument: '',
            pdfName: {
                        title_ru : '',
                        title_eng: ''
                     }
            
        }
    },
    props: {
        title: {
        type: String,
        default: "",
        
        },
        

    },

    methods: {
        exitBtn() {
            this.$emit('exit')
        },
        handleDropPDF(event) {
          event.preventDefault();
          const files = event.dataTransfer.files;
            const file = files[0];
            if (file.type.startsWith('application/pdf')) {
             const reader = new FileReader();
                reader.onload = (e) => {
                    this.uploadedNamesDocument = file.name
                    this.pdfFile = file
                };
                reader.readAsDataURL(file);
            }
          this.isDraggingDocument = false;
        },
        handleDragEnterPDF(event) {
            event.preventDefault();
            this.isDraggingDocument = true;
        },

        handleDragLeavePDF(event) {
            event.preventDefault();
            this.isDraggingDocument = false;
        },


        handleFileInputPDF(event) {
            const file = event.target.files[0];
            if (file.type.startsWith('application/pdf')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.uploadedNamesDocument = file.name
                    this.pdfFile = file
                };
                reader.readAsDataURL(file);
            }
        },

        triggerFileInputPDF() {
          this.$refs.fileInputPDF.click(); // вызов события click на скрытом инпуте файла
        },

        clean(){
            this.uploadedNamesDocument = ''
            this.pdfFile = {}
            this.$refs.fileInputPDF.value = null;

            console.log(this.uploadedNamesDocument,this.headerImageBase64Document);
        },

        public() {
            if (this.pdfName.title_ru !== '') {
                if (this.title == 'Документация') {
                    this.requestStatus = 'loading'
                    HTTP.post('/document/create_document', {
                        images: this.pdfFile ,
                        title: JSON.stringify({"title_ru": this.pdfName.title_ru, "title_eng": this.pdfName.title_eng}),
                    
                    }, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    }).then(response => {
                        console.log("Успешно");
                        this.requestStatus = '200'
                        window.location.href = "/safety";


                    }) 
                }else{
                    this.requestStatus = 'loading'
                    HTTP.post('/certificate/create_certificate', {
                        images: this.pdfFile ,
                        device: JSON.stringify({"title_ru": this.pdfName.title_ru, "title_eng": this.pdfName.title_eng}),
                    
                    }, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    }).then(response => {
                        console.log("Успешно");
                        this.requestStatus = '200'
                        window.location.href = "/safety";
                        

                    }) 
                }
                
            }
            
        },

       
        
    },
    created() {
       
    },
    updated() {
    },

   


}

</script>

<style scoped>
.modal-header{
    width: 100%;
    display: flex;
    justify-content: center;
}

.exitBtn{
    position: absolute;
    margin-left: 95vh;
    margin-top: 25px;
    cursor: pointer;
}

h3{
    font-size: 30px;
    margin-top: 20px;
}

p{
    font-size: 20px;
}

.form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

form {
    display: flex;
    flex-direction: column;
    width: 95%;
    row-gap: 30px;
    padding-left: 40px;
    padding-top: 40px;
    
}

.header-text {
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding-bottom: 40px;
}

h2 {
    text-transform: uppercase;
}

.fio-row {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.fioInputContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    
}

.fio-text {
    padding-right: 20px;
}


.fio-input {
    width: 96%;

}

input {
    background-color: #ecf0f1;
    border-radius: 16px;
    width: 100%;
    padding-left: 15px;
    border: none;
    height: 46px;
    box-sizing: border-box;
    font-size: 20px;
}

input:focus {
    border: 2px solid #C9C9C9;
    outline: 0;
    background-color: #FFF;
}




.message-title {
    border: 2px solid #C9C9C9;
    border-radius: 15px;
    padding: 5px;
}


.message-input:focus {
    border: 2px solid #C9C9C9;
    outline: 0;
}

.btn-container {
    display: flex;
    justify-content: center;
    margin-top: -20px;
}

.btn {
    width: 200px;
    height: 40px;
    border: 2px solid #1D71B8;

    background-color: #FFF;
    border-radius: 15px;
    margin-top: 20px;
    color: #1E70B6;
    font-size: 20px;
    transition: 0.3s;
}

.btn:hover {
    letter-spacing: 2px;

}

.exit-container {
    width: 100%;

    display: flex;

    justify-content: end;
    align-items: flex-start;


}

.exit {
    padding-right: 15px;

    cursor: pointer;
    height: 20px;
    width: 20px;
}

.image-input-container {
    box-sizing: border-box;
    width: 95%;
    height: 310px;
    border-radius: 12px;
    border: dashed 2px #C9C9C9;
    margin-top: 16px;
    padding-top: 20px;

    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: row-reverse;

    /* gap: 30px; */
}

.image-input-sub-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    row-gap: 0.5vh;
    padding-right: 260px;
}

.input-image-text {
    font-size: 20px;
    color: rgb(0, 0, 0, 0.4);
    text-align: center;
}

.input-image-button {
    background-color: #1D71B8;
    font-size: 20px;
    color: white;

    width: 360px;
    padding: 8px 0px;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
}

.text-input-container {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    min-height: 200px;
    border-radius: 12px;
    border: solid 2px #C9C9C9;
    background: white;
    margin-top: 16px;

}

.preview-container {
    width: 100%;
    height: auto;
    margin-top: -180px;

    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;

}

.sub-preview-container {
    width: 100%;
    height: calc(100% - 80px);
    box-shadow: 0px 0px 12px rgb(0, 0, 0, 0.3);
    transform: scale(0.71);

}

.drag-over {
    transition: 0.2s;
    border-width: 3px !important;
    background-color: rgba(0, 166, 255, 0.1);
    border-color: #1D71B8;
}

.uploaded-images {
    width: 100%;
    /* height: 64px; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    /* background-color: red; */
}

.uploaded-images > div {
    width: 128px;
    height: 32px;

    background: white;
    border-radius: 10px;

    box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.3);
    text-overflow: ellipsis;
    white-space: nowrap;

    align-items: center;
    padding-right: 32px;
    padding-top: 8px;
    padding-left: 8px;
    overflow: hidden;
    position: relative;
}

.input-image-text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -30px;
    row-gap: 5px;
    
}

.base64Image-container{
    margin-left: -20px;
}

.base64Image {
    width: 220px;
    /*height: 128px;*/
    height: 220px;

    box-sizing: border-box;

    border-radius: 5px;
    background-color: rgb(127, 127, 127);

    /*cursor: pointer;*/

    background-position: center;
    background-size: cover;

    box-shadow: 1px 2px 12px rgb(0, 0, 0, 0.3);
}

.delete-button {
    width: 16px;
    height: 16px;

    cursor: pointer;

    background-color: rgb(0, 0, 0, 0);
    border: none;
    outline: none;
    background-image: url('@/assets/icons/exit.svg');
    background-size: cover;

    position: absolute;
    right: 10px;
    top: 12px;
}


@keyframes shine {
    to {
        background-position-x: -200%;
    }
}


</style>