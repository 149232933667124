<template>
    <div class="container">
      <div class="sub-container">
        <div>
          <h2>
            {{titleDescription}}
          </h2>

          <p class="description">{{description}}</p>
        </div>

        <div class="DistinctiveFeatures">
          <p>
            {{titleDistinctiveFeatures}}
          </p>
          <p v-for="index in ArrayList.length">
            {{ArrayList[index]}}
          </p>
        </div>

        <div class="SystemComposition">
          <p>
            {{titleSystemComposition}}
          </p>
          <p>{{SystemComposition}}</p>
        </div>

        <div class="ScopeOfApplication">
          <p>
            {{titleScopeOfApplication}}
          </p>
          <p>{{ScopeOfApplication}}</p>
        </div>

      </div>
    </div>
</template>

<script>

export default {
  name: "DescriptionProduct",


  props: {
    titleDescription: {
      type: String,
      default: ''
    },
    ArrayList: {
      type: Array,
      default: []
    },
    description: {
      type: String,
      default: '123'
    },
    titleDistinctiveFeatures: {
      type: String,
      default: ''
    },
    titleSystemComposition: {
      type: String,
      default: ''
    },
    titleScopeOfApplication: {
      type: String,
      default: ''
    },
    ScopeOfApplication: {
      type: String,
      default: ''
    },

    SystemComposition: {
      type: String,
      default: ''
    },
    // SystemComposition: {
    //   type: String,
    //   default: ''
    // },
  },

}
</script>

<style scoped>
    .container {
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: start;
      color: var(--text);
      background: var(--about-bg);
      padding-top: 50px;
      /*scroll-snap-align: center;*/
    }

    .sub-container {
      width: 1300px;
      display: flex;
      flex-direction: column;
      /*justify-content: center;*/
    }

    h2 {
      font-weight: 500;
      font-size: 25px;
      /*line-height: 120%;*/
    }
    .description {
      padding-top: 20px;
      /*padding-bottom: 50px;*/
    }

    .DistinctiveFeatures {
      padding: 20px 0;
    }

    .SystemComposition {
      padding-bottom: 20px;
    }
    .ScopeOfApplication {
      padding-bottom: 40px;
    }
</style>