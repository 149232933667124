<template>
  <div class="container" ref="UpToTop">
    <div class="sub-container">
      <div class="container-flex-row">
        <div class="container-flex-column-left">
          <div class="vacancy-name">{{data[index].title}}</div>
          <div class="container-city-time">
            <div class="city">
              <div class="icons" :style="{backgroundImage: `url(${require('@/assets/icons/location.png')})`}"></div>
              {{data[index].city}}
            </div>
            <div class="time">
              <div class="icons" :style="{backgroundImage: `url(${require('@/assets/icons/clock.png')})`}"></div>
              {{data[index].work_time}}
            </div>
          </div>
          <div v-show="data[index].remote_work"
               class="container-remote-work"
          >
            <div class="icons"
                 :style="{backgroundImage: `url(${require('@/assets/icons/PC.png')})`}"></div>
            Удаленная работа
          </div>
          <div class="added">
            Добавлено {{data[index].created_at?.replace( /\-/g, '.') }}
          </div>
        </div>

        <div class="container-flex-column-right">
          <div class="payday">
            от {{data[index].salary_min}} до {{data[index].salary_max}} руб.
          </div>
          <div class="month">
            {{ data[index]?.salary_type.toLowerCase() }}
          </div>
          <CustomButton :link="'/vacancy/' + data[index].id"
                        :style="{width: `220px`, height: `40px`}"
                        button-text="Узнать больше"
                        @click="upToTop"
          />
        </div>
      </div>

    </div>
    <div v-show="user === 'admin'"
         class="container-button-delete-edit"
    >
      <router-link :to="`/editVacancy/${data[index].id}`">
        <button>
          <img src="../ui/assets/pencil.png" alt="">
        </button>
      </router-link>
      <button @click="deletePost(data[index].id)">
        <img src="../ui/assets/delete.png" alt="">
      </button>
    </div>
  </div>
</template>

<script>
  import CustomButton from "@/components/ui/CustomButton.vue";
  import {HTTP} from '@/plugins/http-common'

  export default {
    name: 'ParserVacancy',
    components: {CustomButton},

    props: {
      data: {},
      index: {},
    },

    data() {
      return {
        user: "user"
      }
    },
    methods: {
      upToTop() {

      },

      deletePost(id){
        HTTP.delete('/vacancies/delete_vacancies/' + id,
        {headers: {
            'Content-Type': 'application/json'
        }})
        .then(function (response) {
            // console.log("Удалена запись с айди №" + id);
            location.reload()
        }).catch(() => {
            this.wrongData = true;
        })
      }
    },
    mounted() {
      if(localStorage.user) this.user = localStorage.user;
    }
  }
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  column-gap: 10px;
  color: var(--text);
  height: 100%;
}

.sub-container {
  width: 90%;
  background: var(--card-bg);
  box-shadow: var(--card-box-shadow);
  border-radius: 15px;
  padding: 15px;
}
.container-button-delete-edit {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.container-button-delete-edit button {
  padding: 4px;
  border-radius: 5px;
  background-color: white;
  border: solid 1px #ccc;
  cursor: pointer;
}

.container-button-delete-edit button img{
  width: 20px;
  height: 20px;
}
.vacancy-name {
  font-size: 25px;
  width: 95%;
  font-weight: 600;
}
.container-flex-column-left {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.container-flex-column-right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: end;
}
.container-flex-row {
  display: flex;
  justify-content: space-between;
}
.container-city-time {
  display: flex;
  font-size: 20px;
  font-weight: 400;
  column-gap: 15px;
}
.container-remote-work {
  display: flex;
  column-gap: 5px;
  font-size: 20px;
  font-weight: 400;
  align-items: center;
}
.city {
  display: flex;
  column-gap: 5px;
  align-items: center;
}
.time {
  display: flex;
  column-gap: 5px;
  align-items: center;
}
.icons {
  width: 21px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 21px;
}
.payday {
  font-size: 25px;
  font-weight: 600;
}
.added {
  font-size: 18px;
  font-weight: 400;
  color: #808080;
}
.month {
  display: flex;
  justify-content: end;
  color: #808080;
  margin-top: -15px;
}
@media (max-width: 1100px) {
  .container-flex-column-right {
    align-items: center;
    row-gap: 20px;
  }
  .container-flex-row {
    justify-content: stretch;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
  }
  .container-flex-column-left {
    align-items: center;
  }
}
@media (max-width: 450px) {
  .sub-container {
    width: 85%;
    font-size: 20px;
  }
  .vacancy-name {
    font-size: 20px;
  }
  .container-city-time {
    font-size: 15px;
  }
  .added {
    font-size: 15px;
  }
  .payday {
    font-size: 20px;
  }

}
</style>