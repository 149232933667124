<template>
  <div class="custom-checkbox" @click="toggleChecked">
    <div :class="['checkbox', { 'checked': isChecked }]">
      <div v-if="isChecked" class="checkmark">
        <span>&#10003;</span>
      </div>
    </div>
    <label>{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isChecked: this.value
    };
  },
  watch: {
    value(newValue) {
      this.isChecked = newValue;
    },
    isChecked(newValue) {
      this.$emit('input', newValue);
    }
  },
  methods: {
    toggleChecked() {
      this.isChecked = !this.isChecked;
      this.$emit('change', this.isChecked);
    }
  }
};
</script>

<style scoped>
.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.checkbox.checked {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

.checkmark {
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
</style>