<template>
  <div class="card">
    <div class="imageContainer">
      <img :src="require('@/assets/cardServices/' + srcImage)">
    </div>
    <div class="textContainer">
      <p class="text">{{text}}</p>
      <span>{{aboutAs}}</span>
      <CustomButton :style="{marginTop: `10px`}" :button-text="$t('Buttons.more')"/>
    </div>

  </div>
</template>

<script>
import CustomButton from "@/components/ui/CustomButton.vue";

export default {
  name: "Card",
  components: {CustomButton},
  props: {
    text: {
      type: String,
      default: 0
    },
    srcImage: {
      type: String,
      default: ""
    },
    aboutAs: {
      type: String,
      default: ""
    }

  },

  data() {
    return {

    }
  }
}
</script>

<style scoped>
.card {
  width: 300px;
  height: 420px;
  background: var(--card-bg);
  box-shadow: var(--card-box-shadow);
  transition: 0.5s;
  border-radius: 15px;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.text {
  font-weight: 400;
  /*line-height: 1.2;*/
  color: var(--text);
  width: 80%;
  overflow: hidden;
  margin-top: 15px;
  text-align: center;
}

span {
  /*display: none;*/
  width: 0;
  opacity: 0;
  height: 0;
  /*ширену 0 opacity 0*/
  color: var(--text);
}
.textContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageContainer > img {
  border-radius: 10px;
  height: 90%;
  width: 90%;
}
</style>