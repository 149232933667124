<template>
    <div class="newsIDContainerPage">
        <Header style="position: relative" :theme="$store.state.theme == 'light' ? 'dark' : 'light'"/>
        <SkeletonAdditionalNews v-if="arrayNews.created_at == '' || array_for_parser == '' "/>
        <div v-else>
            <div class="newsIDContainer">
                <div class="newsIDSubContainer">
                    <div class="tite-container">
                        <h1>{{ $store.state.lang == 'ru-RU' ? arrayNews.newsTitleRU : arrayNews.newsTitleEN }}</h1>
                    </div>
                    <p class="date">
                        {{
                        arrayNews.created_at
                        }}
                    </p>
                    <div class="containerImage">
                      <div
                          :style="{backgroundImage: `url('` + arrayNews.images + `')`}"
                          class="base64ImageBlur"
                      >
                      </div>
                      <div
                          :style="{backgroundImage: `url('` + arrayNews.images + `')`}"
                          class="base64Image"
                      >
                      </div>
                    </div>

                </div>
            </div>

            <Parser :header="array_for_parser"></Parser>

            <div class="otherNews">
                <h2 class="otherNewsTitle">Другие статьи</h2>
                <div class="otherNewsFlexRow" >
                    <div class="otherNewsContainer"
                         v-for="(element, index) in arrayOtherNews"
                         :key="index"
                    >
                      <div>
                        <div
                            :style="{backgroundImage: `url('` + arrayOtherNews[index].imageOtherNews + `')`}"
                            class="otherNewsImage"
                        >

                        </div>
                        <p class="otherNewsTitle">
                          {{ $store.state.lang == 'ru-RU' ? arrayOtherNews[index].newsOtherTitleRU : arrayOtherNews[index].newsOtherTitleEN }}
                        </p>
                        <div class="date-link-container">
                            <p class="dateOther">
                                {{
                                arrayOtherNews[index].created_at?.toString().split(' ')[0].split('-').join('/') + ' '
                                + arrayOtherNews[index].created_at?.toString().split(' ')[1].split(":")[0] + ':'
                                + arrayOtherNews[index].created_at?.toString().split(' ')[1].split(":")[1]
                                }}
                            </p>
                            <router-link class="linkToOtherNews"
                                         :to="{ name: 'newsId', params: { id:  arrayOtherNews[index].otherNewsID}}">
                                {{ $t('Buttons.more') }}
                            </router-link>
                        </div>
                      </div>

                    </div>
                </div>
            </div>
        </div>

        <Footer/>
    </div>

</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {HTTP} from "@/plugins/http-common";
import MicroNews from "@/components/MicroNews.vue";
import Parser from "@/components/Parser/Parser.vue";
import SkeletonAdditionalNews from "@/components/Sceletons/SkeletonAdditionalNews.vue";
import {useHead} from "@vueuse/head"

export default {
    name: "News",
    components: {SkeletonAdditionalNews, MicroNews, Header, Footer, Parser},
    props: {
        id : {},
        images: '',
        
    },
    inheritAttrs: false,
    data() {
        return {
            arrayNews: [],
            arrayOtherNews: [],

        }
    },
    methods: {
        scrollToTop() {
        }
    },

    created() {

        HTTP.get(`/news/get_news_by_id/${this.id}${this.$store.state.lang == 'ru-RU' ? 'ru' : 'eng'}`).then(response => {
            useHead({
                title: response.data.title,
                meta: [
                    {
                        name: `description`,
                        content: JSON.parse(response.data?.description)?.description
                    },
                    {
                        name: `keywords`,
                        content: JSON.parse(response.data?.keywords)?.keywords.join(', ')
                    }
                ]

            })
            this.arrayNews = {
              created_at: response.data.created_at,
              newsTitleRU: response.data.title,
              images: ''
            }
            // console.log(JSON.parse(response.data.image).image);

            const imagePath = JSON.parse(response.data.image).image;
            const formData = new FormData();
            formData.append('image', imagePath);
            HTTP.post('/get_image/download_image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            responseType: 'blob'
            }).then(responseImage => {
                const url = URL.createObjectURL(responseImage.data);  
                this.arrayNews.images = url
                        
            }).catch(error => {
                console.error('Ошибка при загрузке изображения:', error);
            });

            

            this.array_for_parser = response.data.body
            this.$watch(
                () => this.id,
                async () => {
                    this.arrayNews = []
                    HTTP.get(`/news/get_news_by_id/${this.id}${this.$store.state.lang == 'ru-RU' ? 'ru' : 'eng'}`).then(response => {
                      this.arrayNews = {
                        created_at: response.data.created_at,
                        newsTitleRU: response.data.title,
                        images: JSON.parse(response.data.image).image
                      }
                        this.array_for_parser = response.data.body
                        const imagePath = this.arrayNews.images;
                        const formData = new FormData();
                        formData.append('image', imagePath);
                        HTTP.post('/get_image/download_image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        responseType: 'blob'
                        }).then(responseImage => {
                            const url = URL.createObjectURL(responseImage.data);  
                            this.arrayNews.images = url
                                    
                        }).catch(error => {
                            console.error('Ошибка при загрузке изображения:', error);
                        });
                    })
                    document.getElementsByClassName('newsIDContainerPage')[0].scrollTop = 0


                }
            )
        }).catch(err => {
            console.log('Ошибка в запросе', err);
        })

        HTTP.get(`/news/get_all_news/0/3`).then(response => {
          this.arrayOtherNews = response.data.data.map((item, index) => {
            return {
              otherNewsID: item.id,
              newsOtherTitleRU: JSON.parse(item.body).body_ru[0][1],
              newsOtherTitleEN: JSON.parse(item.body).body_eng[0] === undefined ? '' : JSON.parse(item.body).body_eng[0][1],
              created_at: item.created_at,
              imageOtherNews: JSON.parse(item.image).image
            }
          })
          
          for (let index = 0; index < this.arrayOtherNews.length; index++) {

            const imagePath = this.arrayOtherNews[index].imageOtherNews;
            
            const formData = new FormData();
            formData.append('image', imagePath);
            HTTP.post('/get_image/download_image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            responseType: 'blob'
            }).then(responseImage => {
                const url = URL.createObjectURL(responseImage.data);   
                this.arrayOtherNews[index].imageOtherNews = url
                        
            }).catch(error => {
                console.error('Ошибка при загрузке изображения:', error);
            });
          }


        }).catch(err => {
          console.log('Ошибка в запросе', err);
        })



    },


}


</script>

<style scoped>
.newsIDContainerPage {
    position: relative;
    overflow-y: scroll;
    color: var(--text);
    overflow-x: hidden;
    height: 100vh;
    /*scroll-snap-type: y proximity;*/
}

.newsIDContainer {
    display: flex;
    color: var(--text);
    padding: 55px 0 60px 0;

    justify-content: center;
    align-items: center;
}

.newsIDSubContainer {
    width: 1000px;
    /*height: 50vh;*/

    display: flex;
    row-gap: 25px;
    justify-content: space-between;
    flex-direction: column;
}

.title-container {
    width: 100%;
}

h2, h1 {
    font-size: 48px;
    font-weight: 500;
}

.date {
    color: #808080;
    font-size: 25px;
    font-weight: 400;
}

.base64Image {
  width: calc(100% - 15px);
  height: calc(100% - 15px);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: 2;
}


.containerImage {
  min-width: 128px;
  border-radius: 5px;
  /* width: 500px; */
  height: 600px;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 2px 12px rgb(0, 0, 0, 0.3);
}
.base64ImageBlur {
  width: 100%;
  height: 100%;
  position: absolute;
  filter: blur(20px);
  background-size: cover;
  transform: scale(1.2);
  background-position-y: center;
}
.paragraph {
    font-size: 25px;
    font-weight: 400;
}

.paragraph:last-child {
    padding-bottom: 55px;
}

.otherNews {

    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 80px;
}

.otherNewsFlexRow {
    display: flex;
    column-gap: 25px;
    row-gap: 35px;
    justify-content: center;
}

.otherNewsImage {
    width: 300px;
    height: 200px;

    box-sizing: border-box;

    border-radius: 5px;
    background-color: rgb(127, 127, 127);

    /*cursor: pointer;*/

    background-position: center;
    background-size: cover;

    box-shadow: 1px 2px 12px rgb(0, 0, 0, 0.3);
}

.dateOther {
    color: #808080;
    font-weight: 400;
    overflow: hidden;
}

.otherNewsContainer {
    width: 315px;
    display: flex;
    position: relative;
    height: 350px;
    row-gap: 15px;
    flex-direction: column;
}

.linkToOtherNews {
    color: var(--blue);
    overflow: hidden;
    bottom: 0;
}

.otherNewsTitle {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    -webkit-box-orient: vertical;

}

.paragraph.skeleton {

    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}


@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

@media (max-width: 1300px) {
    .newsIDSubContainer {
        width: 90%;
    }


    h2, h1 {
        text-align: center;
        font-size: 4.5vh;
    }


    .date {
        font-size: 3vh;
    }

    .base64Image {
        height: 60vh;
    }
}

@media (max-width: 1050px) {
    .otherNewsFlexRow {
        max-width: 1050px;
        flex-wrap: wrap;

    }

    .otherNewsContainer {
        width: 300px;
    }


}

@media (max-width: 644px) {

    .otherNews {
        justify-content: center;
        display: flex;
        align-items: center;

    }

    .otherNewsFlexRow {
        width: 90%;
        height: 100%;
        flex-direction: column;
        align-items: center;


    }

    .otherNewsTitle {
        height: auto;

    }

    .date-link-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 40px;
        justify-content: space-between;
        overflow: hidden;
    }

    .dateOther {
        font-size: 14px;

    }

    .otherNewsContainer {
        width: 90%;
        height: auto;
    }


    .otherNewsImage {
        width: 100%;
        height: 300px;

    }
}

@media (max-width: 550px) {
    .base64Image {
        height: 40vh;
    }

    .otherNewsImage {
        width: 100%;
        height: 40vh;

    }
}
</style>
