<template>
  <div class="container">
      <div class="sub-container">
          <div class="gif" :style="{
            zIndex: 10,
            backgroundImage: `url(${require('../assets/aboutPage/product/UTZSH-01.gif')})`,
          }"
          >

          </div>
<!--          <img src="../assets/aboutPage/product/UTZSH-01.gif" alt="">-->
          <div class="containerText">
              <p>
                <span>
                    {{$t('AboutPage.product.UTZSH-01.aboutProductHistory-first-color')}}
                </span>
                {{$t('AboutPage.product.UTZSH-01.aboutProductHistory-first')}}
              </p>

              <p>
                {{$t('AboutPage.product.UTZSH-01.aboutProductHistory-two')}}
              </p>

              <p>
                {{$t('AboutPage.product.UTZSH-01.aboutProductHistory-three')}}
              </p>

              <CustomButton link="/product/38" :buttonText="$t('Buttons.UTZSH-01')"/>
          </div>
      </div>

    <div class="sub-container">
<!--      <img src="../assets/aboutPage/product/AsuScan.png" alt="">-->
      <div class="image" :style="{
            zIndex: 10,
            backgroundImage: `url(${require('../assets/aboutPage/product/AsuScan.webp')})`,
          }"
      >

      </div>

      <div class="containerText">
        <p>
                <span>
                    {{$t('AboutPage.product.AsuScan.aboutProductHistory-two-color')}}
                </span>
          {{$t('AboutPage.product.AsuScan.aboutProductHistory-firstASU')}}
        </p>
        <CustomButton link="/product/58" :buttonText="$t('Buttons.AsuScan')"/>
      </div>
    </div>

    <div class="sub-container">
<!--      <img src="../assets/aboutPage/product/UTZSH-11.png" alt="">-->
      <div class="image" :style="{
            zIndex: 10,
            backgroundImage: `url(${require('../assets/aboutPage/product/UTZSH-11.webp')})`,
          }"
      >

      </div>
      <div class="containerText">
        <p>
          {{$t('AboutPage.product.UTZSH-11.aboutProductHistory-first-UTZSH-11')}}
          <span>
            {{$t('AboutPage.product.UTZSH-11.aboutProductHistory-three-color')}}
          </span>
          {{$t('AboutPage.product.UTZSH-11.aboutProductHistory-two-UTZSH-11')}}
        </p>
        <CustomButton link="/product/38" :buttonText="$t('Buttons.UTZSH-11')"/>
      </div>
    </div>

    <div class="sub-container" :style="{columnGap:`60px`}">
<!--      <img src="../assets/aboutPage/product/KPS-M.png" alt="">-->
      <div class="image" :style="{
            zIndex: 10,
            backgroundPositionY: `-70px`,
            backgroundImage: `url(${require('../assets/aboutPage/product/KPS-M.webp')})`,
          }"
      >

      </div>
      <div class="containerText" >
        <p>
          {{$t('AboutPage.product.KPS-M.aboutProductHistory-first-KPS-M')}}
          <span>
            {{$t('AboutPage.product.KPS-M.aboutProductHistory-four-color')}}
          </span>
          {{$t('AboutPage.product.KPS-M.aboutProductHistory-two-KPS-M')}}
        </p>

        <p>
          {{$t('AboutPage.product.KPS-M.aboutProductHistory-three-KPS-M')}}

        </p>
        <CustomButton link="/product/48" :buttonText="$t('Buttons.KPS-M')"/>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "AboutProductHistory"
}
</script>

<style scoped>
  .container {
    width: 100%;
    
    display: flex;
    justify-content: center;
    margin-top: 60px;

    align-items: center;
    color: var(--text);
    background: var(--default-bg);
    scroll-snap-align: center;
    flex-direction: column;
  }
  .sub-container {
    max-width: 1200px;
    column-gap: 30px;
    display: flex;
    justify-content: center;
  }
  .image {
    background-size: contain;
    background-position: center;
    width: 500px;
    /*background-size: 100% 100%;*/
    background-repeat: no-repeat;
  }
  .containerText {
    display: flex;
    width: 40%;
    flex-direction: column;
    row-gap: 20px;
    align-items: start;
  }

  .gif{
    display: flex;
    width: 40%;
    flex-direction: column;
    row-gap: 20px;
    align-items: start;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

  }
  .containerText p {
    font-size: 16px;
  }
  span {
    color: var(--blue);
    font-size: 16px;
  }
  .sub-container:nth-child(even) {
    flex-direction: row-reverse;
    margin-top: 50px;
    margin-bottom: 80px;
  }
  .sub-container:nth-child(odd) {
    column-gap: 130px;
  }

  @media (max-width: 1000px){
    .sub-container{
      flex-direction: column-reverse;
      align-items: center;
      width: 90vw;
      
    }

    .containerText{
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .sub-container:nth-child(even){
      flex-direction: column-reverse;
      
    }

    .sub-container:nth-child(2) .image{
      margin-bottom: -120px;
      margin-top: -50px;
      
    }

    .sub-container:nth-child(3) .image{
      height: 500px;
      width: 40%;
      margin-top: -100px;
      margin-bottom: -150px;
      
      
    }

    .sub-container:nth-child(4) .image{
      height: 550px;
      
    
    }

    .image{
      margin-top: 100px;
      height: 500px;
      margin-bottom: -240px;
    }

    .gif{
      /* height: 200px !important; */
      display: none;
    }

   

    /* .containerText{
      align-items: center;
      width: 80%;
      
    } */

  }

  /* @media (max-width: 900px){
    .sub-container{
      width: 90vw;
    }
  } */

  @media (max-width: 510px){
    /* .sub-container:nth-child(3) .containerText{
      width: 90%;
    } */

    .sub-container:nth-child(even) .image{
      margin-bottom: -50px;
      margin-top: 20px;
      height: 250px;
    }

    .image{
      margin-top: 20px;
      height: 350px;
      margin-bottom: -140px;
    }

    .sub-container:nth-child(4) .image{
      height: 250px;
      margin-top: 50px;
      margin-bottom: -100px;
      
    
    }

    
  }

  @media (max-width: 460px){
    /* .containerText {
      width: 80%;
    } */
  }

  @media (max-width: 400px){
    /* .containerText {
      width: 70%;
    } */

    .sub-container:nth-child(4) .image{
      height: 200px;
      margin-top: 50px;
      margin-bottom: -80px;
      
    
    }
  }

  @media (max-width: 375px){
    /* .containerText {
      width: 65%;
    } */
  }

  

 

</style>