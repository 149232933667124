<template>
  <div class="container-vacancy-page" id="upToVacancyTop">
    <Header style="position: absolute;" theme="light"/>
    <VideoHeader />
    <VacancySelection @filtersVacancy="handleFiltersArray"
                      :page="currentPage"
                      @filtersVacancyNone="handleFiltersArrayNone"
    />
    <div class="sub-container-vacancy-page">
      <div class="parser-container">
        <ParserVacancy v-show="arrayVacancy.length > 0"
                       v-for="(element, index) in arrayVacancy"
                       :index="index"
                       @filterVacancyClear="handleFilterNone"
                       :data="arrayVacancy"
        />
        <div class="create-container">
          <router-link to="/createVacancy" class="create-sub-container"  v-if="user == 'admin'">
            <img
                src="@/assets/icons/add_product.svg"
                alt=""
                class="add-icon"

            >
            <img/>
          </router-link>
        </div>
        <div v-show="arrayVacancy.length <= 0"
             class="title"
        >
          Данные не найдены
        </div>
        <Pagination v-show="arrayVacancy.length > 0"
                    :total-page="totalPage"
                    @getPage="updatePagination"
                    @updatePage="updatePagination"
        />
      </div>

    </div>



    <Footer/>
  </div>

</template>

<script>
import ParserVacancy from "@/components/Parser/ParserVacancy.vue";
import Header from "@/components/Header.vue";
import store from "@/store"
import Footer from "@/components/Footer.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import VacancySelection from "@/components/VacancySelection.vue";
import VideoHeader from "@/components/VideoHeader.vue";
import {HTTP} from "@/plugins/http-common";
import {useHead} from "@vueuse/head";

export default {
  name: 'VacancyPage',
  components: {
    VacancySelection,
    Pagination,
    Footer,
    Header,
    ParserVacancy,
    store,
    VideoHeader
  },
  data() {
    return {
      arrayVacancy: [],
      totalPage: 1,
      currentPage: 1,
      type: "",
      user: 'user'
    }
  },
  methods: {
    updatePagination(page) {
      if (this.type === "") {
        this.arrayVacancy = []
      try {
        HTTP.get(`/vacancies/get_all_vacancies/${(page - 1) * 4}/4`).then(response => {
          // console.log(response.data);
          for(let i = 0; i < response.data.data.length; i++){
            this.arrayVacancy[i] = {
              id: response.data.data[i].id,
              title: response.data.data[i].title,
              city: response.data.data[i].city,
              work_time: response.data.data[i].work_time,
              created_at: response.data.data[i].created_at,
              salary_min: response.data.data[i].salary_min,
              salary_max: response.data.data[i].salary_max,
              salary_type: response.data.data[i].salary_type,
              remote_work: response.data.data[i].remote_work
            }
          }
        })
        HTTP.get('/vacancies/get_pages').then(response => {
          this.totalPage = response.data.pages
        }).catch(err => {

        })
      } catch (e) {

      }

      }

      document.getElementById('upToVacancyTop').scrollTop = 1100
    },

    handleFiltersArray(array, page) {
      this.arrayVacancy = []
      this.totalPage = page
      this.arrayVacancy = array
      this.type = "Array"
    },

    handleFiltersArrayNone() {
      this.arrayVacancy = []
      this.type = ''
    },

    handleFilterNone() {
      this.defaultHTTPVacancy()
    },
    defaultHTTPVacancy() {
      HTTP.get(`/vacancies/get_all_vacancies/0/4`).then(response => {
        // console.log(response.data);
        for(let i = 0; i < response.data.data.length; i++){
          this.arrayVacancy[i] = {
            id: response.data.data[i].id,
            title: response.data.data[i].title,
            city: response.data.data[i].city,
            work_time: response.data.data[i].work_time,
            created_at: response.data.data[i].created_at,
            salary_min: response.data.data[i].salary_min,
            salary_type: response.data.data[i].salary_type,
            salary_max: response.data.data[i].salary_max,
            remote_work: response.data.data[i].remote_work
          }
        }
      }).catch(err => {

      })
    }
  },

  mounted() {
    if(localStorage.user) this.user = localStorage.user;
    try {
      HTTP.get('/vacancies/get_pages').then(response => {
        this.totalPage = response.data.pages
      }).catch(err => {

      })
      this.defaultHTTPVacancy()
    } catch (e) {
      console.log(e)
    }
    useHead({
      title: `Работа в ООО «Трансмаш»`,
      meta: [
        {
          name: `description`,
          content: `Подберите подходящую работу для себя и станьте частью команды компании. `
        },
        {
          name: `keywords`,
          content: `работа, работа в Ленинск-Кузнецке, работа в Томске, работа в Новокузнецке`
        }
      ]

    })
  }

}
</script>

<style scoped>
.container-vacancy-page {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
}
.sub-container-vacancy-page {
  background-color: var(--about-bg);
  width: 100%;
  padding: 50px 0;
}
.title {
  font-size: 48px;
  padding-bottom: 30px;
  color: var(--text);
  text-align: center;
  font-family: geometria_extralight;
  text-transform: uppercase;
}
.create-container{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
.create-sub-container {
  width: 100%;
  max-width: 920px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;


  margin: 20px;

  background: var(--card-bg);

  border-radius: 12px;

  border-style: solid;
  border-width: 1px;
  border-image:
      linear-gradient(
          to bottom,
          rgba(20, 20, 20, 0.1),
          #ccc,
          rgba(20, 20, 20, 0.1)
      ) 1 100%;

  box-shadow: var(--card-box-shadow);



}
.parser-container {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  justify-content: center;
  align-items: center;
}
</style>
