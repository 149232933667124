<script setup>
    import { useI18n } from 'vue-i18n';
    const { t } = useI18n({useScope: 'global'});
</script>

<template>
    <div class="container">
        <div class="sub-container">
            

            <div class="footer-titles">  
                <div class="title">
                    <div class="header-sub-title">
                        <router-link to="/about"
                                     class="h3"
                        >
                            {{$t('Footer.about')}}
                        </router-link>
                    </div>
                    <div class="footer-sub-titles">                               
                        <router-link to="about" class="footer-href">{{$t('Footer.firstColumn.about_company')}}</router-link>
                        <router-link to="ourTeam" class="footer-href">{{$t('Footer.firstColumn.our_team')}}</router-link>
                    </div>
                </div>

                <div class="title">
                    <div class="header-sub-title">
                        <router-link to="/catalog"
                                     class="h3"
                        >
                            {{ $t('Footer.catalog') }}
                        </router-link>
                    </div>
                    <div class="footer-sub-titles">
                        <router-link :to="{path: '/catalog', query: {categories: 'Automation'}}"
                                     class="footer-href"        
                                     @click="categoryEmit(categoryType = 'Automation')"                  
                        >
                            {{ $t('Footer.thirdColumn.automatics') }}
                        </router-link>
                        <router-link :to="{path: '/catalog', query: {categories: 'Data_transfer'}}"
                                     class="footer-href"
                                     @click="categoryEmit(categoryType = 'Data_transfer')"
                        >
                            {{ $t('Footer.thirdColumn.data_transfer') }}
                        </router-link>
                        <router-link :to="{path: '/catalog', query: {categories: 'Video_monitoring'}}"
                                     class="footer-href"
                                     @click="categoryEmit(categoryType = 'Video_monitoring')"
                        >
                            {{ $t('Footer.thirdColumn.video') }}
                        </router-link>
                        <router-link :to="{path: '/catalog', query: {categories: 'Software'}}"
                                     class="footer-href"
                                     @click="categoryEmit(categoryType = 'Software')"
                        >
                            {{ $t('Footer.thirdColumn.po') }}
                        </router-link>
                        <router-link :to="{path: '/catalog', query: {}}"
                                     class="footer-href"
                        >
                            {{ $t('Footer.thirdColumn.more') }}
                        </router-link>
                    </div>
                </div>

                <div class="title">
                    <div class="header-sub-title">
                        <router-link to="/contacts"
                                     class="h3"
                        >
                            {{ $t('Footer.contacts') }}
                        </router-link>
                    </div>
                    <div class="footer-sub-titles">
                        <a href="https://yandex.ru/maps/-/CCU04UvtDC"
                           class="footer-href-icon-contacts"
                           target="_blank"

                        >
                            {{ $t('Footer.fourthColumn.adress') }}
                        </a>
                        <div class="footer-href-icon-contacts">
                            <div class="phone-container">
                                <a href="tel:+79001234567">
                                    +7 (384-56)5-45-31

                                </a>
                                <a href="tel:+79001234567">
                                    +7 (384-56)5-45-29

                                </a>
                            </div>
                        </div>
                        <div class="footer-href-icon-contacts">
                            <a href="mailto: info@transmlnk.ru">
                                info@transmlnk.ru
                            </a>
                        </div>
                        <div class="footer-href-icon-contacts">

                            <a href="https://t.me/transmash_leninsk"
                               class="telegram"
                               target="_blank"
                            >
                                telegram
                            </a>

                            <a href="https://vk.com/transmash_lnk"
                               class="vk"
                               target="_blank"
                            >
                                vk
                            </a>

                        </div>

                    </div>
                </div>

            </div>
            <div class="bottom-container">
                <img class="img_1" src="@/assets/full_logo.png" alt="">
                <img src="@/assets/fond.png" alt="">
                <img src="@/assets/medal.png" alt="">
            </div>
           
        </div>
        <div class="rights-container">
                {{$t('Footer.right')}}
            </div>
        
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },

        methods: {
            categoryEmit(categoryType){
                this.$emit('categoryEmit', categoryType)
                window.scrollTo({
                top: 1000,
                behavior: 'smooth'
            });
            }
           
        },


        components: {

        }
    }
</script>

<style scoped>
    .container {
        width: 100%;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        flex-direction: column;
        
        background-color: var(--footer-bg);
    }

    .sub-container {
        
        width: 1300px;
        align-items: center;
        padding-top: 40px;
        row-gap: 50px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .title{
        width: 50%;
        
    }

    

    

    .footer-titles {
        
        justify-content: center;
        display: flex;
    }

    .header-sub-title {
        
        

        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer-sub-titles {
        
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .footer-href, .footer-href-icon {
        margin-bottom: 20px;
        font-size: 16px;
        display: flex;
        color: var(--text);
        
        width: 300px;
        justify-content: center;
        align-items: center;

        text-align: center;
        font-family: geometria_light;
        
        opacity: 0.8;

        transition: 0.3s;
    }

    .footer-href-icon-contacts{
        margin-bottom: 20px;
        font-size: 16px;
        display: flex;
        color: var(--text);
        
        width: 280px;
        justify-content: center;
        align-items: center;

        text-align: center;
        font-family: geometria_light;
        
        opacity: 0.8;

        transition: 0.3s;
    }

    .footer-href_certificate{
        margin-bottom: 20px;
        font-size: 16px;
        display: flex;
        color: var(--text);
        text-align: center;
        font-family: geometria_light;
        
        opacity: 0.8;

        transition: 0.3s;
    }

    .footer-href_industrialSafety{
        margin-bottom: 20px;
        font-size: 16px;
        display: flex;
        color: var(--text);
        text-align: center;
        font-family: geometria_light;
        
        opacity: 0.8;

        transition: 0.3s;
    }

    .footer-href:hover {
        cursor: pointer;
        opacity: 1;
    }

    .footer-href-icon {
        text-align: left;
        justify-content: flex-start;
        padding-left: 64px;
    }

    .footer-href-icon::after, .vk::after, .telegram::after {
        content: "";
        width: 20px;
        height: 28px;


        position: absolute;
        margin-left: -40px;

        background-size: contain;
        background-repeat: no-repeat;
    }

    .footer-href-icon:nth-child(1)::after {
        background-image: url(@/assets/icons/location.png);
    }

    .footer-href-icon:nth-child(2)::after {
        background-image: url(@/assets/icons/phone.png);
    }

    .footer-href-icon:nth-child(3)::after {
        background-image: url(@/assets/icons/message.png);
        margin-top: 12px;
    }

    /*.footer-href-icon:nth-child(4)::after {*/
    /*    background-image: url(@/assets/icons/tg.png);*/
    /*    margin-top: 12px;*/
    /*}*/



    .telegram::after {
        background-image: url(@/assets/icons/tg.png);
        margin-top: 5px;
        z-index: 2;
        margin-left: -100px;
    }


    .vk {
        margin-left: 60px;
    }

   

    .vk::after {
        margin-top: 5px;
        margin-left: -45px;
        background-image: url(@/assets/icons/vk.png);
    }
    
    .wiki{
        display: flex;
        flex-direction: row;
        
    }

    .wiki>span {
        font-size: 16px;
        display: flex;
        color: var(--text);
        font-family: geometria_light;
        
        opacity: 0.8;

    }

   .img-wiki{
    height: 20px;
    width: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;
   }

    

    .bottom-container {
        width: 100%;
        /* border: solid 1px red; */
        

        display: flex;
        justify-content: space-evenly;
    }

    

    .rights-container {
        color: var(--text);
        font-size: 16px;

        height: 32px;

        margin-top: 5%;
        opacity: 0.5; 

    }

    .phone-container {
        display: flex;
        flex-direction: column;
    }

    .h3 {
        font-size: 26px;
        font-weight: 100;
        color: var(--text);
        text-transform: uppercase;
    }

    a {
        text-decoration: none;
        color: var(--text);
        font-family: geometria_light
    }

    @media (max-width: 1300px){
        .footer-titles{
            flex-wrap: wrap;
            width: 1000px;    
        } 
        
        .bottom-container{
            flex-wrap: wrap;
            width: 1000px;
        }

        .header-sub-title{
            margin-bottom: 5px;
            margin-top: 15px;
        }
        
        .header-sub-title{
            margin-bottom: 10px;
        }

        .title{
            margin-bottom: 20px;
        }

        .footer-href_certificate{
            display: none;
        }

        .footer-href_industrialSafety{
            display: none;
        }
      
    }

    

    @media (max-width: 1000px){
          
        
        

        .bottom-container{
           
            width: 800px;
        }

        .header-sub-title{
            margin-bottom: 5px;
            margin-top: 30px;
        }

        .footer-titles{
            flex-wrap: wrap;
            width: 700px;    
        } 
    }

    @media (max-width: 800px){
        .bottom-container{
           
           width: 600px;
       }
    }

    @media (max-width: 700px){
       

        .header-sub-title{
            margin-bottom: 10px;
            
        }

        .footer-sub-titles{
            margin-bottom: 20px;
        }

       

       .rights-container {
        
        font-size: 13px;

        }

    .title{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        } 

        
    
    }

    @media (max-width: 600px){
        .bottom-container{
           
           width: 300px;
           row-gap: 40px;
       }

       .sub-container {
         row-gap: 0;
       }

       .img_1{
        width: 90%;
       }

       h3{
            font-size: 20px;
        }

        .title{
            margin-top: -50px;
        }

        .title:nth-child(1){
            margin-top: -30px;
        }

        .footer-href, .footer-href-icon {
            font-size: 15px;
            margin-bottom: 10px;
        }

       
    }



</style>