<template>
  <div class="container">
    <div class="sub-container">
      <h2 id="scrollToSpecificationsProduct">
        {{$store.state.lang == 'ru-RU' ? 'Характеристики' : 'Characteristics' }}
      </h2>
      <div class="container-body">
        <div  v-for="index in 5"
              class="specifications">

          <p class="name-text">
            {{testArray[0].name}}
          </p>

          <p class="answer-text">
            {{testArray[0].answer}}
          </p>
          <!--        {{specifications}}-->
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "SpecificationsProduct",
  data () {
    return {
      testArray: [{name: "Маркировка взрывозащиты", answer: 'РO Ex ia I X / РO Ex ia op is I X'}]
    }
  },
  props: {
    specifications: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
    .container {
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: start;
      color: var(--text);
      background: var(--default-bg);
      padding: 50px 0;
      /*scroll-snap-align: center;*/
    }

    .sub-container {
      width: 1300px;
      display: flex;
      column-gap: 20px;
      flex-direction: column;
      /*justify-content: center;*/
    }

    h2 {
      font-weight: 500;
      font-size: 25px;
      /*line-height: 120%;*/
    }

    .container-body {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      padding-top: 20px;
    }

    .specifications {
      display: flex;
      column-gap: 10px;
      align-items: center;
    }

    .answer-text, .name-text {
      border-radius: 15px;
      padding: 16px 16px;
      display: flex;
      align-items: center;
    }

    .name-text {
      background: var(--background-name-specification);
      width: 70%;
    }

    .answer-text {
      width: 30%;
      justify-content: end;
      background: var(--background-answer-specification)
    }
</style>