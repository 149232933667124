<template>
  <div class="carousel">
    <div class="carousel-inner">
      <CarouselItem v-for="(slide, index) in slides.length == 0 ? 1 : slides"
                    :slide="slide"
                    :currentSlide="currentSlide"
                    :index="index"
                    :direction="direction"
                    :key="index"
      />
      <CarouselControls v-if="slides.length > 1" @prev="prev"
                        @next="next"
      >

      </CarouselControls>
    </div>
  </div>
</template>

<script>

import CarouselItem from "@/components/Slider/CarouselItem.vue";
import CarouselControls from "@/components/Slider/CarouselControls.vue";

export default {
  name: "Carousel",
  components: {
    CarouselControls,
    CarouselItem
  },
  data() {
    return {
      currentSlide: 0,
      slideInterval: null,
      direction: 'right'
    }
  },
  props: {
    slides: {
      type: Array,
      default: []
    },
    // currentSlide: {
    //   type: Number,
    //   default: 0
    // }
    },
    methods: {
      setCurrentSlide(index) {
        this.currentSlide = index
      },
      prev() {
        const index = this.currentSlide > 0
            ? this.currentSlide - 1
            : this.slides.length - 1
        this.setCurrentSlide(index)
        this.direction="left"
      },
      next() {
        const index = this.currentSlide < this.slides.length - 1 ? this.currentSlide + 1 : 0
        this.setCurrentSlide(index)
        this.direction="right"
      },
      
  },
  created() {
    // console.log(this.slides);
  }
}
</script>

<style scoped>
.carousel {
  display: flex;
  width: 100%;
  justify-content: center;
}
.carousel-inner {
  position: relative;
 
  width: 100%;
  height: 450px;
  overflow: hidden;
}


</style>