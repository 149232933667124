// Импорты компонентов
import CustonButton from "@/components/ui/CustomButton.vue"
import Card from "@/components/ui/Card.vue";
import Circle from "@/components/ui/Circle.vue";
import CustomCard from "@/components/ui/CustomCard.vue";
import ContactCard from "@/components/ui/ContactCard.vue";
import SceletonCard from "@/components/ui/SceletonCard.vue";
import PhoneInput from "@/components/ui/PhoneInput.vue";
import FiltCard from "@/components/ui/FiltCard.vue";


export default [
    Card,
    CustonButton,
    Circle,
    CustomCard,
    ContactCard,
    SceletonCard,
    PhoneInput,
    FiltCard
]
