<template>
    <div class="container">
        <div class="sub-container">
            <div class="sub-container-header">
                <h2>{{$t('Footer.about')}}</h2>
            </div>
            <div class="sub-container-body">
                <div class="info-block">
                    <div class="chart-icon-container"></div>
                    <h3>{{ $t('FullscreenSlider.inovations') }}</h3>
                    <span class="description">
                        {{$t('About.firstDescription')}}
                    </span>
                </div>

                <div class="info-block">
                    <div class="hands-icon-container"></div>
                    <h3>{{ $t('FullscreenSlider.experience') }}</h3>
                    <span class="description">
                        {{$t('About.secondDescription')}}

                    </span>
                </div>

                <div class="info-block">
                    <div class="lamp-icon-container"></div>
                    <h3>{{ $t('FullscreenSlider.result') }}</h3>
                    <span class="description">
                        {{$t('About.thirdDescription')}}
                    </span>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
            }
        },
    }
</script>

<style scoped>

    .container {
        
        
        display: flex ;
        justify-content: center;
        align-items: center;
        
        
        background-color: var(--about-bg);
        scroll-snap-align: center;

    }

    .sub-container-body{
        justify-content:space-between;
        align-items: center;
        display: flex;
        
        
    }

    .chart-icon-container, .hands-icon-container, .lamp-icon-container {
        width: 98px;
        height: 98px;
        
        margin: 20px;

        background-repeat: no-repeat;
        background-size: contain;
    }

    .chart-icon-container {
        background-image: var(--about-icon-url-chart);
    }

    .hands-icon-container {
        background-image: var(--about-icon-url-hands);
    }

    .lamp-icon-container {
        background-image: var(--about-icon-url-lamp);
    }

    .sub-container {
        width: 1300px;
        
        margin-top: 50px;
        margin-bottom: 50px;
        flex-direction: column;
        
        display: flex;
        justify-content: center;
        align-items: center;
        
    }

    .sub-container-header {
        width: 100%;
        height: 70px;

        display: flex;
        justify-content: center;
    }

    .sub-container-body {
        width: 100%;
       
        display: flex;
    }

    .info-block {
        width: 350px;
        
        
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }

    .description {
        width: 250px;
        margin-top: 40px;
        color: var(--text);
    }

    h2 {
        font-size: 48px;
        color: var(--text);

        font-family: geometria_extralight;

        text-transform: uppercase;
    }

    h3 {
        font-size: 26px;
        font-weight: 100;
        color: var(--text);
        text-transform: uppercase;
    }


    @media (max-width: 800px) {
        .sub-container-body{
            flex-wrap: wrap !important;
            justify-content: center;
        }
        .info-block{
            width: 260px;
        }

        h2{
            font-size: 35px;

        }

        h3{
            font-size: 20px;
        }

        .description{
            margin-top: 10px;
            margin-bottom: 40px;
        }

        .info-block:nth-child(3){
            
            margin-bottom: 0px;
        }
    }

</style>