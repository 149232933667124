<template>
    <div class="newsIDContainerPage" >
        <div class="newsIDContainer">
            <div class="newsIDSubContainer">
                <h2 class="h2"></h2>

                <p class="date"></p>

                <div class="base64Image" style="background-size: 200% 100%"></div>



            </div>
        </div>

        <SkeletonParser />

        <div class="otherNews">
            <h2 class="otherNewsTitle">
                Другие статьи
            </h2>
            <div class="otherNewsFlexRow">
                <div class="otherNewsContainer" v-for="(text, index) in 3">
                    <div
                            class="otherNewsImage"
                            style="background-size: 200% 100%"
                    >
                    </div>
                    <p class="otherNewsTitleParagraph"></p>

                    <p class="dateOther"></p>

                    <router-link class="linkToOtherNews" to=""></router-link>

                </div>
            </div>

        </div>

        <Footer/>
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Parser from "@/components/Parser/Parser.vue";
import MicroNews from "@/components/MicroNews.vue";
import SkeletonParser from "@/components/Sceletons/SkeletonParser.vue";

export default {
    name: "SkeletonAdditionalNews",
    components: {SkeletonParser, MicroNews, Header, Footer, Parser},
    props: ['id'],
    inheritAttrs: false,
    data() {
        return {
            newsTitleRU: [],
            newsParagraphRU: [],
            array_for_parser: [],

            newsTitleEN: [],
            newsParagraphEN: [],

            created_at: [],
            created_ats: [],

            images: [],

            newsOtherTitleRU: [],
            newsOtherTitleEN: [],
            otherNewsID: [],
            imageOtherNews: [],
        }
    },
    methods: {
        scrollToTop(){
        }
    },
}
</script>

<style scoped>
    .newsIDContainerPage {
        position: relative;
        overflow-y: scroll;
        color: var(--text);
        height: 100vh;
        /*scroll-snap-type: y proximity;*/
    }
    .newsIDContainer {
        display: flex;
        color: var(--text);
        padding: 55px 0 60px 0;

        justify-content: center;
        align-items: center;
    }
    .newsIDSubContainer {
        width: 1000px;
        /*height: 50vh;*/

        display: flex;
        row-gap: 25px;
        justify-content: space-between;
        flex-direction: column;
    }
    .h2, .otherNewsTitle {
        font-size: 48px;
        font-weight: 500;
    }
    .h2 {
        background: var(--sceleton-card);
        animation: 1.5s shine linear infinite;
        background-size: 200% 100%;
        height: 100px;
        width: 100%;
    }
    .date {
        color: #808080;
        font-size: 25px;
        font-weight: 400;
        background: var(--sceleton-card);
        animation: 1.5s shine linear infinite;
        background-size: 200% 100%;
        width: 250px;
        height: 30px;
    }
    .base64Image {
        min-width: 128px;
        /*height: 128px;*/
        height: 600px;

        box-sizing: border-box;
        border-radius: 5px;
        background: var(--sceleton-card);
        animation: 1.5s shine linear infinite;
        /*cursor: pointer;*/

        /*background-position: center;*/
        /*background-size: cover;*/

        box-shadow: 1px 2px 12px rgb(0,0,0,0.3);
    }

    .paragraph {
        font-size: 25px;
        font-weight: 400;
    }

    .paragraph:last-child {
        padding-bottom: 55px;
    }
    .otherNews {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        align-items: center;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 80px;
    }
    .otherNewsFlexRow {
        display: flex;
        column-gap: 25px;
        row-gap: 35px;
        justify-content: center;
    }
    .otherNewsImage {
        width: 300px;
        height: 200px;

        box-sizing: border-box;

        border-radius: 5px;
        background: var(--sceleton-card);
        animation: 1.5s shine linear infinite;

        /*cursor: pointer;*/

        /*background-position: center;*/

        box-shadow: 1px 2px 12px rgb(0,0,0,0.3);
    }
    .dateOther {
        color: #808080;
        font-weight: 400;
        background: var(--sceleton-card);
        animation: 1.5s shine linear infinite;
        background-size: 200% 100%;
        height: 35px;
        width: 300px;
    }
    .otherNewsContainer {
        width: 315px;
        display: flex;
        position: relative;
        height: 350px;
        row-gap: 15px;
        flex-direction: column;
    }
    .linkToOtherNews {
        color: var(--blue);
        position: absolute;
        bottom: 0;
        background: var(--sceleton-card);
        animation: 1.5s shine linear infinite;
        background-size: 200% 100%;
        height: 30px;
        width: 300px;
    }
    .otherNewsTitle {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        -webkit-box-orient: vertical;
    }
    .otherNewsTitleParagraph {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        -webkit-box-orient: vertical;
        background: var(--sceleton-card);
        animation: 1.5s shine linear infinite;
        background-size: 200% 100%;
        height: 35px;
        width: 300px;
    }
    .paragraph.skeleton {

        background: #eee;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
    }


    @keyframes shine {
        to {
            background-position-x: -200%;
        }
    }
</style>