<template>
  <div class="container">
    <div class="sub-container">
      <div class="sub-container-header">
        <h2>
          {{$t('AboutPage.certificate.title')}}
        </h2>
      </div>
        <div class="sub-container-body" >
        
        <div class="info-block" v-for="(element, index) in (arrayElement.length !== 0 ? arrayElement : 4)">
          <div class="sceleton-container"
               v-if="arrayElement.length === 0"
          >

          </div>
        <div v-else>
          <div class="image"
               v-if="isDownload === true"
               @click="Download(element.id, $store.state.lang == 'ru-RU' ? element.titleRU : element.titleEN)"
               :style="isDownload === true
               ? {backgroundImage: `url(${require('@/assets/certificate.jpeg')})`, cursor: 'pointer'}
               : {backgroundImage: `url(${require('@/assets/certificate.jpeg')})`}"
          >
          </div>
          <div class="image"
               v-else
               :style="{backgroundImage: `url(${require('@/assets/certificate.jpeg')})`}"
          >


          </div>

          <div class="imageRight"
               :style="{backgroundImage: `url(${require('@/assets/certificate.jpeg')})`}"
          >

          </div>

          <div class="imageRight2"
               :style="{backgroundImage: `url(${require('@/assets/certificate.jpeg')})`}"
          >

          </div>
        </div>

          <div class="container-text">
            <p>
                {{$store.state.lang == 'ru-RU' ? element.titleRU : element.titleEN}}
            </p>
            <div class="borderBehind">

            </div>
            <div class="containerLengthImage">
              <p class="lengthImage">{{element.count}}</p>
            </div>
          </div>

        </div>
      </div>
      <CustomButton v-if="isButton === true" link="certificate" :style="{margin:`0 auto`}" :button-text="$t('Buttons.more')"/>
    </div>

  </div>
</template>

<script>
import CustomLinkDownload from "@/components/ui/CustomLinkDownload.vue";
import {HTTP} from "@/plugins/http-common";

export default {
  name: "CertificateAboutPage",
  components: {CustomLinkDownload},
  props: {
    arrayElement: {
      default: []
    },
    isButton: {
      default: true
    },
    isDownload: {
      default: false
    }
  },
  data() {
    return {
      // documentation: [],

    }
  },
  methods: {
      Download(id, name) {
          HTTP({
              method: 'get',
              url: `/certificate/get_certificate_by_id/${id}`,
          }).then( (response) => {
              const a = document.createElement('a');
              // let blob = new Blob(response.data.images, {type: 'image/jpg'})
              // console.log('download')
              a.href = response.data.pdf
              // console.log(a)
              a.download = name +'.pdf';
              document.body.appendChild(a);
              a.click();
              a.remove();
          }).catch(err=> {
              // console.log(err)
          });
      }
  },
}
</script>

<style scoped>


    @keyframes shine {
          to {
            background-position-x: -200%;
          }
        }
    .sceleton-container{
      background: var(--sceleton-card);
        border-radius: 5px;
        background-size: 200% 100% !important;
        animation: 1.5s shine linear infinite;
        height: 300px;
      width: 210px;
        box-shadow: 1px 2px 12px rgb(0,0,0,0.3);
        
    }
    .container {
      /*height: 70vh;*/
      width: 100vw;
      display: flex;
      justify-content: center;
      padding: 50px 0;

      align-items: start;
      color: var(--text);
      background: var(--default-bg);
      scroll-snap-align: center;
      /*flex-direction: column;*/
    }
    .sub-container {
      width: 1300px;
      column-gap: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .sub-container-header {
      width: 100%;
      height: 70px;
      display: flex;
      justify-content: center;
    }
    .sub-container-body {
      width: 100%;
      height: 100%;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      display: flex;
    }
    .container-text {
      padding: 20px 0;
      /*height: 105px;*/
      width: 85%;
    }
    .info-block {
      width: 25%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      text-align: center;
      flex-direction: column;
      overflow: hidden;
    }
    .image {
      background-size: contain;
      height: 300px;
      width: 210px;
      margin-top: 45px;
      position: relative;
      z-index: 2;
      border-radius: 10px;
      /*background-position: top center;*/
      /*background-size: 100% 100%;*/
      background-repeat: no-repeat;
    }
    h2 {
      font-size: 48px;
      color: var(--text);
      font-family: geometria_extralight;
      text-transform: uppercase;
    }
    .lengthImage {
      position: absolute;
      /*top: 60px;*/
      width: 25px;
      height: 25px;
      font-size: 18px;
      /*background: #FFFFFF;*/
      display: flex;
      font-weight: 700;
      justify-content: center;
      align-items: center;
      color: black;
      mix-blend-mode: multiply;
      /*left: 70px;*/
    }
    .containerLengthImage {
      position: absolute;
      width: 25px;
      top: 60px;
      z-index: 2;
      border-radius: 3px;
      left: 70px;
      background: #FFFFFF;
      height: 25px;
    }
    .borderBehind {
      border-top: 2px solid #D9D9D9;
      border-right: 2px solid #D9D9D9;
      height: 28px;
      width: 25px;
      position: absolute;
      z-index: 2;
      top: 54px;
      left: 75px;
      border-radius: 5px;
    }
    .imageRight {
      background-size: contain;
      height: 290px;
      width: 210px;
      border-radius: 10px;
      position: absolute;
      /*top: 65px;*/
      background-position: top center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      top: 53px;
      z-index: 1;
      right: 47px;
      /*background: rgba(0,0,0,10%);*/
      opacity: 0.6;
    }
    .imageRight2 {
      background-size: contain;
      height: 280px;
      width: 210px;
      border-radius: 10px;
      position: absolute;
      /*top: 65px;*/
      background-position: top center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      top: 59px;
      right: 37px;
      opacity: 0.3;
    }

    @media (max-width: 1300px){
      .container{
        display: none;
      }
    }


</style>