<template>
    <div class="documentationContainer">

        <DocumentationAboutPage :style="{backgroundColor: `var(--default-bg)`, height: `50%` }"
                                :title="$store.state.lang === 'ru-RU' ? documentationTitleRU : documentationTitleEN"
                                :is-more="false"
                                :array-element="documentationArray"/>
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import {HTTP} from "@/plugins/http-common";
import DocumentationAboutPage from "@/components/DocumentationAboutPage.vue";

export default {
    name: "Documentation",
    components: {
        DocumentationAboutPage,
        Header,
        Footer
    },
    data() {
        return {
            documentationArray: [],
            documentLink: [],
            documentLinkID: '',
            documentationTitleRU: 'Документация',
            documentationTitleEN: 'Documentation',
        }
    },
    methods: {
        // getID(id) {
        //     this.documentLinkID = id
        // }
    },

    updated() {
        // console.log(this.documentLinkID)
    },
    created() {
        HTTP.get(`/document/get_document_number/4`).then(response => {
            // console.log(response.data);
            for (let i = 0; i < response.data.length; i++) {
                this.documentationArray[i] = {
                    id: response.data[i].id,
                    titleRU: JSON.parse(response.data[i].device).title_ru,
                    titleEN: JSON.parse(response.data[i].device).title_eng,
                    type: 'documentation'
                }
            }
        })

    }
}
</script>

<style scoped>
.documentationContainer {
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
}
</style>