<template>
    <div>
        <Header :theme="$store.state.theme == 'light' ? 'dark' : 'light'"
        />
        <h1>
            404
        </h1>
    </div>


</template>

<script>
import Header from "@/components/Header.vue";
import store from "@/store";

export default {
    name: "NotFound",
    components: {
      Header
    },
    mounted() {
      // store.commit('checkAdminStatus')
    }
}
</script>

<style scoped>

h1 {
    margin-top: 30%;
    font-size: 100px;
    text-align: center;
}
</style>