 
<template>
    <div class="container">
      <div class="subContainer">
        <div class="image" ></div>
        <div class="containerText" >
          <div class="h2"></div>
          
  
          <div class="subContainerRow"></div>   
          
            <div class="btn-date">
                <div class="btn"></div>
                <div class="date"></div>  
            </div>

            
        </div>
      </div>
  
    </div>
  </template>
  
  <script>
  import News from "@/pages/AdditionalPages/News.vue";
  
  export default {
    name: "SceletonNewsFullPreview",
    components: {News},
    props: {
      Base64: {
        type: String,
        default: () => "No Image"
      },
  
      FirstPar: {
        type: String,
        default: () => ""
      },
      date: {
        type: String,
        default: () => ""
      },
      Paragraph: {
        type: String,
        default: () => ""
      },
      id: {
        type: String,
        default: () => ""
      }
    },
  
    data(){
      return {
  
      }
    },
  
    methods() {
  
    }
  
  }
  
  </script>
  
  <style scoped>
    .container {
      width: 100vw;
      scroll-snap-align: center;
      color: var(--text);
      padding: 35px 0;
      display: flex;
      margin-left: 50px;
      justify-content: center;
    }
  
    .containerText a {
      color: var(--blue);
      font-size: 20px;
    }
    .h2 {
      width: 750px;
      height: 100px;

      background: #eee;
    background: var(--sceleton-card);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;

      overflow: hidden;
    }

    .btn-date{
        margin-top: -40px;
        display: flex;
        flex-direction: row;
        
    }

    .btn{
        width: 200px;
        height: 30px;
        margin-top: 100px;
        background: #eee;
        background: var(--sceleton-card);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
    }
    .date{
        width: 200px;
        height: 30px;
        margin-top: 30px;
        background: #eee;
        
        right: 0px;

        background: var(--sceleton-card);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
    }
    .paragraphStyle {
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      overflow: hidden;
      /*height: 200px;*/
    }
    .containerText p {
      font-size: 20px;
    }
    
      
    .containerText a, .date {
      position: absolute;
      bottom: 0;
    }
    .subContainer {
      max-width: 1300px;
      height: 100%;
      display: flex;
      column-gap: 20px;
      justify-content: center;
    }
    .containerText {
      display: flex;
      position: relative;
      flex-direction: column;
      row-gap: 20px;
      width: 40%;
    }
    .image {
      width: 450px;
      height: 400px;
      border-radius: 5px;
      

      background: #eee;
    background: var(--sceleton-card);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
      
    }
    .subContainerRow {
      width: 750px;
      height: 170px;

      background: #eee;
    background: var(--sceleton-card);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    }

    @keyframes shine {
      to {
        background-position-x: -200%;
      }
    }

  
    @media (max-width: 1050px){
        .btn{
            width:170px ;
            margin-bottom:  30px;
            margin-top: 30px;
        }

        .date{
            width:170px ;
           margin-bottom:  30px;
        }
    }

    @media (max-width: 900px){
      .subContainer {
        flex-direction: column;
        align-items: center;
      }
  
      .image{
        width: 90%;
      }
  
      .containerText{
        width: 90%;
      }
  
      .subContainerRow{
        margin-top: 20px;
      }

      .h2{
        margin-top: 10px;
      }
    }
  
    @media (max-width: 450px){
      .image{
        width: 90%;
        height: 300px;
      }
  
      .containerText{
        width: 90%;
      }
  
      .containerText p {
        font-size: 16px;
      }
      
      
  
      .router{
        font-size: 20px;
      }
  
      .subContainerRow{
        margin-top: 10px;
      }

      .btn{
            width:150px ;
        }

        .date{
            width:150px ;
            
        }
    }
  
  </style>