<template>
  <div class="container">
      <h2 class="title">
        {{ $t('Catalog.Select_category') }}
      </h2>
    <div class="sub-container">
      <div class="category" v-for="(category, index) in categoriesText">
        <router-link
          :to="{
            path: '/catalog',
            query: {
              categories:
                method === category.name_eng
                  ? category.name_eng
                  : ''.split('=')[1],
            },
          }"
          class="routBtn"
        >
          <button
            class="categoryButton"
            :class="{ buttonClicked: category_type === category.name_eng }"
            @click="
              btn(
                method === category.name_eng
                  ? (method = '')
                  : (method = category.name_eng)
              )
            "
            v-if="isEdit !== index"
          >
          <span class="categoryButtonText" :style = "category_type === category.name_eng ? {color: 'white'} : {color: 'var(--blue)'}">
            {{ category.name_ru.replace('_' , ' ') }}
          </span>

          </button>

          <div class="addInputsContainer" v-else>
            <div class="addInputsSubContainer" :style = "this.isEditRuValid ? {} : {border: '1px solid red'}">
              <span>
                RU
              </span>
              <input 
                class="addInput " 
                v-model="editTextRu"
                placeholder="Название новой категории"
              >
            </div>
            <div class="addInputsSubContainer" :style = "this.isEditEngValid ? {} : {border: '1px solid red'}">
              <span>
                ENG
              </span>
              <input 
                class="addInput " 
                v-model="editTextEng"
                placeholder="Name your new category"
              >
            </div>
          </div>
        </router-link>
        <div class="buttons-container" v-if="user == 'admin'">
          <button class="button edit" @click="edit(index, category)" v-if="isEdit !== index"></button>

          <button class="button save" @click="updateCategory(category)" v-else></button>

          <button class="button delete" @click="deleteCategory(category)"></button>
        </div>
      </div>
      <div class="category" v-if="isCreate">
        <div class="addInputsContainer">
          <div class="addInputsSubContainer" :style = "this.isCreateRuValid ? {} : {border: '1px solid red'}">
            <span>
              RU
            </span>
            <input 
              class="addInput " 
              v-model="createTextRu"
              placeholder="Название новой категории"
            >
          </div>
          <div class="addInputsSubContainer" :style = "this.isCreateEngValid ? {} : {border: '1px solid red'}">
            <span>
              ENG
            </span>
            <input 
              class="addInput " 
              v-model="createTextEng"
              placeholder="Name your new category"
            >
          </div>
        </div>

          <div class="buttons-container" v-if="user == 'admin'">
            <button class="button save" @click="createCategory"></button>
            <button class="button delete" @click="isCreate = false"></button>
          </div>
      </div>
      
      <div class="addCategoryButtonContainer" v-if="user == 'admin' && !isCreate">
        <buttom class="addCategoryButton" @click="isCreate = true">
          <img src="./ui/assets/plus.svg" alt="">
        </buttom>
      </div>

    </div>
  </div>
</template>

<script>
import {HTTP} from '@/plugins/http-common'

export default {
  emits: [],
  data() {
    return {
      category_type: "",
      user: "user",
      isEdit: null,
      isEditRuValid: true,
      isEditEngValid: true,
      editTextRu: '',
      editTextEng: '',
      isCreate: false,
      isCreateRuValid: true,
      isCreateEngValid: true,
      createTextRu: '',
      createTextEng: ''
    };
  },

  methods: {
    btn(method) {
      this.category_type = method;
      this.$emit("category_type_btn", method);
    },
    edit(id, name) {
      this.editTextRu = name.name_ru
      this.editTextEng = name.name_eng
      this.isEdit = id
    },
    updateCategory(category) {
      if (this.editTextRu !== '') {
        this.isEditRuValid = true
      }else{
        this.isEditRuValid = false
      }

      if (this.editTextEng !== '') {
        this.isEditEngValid = true
      }else{
        this.isEditEngValid = false   
      }

      if (this.isEditRuValid && this.isEditEngValid) {
        HTTP.put(`/category/categories/${category.id}`, {
          category_id : category.id,
          name_ru : this.editTextRu,
          name_eng : this.editTextEng,
          
        }, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        .then((response) => {
          this.isEdit = null;
          this.$emit('categoryUpdated');
        })
      }
      
    },

    createCategory() {
      if (this.createTextRu !== '') {
        this.isCreateRuValid = true
      }else{
        this.isCreateRuValid = false
      }

      if (this.createTextEng !== '') {
        this.isCreateEngValid = true
      }else{
        this.isCreateEngValid = false
        
      }

      if (this.isCreateRuValid && this.isCreateEngValid) {
        HTTP.post(`/category/categories`, {
          name_ru : this.createTextRu,
          name_eng : this.createTextEng,
          
        }, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        .then((response) => {
          this.isCreate = false;
          this.createTextRu = '';
          this.createTextEng = '';
          this.$emit('categoryUpdated');
        })
      } 
      
    },

    deleteCategory(category) {
      HTTP.delete(`/category/categories/${category.id}`, {
          
      }, {
          headers: {
              'Content-Type': 'multipart/form-data',
          }
      })
      .then((response) => {
        this.$emit('categoryUpdated');
      })
    },
  },
  props: {
    category_type: {},
    method: {},
    categoriesText: {
      default: [],
    },
  },

  created() {
    this.category_type = window?.location?.href?.split("=")[1];
  },

  mounted() {
    if (localStorage.user) this.user = localStorage.user;
  },

  watch: {
    category_type: {
      handler() {
        window.location.reload;
      },
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 50px;
  width: 100%;
  margin-bottom: 50px;
  background-color: var(--default-bg);
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title{
  font-size: 48px;
  font-family: geometria_extralight;
  text-transform: uppercase;
  padding-bottom: 4vh;
  color: var(--text)
}

.sub-container {
  width: 90%;
  max-width: 1350px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 1vh;
  column-gap: 1vw;
}

.category {
  box-sizing: border-box;
  align-self: self-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.category img {
  margin-bottom: 42px;
}

.categoryButton {
  width: 256px;
  height: 48px;
  border-radius: 15px;

  border: solid 2px var(--blue);

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgb(0, 0, 0, 0);
  text-decoration: none;

  cursor: pointer;

}

.categoryButtonText{
  font-family: Geometria_regular;
  font-size: 14px;
  color: var(--blue);
  text-transform: uppercase;
  text-wrap: wrap;
  max-width: 90%;

}

.categoryButton:hover {
  transform: scale(1.02);

}

.buttonClicked {
  background: #1d71b8;
}

.editInput{
  width: 256px;
  height: 48px;
  font-size: 20px;
  border-radius: 15px;

  color: var(--blue);
  border: solid 1px var(--blue);
  text-align: center;

  transition: 0.3s;

}

.addInputsContainer{
  display: flex;
  flex-direction: column;
  row-gap: 0.5vh;
  background-color: var(--default-bg);
 
}

.addInputsSubContainer{
  display: flex;
  flex-direction: row;
  border-radius: 8px;

}

.addInputsSubContainer > span {
  border: solid 1px #AFAFAF;
  border-radius: 8px 0 0 8px;
  width: 15%;
  color: #000000;
  pointer-events: none;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  display: flex;
  color: var(--text);
}

.addInput{
  width: 256px;
  height: 30px;
  font-size: 16px;
  border-radius: 0 8px 8px 0;
  background-color: var(--default-bg);
  color: #000000;
  border: solid 1px #AFAFAF;
  text-align: center;
  color: var(--text);
  transition: 0.3s;

}

.routBtn {
  text-decoration: none;
  cursor: default;
}

.buttons-container {

  z-index: 4 !important;
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  row-gap: 0.5vh;
}

.button {
  width: 32px;
  height: 32px;

  cursor: pointer;

  background: white;
  font-size: 24px;
  font-family: monospace;

  border-radius: 5px;
  border: solid 1px #ccc;

  background-size: 22px;
  background-position: center;
  background-repeat: no-repeat;
}

.button.edit {
  background-image: url("./ui/assets/pencil.png");
}

.button.save {
  background-image: url("./ui/assets/save.svg");
}

.button.delete {
  background-image: url("./ui/assets/delete.png");
}

.addCategoryButtonContainer{
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addCategoryButton{
  width: 3vh;
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E9EDF0;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 0.5vw;
}

.addCategoryButton > img{
  width: 70%;
}


@media (max-width: 1100px) {
  .category {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .sub-container {
    flex-wrap: wrap;

  }

  .container {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

@media (max-width: 550px) {
  .category {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
