 
<template>
    <div class="container"
     :class="{
         'hidden-left': index < viewIndex-1,
         'left': index == viewIndex-1,
         'middle': index == viewIndex,
         'right': index == viewIndex+1,
         'hidden-right': index >= viewIndex+2,
 
     }"
    >
    
     <div class="image-container"></div>  

     <div class="text-container"></div>
     <div class="text-container-down"></div>
    </div>
 </template>
 
 <script>
     export default {
 
         name: 'SceletonCard',
 
         data() {
             return {
 
             }
         },
 
         props: {
             cardText: {
                 type: String,
                 default: "Default card text",
             },
 
             cardImage: {
                 type: String,
                 default: "no-image",
             },
 
             index: {
                 default: 0,
             },
 
             viewIndex: {
                 default: 0,
             }
         },
 
         methods: {
             getImagePath(filename) {
                 return require('../../assets/' + filename);
             }
         }
     }
 </script>
 
 <style scoped>
     .container {
         width: 310px;
         height: 376px;
 
         position: absolute;
 
         margin-left: calc(310px/-2);
 
         background: var(--card-bg);
 
         border-radius: 12px;
         
         border-style: solid;
         border-width: 1px;
         border-image: 
             linear-gradient(
             to bottom, 
             rgba(20, 20, 20, 0.1),
             #ccc, 
             rgba(20, 20, 20, 0.1)
             ) 1 100%;
 
         box-shadow: var(--card-box-shadow);
         transition: 0.3s;
         
         padding-top: 0px;
         padding-bottom: 24px;
     }
 
     .hidden-left {
         transform: translateX(-670px) scale(0.05);
         opacity: 0;
     }
 
     .hidden-right {
         transform: translateX(670px) scale(0.05);
         opacity: 0;
     }
 
     .left {
         transform: translateX(-370px) scale(0.85);
         opacity: 1;
         filter: blur(2px);
     }
 
     .middle {
         transform: translateX(0px) scale(1);
         opacity: 1;
     }
 
     .right {
         transform: translateX(370px) scale(0.85);
         opacity: 1;
         filter: blur(2px);
     }

 
     .image-container {
         width: 80%;
         height: 240px;
         margin-top: 20px;
         overflow: hidden;
 
         position: relative;
         
         background-size: contain;
         background-repeat: no-repeat;
         background-position: center;
 
         overflow: hidden;
        opacity: 0.5;
         display: flex;
         justify-content: center;
         align-items: center;

         background: #eee;
        background: var(--sceleton-card);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
     }
     

     

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

 
     .blurImage {
         width: 100%;
         height: 100%;
 
         background-size: cover;
         background-repeat: no-repeat;
         background-position: center;
 
         /* opacity: 0.2; */
         position: absolute;
         filter: blur(20px);
         opacity: 0.6;
     }
 
     .normalImage {
         width: calc(100% - 15px);
         height: calc(100% - 15px);
 
         background-size: contain;
         background-repeat: no-repeat;
         background-position: center;
         position: absolute;
 
         z-index: 2;
     }
 
     .text-container {
         width: 80%;
         height: 40px;
         
 
         bottom: 80px;
         
         position: absolute;
         opacity: 0.5;
         display: flex;
         justify-content: center;
         align-items: center;
 
         background: #eee;
        background: var(--sceleton-card);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
     }

     .text-container-down {
         width: 80%;
         height: 40px;
         
 
         bottom: 20px;
         
         position: absolute;
 
         display: flex;
         justify-content: center;
         align-items: center;
 
         background: #eee;
        background: var(--sceleton-card);
        opacity: 0.5;
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
     }
 
     @media (max-width: 1150px){
   .left{
     opacity: 0;
   }
 
   .right{
     opacity: 0;
   }
 }
 
     @media (max-width: 420px){
     .container{
         width: 270px;
         height: 316px;
         margin-left: calc(270px/-2);
         
         
     }
 
     
 
     .image-container{
         width: 240px;
         height: 240px;  
     }
     }
 </style>