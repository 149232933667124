<template>
  <div class="container"
       :class="{
         'hidden-left': index < viewIndex-1,
         'left': index == viewIndex-1,
         'middle': index == viewIndex,
         'right': index == viewIndex+1,
         'hidden-right': index >= viewIndex+2,
 
     }"
  >
  <div 
        class="image-container"
    >
        <div class="blurImage" :style="{backgroundImage: `url(${cardImage})`}"></div>
        <div class="normalImage" :style="{backgroundImage: `url(${cardImage})`}"></div>
    </div>
    <div class="text-container">
      {{ cardText }}
    </div>
    <div class="description-container">
      {{ cardDescription }}
    </div>
    <div class="phone-container">
      {{ phone }}
    </div>
    <div class="email-container">
      {{ email }}
    </div>
  </div>
</template>

<script>
export default {

  name: 'ContactCard',

  data() {
    return {

    }
  },

  props: {
    cardText: {
      type: String,
      default: "Default card text",
    },

    cardDescription: {
      type: String,
      default: "Default card description",
    },

    phone: {
      type: String,
      default: "8 800 555 3535",
    },

    email: {
      type: String,
      default: "email@mail.com",
    },

    cardImage: {
      type: String,
      default: "no-image",
    },

    index: {
      default: 0,
    },

    viewIndex: {
      default: 0,
    }
  },

  methods: {
    getImagePath(filename) {
      return require('../../assets/' + filename);
    }
  }
}
</script>

<style scoped>
.container {
  width: 310px;
  height: 356px;

  position: absolute;

  margin-left: calc(310px/-2);

  background: var(--card-bg);

  border-radius: 12px;

  border-style: solid;
  border-width: 1px;
  border-image:
      linear-gradient(
          to bottom,
          rgba(20, 20, 20, 0.1),
          #ccc,
          rgba(20, 20, 20, 0.1)
      ) 1 100%;

  box-shadow: var(--card-box-shadow);
  transition: 0.3s;
          
  padding-top: 0px;
  padding-bottom: 48px;
}

.hidden-left {
  transform: translateX(-670px) scale(0.05);
  opacity: 0;
}

.hidden-right {
  transform: translateX(670px) scale(0.05);
  opacity: 0;
}

.left {
  transform: translateX(-370px) scale(0.85);
  opacity: 0.5;
}

.middle {
  transform: translateX(0px) scale(1);
  opacity: 1;
  z-index: 5;
}

.right {
  transform: translateX(370px) scale(0.85);
  opacity: 0.5;
}

.image-container {
    width: 100%;
    height: 280px;

    overflow: hidden;

    position: relative;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
}

.blurImage {
    width: 100%;
    height: 100%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    /* opacity: 0.2; */
    position: absolute;
    filter: blur(20px);
    opacity: 0.6;
}

.normalImage {
    width: calc(100% - 15px);
    height: calc(100% - 15px);

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;

    z-index: 2;
}

.image-container img {
  width: 100%;
  height: 100%;
}

.text-container {
  width: 60%;
  /* height: 130px; */
  bottom: 60px;

  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  
  color: var(--text);

  font-size: 16px;
}

.description-container {
  width: 100%;
  /* height: 130px; */
  bottom: 40px;

  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  color: var(--text);

  font-size: 12px;
}

.phone-container {
  width: 100%;
  /* height: 130px; */
  bottom: 25px;

  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  color: var(--text);

  font-size: 12px;
}

.phone-container::before {
  content: '';
  width: 24px;
  height: 12px;
  background-image: url(../../assets/icons/phone.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.email-container {
  width: 100%;
  /* height: 130px; */
  bottom: 10px;

  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  color: var(--text);

  font-size: 12px;
}

.email-container::before {
  content: '';
  width: 24px;
  height: 10px;
  background-image: url(../../assets/icons/message.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 1150px){
  .left{
    opacity: 0;
  }

  .right{
    opacity: 0;
  }
}

@media (max-width: 420px){
  .container{
    width: 270px;
    height: 316px;
    margin-left: calc(270px/-2);
   
    
  }

  

  .image-container{
    width: 240px;
    height: 240px;  
  }

  
}
</style>
