<script setup>
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n({useScope: 'global'});
</script>

<template>
  <div class="video-container" @click="muteVideo" >
    <div class="text-container">
      <div class="sub-text-container">
        <h1>{{$t('Header.vacancy')}}</h1>
        <h4>
          Подберите подходящую работу для себя <br/>
          и станьте частью команды компании.
        </h4>
        <button @click="(e) => {scrollTo('vacancySelection'); e.stopPropagation()}" class="readMore">
          <img src="../assets/icons/ArrowButton.png" alt="">
          {{ $t('Buttons.readMore') }}
          <img src="../assets/icons/ArrowButton.png" alt="">
        </button>
      </div>
    </div>
    <div class="over-gradient">
    </div>
    <iframe style="pointer-events: none" src="https://www.youtube.com/embed/Sdq_jDyfGMo?mode=opaque&wmode=transparent&autoplay=1&loop=1&playlist=Sdq_jDyfGMo&controls=0&modestbranding=1&rel=0&autohide=1&showinfo=0&color=white&iv_load_policy=3&theme=light&wmode=transparent&mute=1" class="video_player" width="1900" frameborder="0" allow="autoplay"></iframe>
    <img class="background-image" src="@/assets/backgrounds/vacancy.png">
    <!-- <iframe width="1280" height="720" src="" title="Трансмаш набирает специалистов" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
  </div>
</template>

<script>
  export default {
    name: "VideoHeader",
    data(){
      return {
        
      }
    },

    props: {
      url: {
        type: String,
        default: '',
        mute: false,
      }
    },

    methods: {
      scrollTo(elementID){
        let DOMelement = document.getElementById(elementID)
        DOMelement.scrollIntoView({block: "start", behavior: "smooth"});
        // this.picked = elementID
      },

      muteVideo(){
        if(!this.mute && window.innerWidth >= 900){
          document.getElementsByClassName('video_player')[0].src = "https://www.youtube.com/embed/Sdq_jDyfGMo?mode=opaque&wmode=transparent&autoplay=1&loop=1&playlist=Sdq_jDyfGMo&controls=0&modestbranding=1&rel=0&autohide=1&showinfo=0&color=white&iv_load_policy=3&theme=light&wmode=transparent"
        } else {
          document.getElementsByClassName('video_player')[0].src = "https://www.youtube.com/embed/Sdq_jDyfGMo?mode=opaque&wmode=transparent&autoplay=1&loop=1&playlist=Sdq_jDyfGMo&controls=0&modestbranding=1&rel=0&autohide=1&showinfo=0&color=white&iv_load_policy=3&theme=light&wmode=transparent&mute=1"
        }
        this.mute = !this.mute;
      },

    },


    mounted(){

      // window.addEventListener('mousemove', () => {
      //   document.getElementsByClassName('video_player')[0].click()
      // })

      // setTimeout(() => {
      //   alert(1);
      //   document.getElementsByClassName('video_player')[0].click();
      // }, 3000)
    }
  }
</script>

<style scoped>
  .video-container  {
    width: 100vw;
    height: 80vh;

    background-color: black;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  }

  .video_player {
    height: 100%;
  } 
  
  iframe {
    min-width: calc(1920px * 0.725);
  }

  .background-image{
    display: none;
  }

  @media (max-width: 900px) {
    iframe {
      display: none;
    }

    .background-image{
    display: flex;
    background-image: '../assets/backgrouns/vacancy.png';
    /* background-color: aquamarine; */
    height: 100vh;
    width: 100vw;
    min-width: 450px;
    background-repeat: no-repeat;
    background-attachment: fixed;
        background-size: cover;
        box-sizing: border-box;
        background-position: center;


  }

    .text-container {
      align-items: center;
    }

    .video-container {
      justify-content: center !important;
      height: 100vh;
      
    }

    .sub-text-container {
      min-width: 100vw !important;
      justify-content: center !important;
      align-items: center !important;

    }

    h1 {
      text-align: center;
    }

    h4 {
      text-align: center;
    }
  }

  .over-gradient {
    width: 100%;
    height: 100%;
    background: black;
    position: relative;
    
    display: flex;
    align-items: center;
  }

  .over-gradient::after {
    content: '';
    width: 100%;
    height: 100%;

    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0, 0, 0, 0) 100%);

    position: absolute;
    transform: translateX(99%);

  }

  .text-container {
    width: 100vw;
    position: absolute;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: end;
  }

  .sub-text-container {
    width: 95%;
    height: 75%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    
    gap: 64px;
  }

  @keyframes opacity-up {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  h1 {
    text-transform: uppercase;
    opacity:0;
    width: 85%;
    font-size: 98px;
    color: white;
    /*line-height: .88;*/

    overflow: hidden;
    z-index: 20;

    animation: opacity-up 0.6s ease-in-out forwards;
    animation-delay: 0.6s;
  }

  h4 {
    text-transform: uppercase;
    opacity:0;
    width: 85%;
    font-size: 22px;
    font-weight: 100;
    color: white;
    /*line-height: .88;*/
    animation: opacity-up 0.6s ease-in-out forwards;
    animation-delay: 1.2s;
    overflow: hidden;
    z-index: 20;
  }

  .readMore {
    /*position: absolute;*/
    /*bottom: 0;*/
    color: white;
    font-size: 20px;
    z-index: 20;
    /*left: 100px;*/
    cursor: pointer;
    height: 38px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-transform: uppercase;
    width: 250px;
    background: none;
    border: 1px solid white;
    border-radius: 15px; 
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent; 
  }
@media (max-width: 700px) {
  h1 {
    font-size: 60px;
  }
}
@media (max-width: 420px) {
  h1 {
    font-size: 50px;
  }
}
</style>
