<template>

    <div class="container-modal" :class="clicked === true ? 'container-modal' : 'container-modal-none' ">
        <div class="sub-container-modal">
            
            <div v-if="timerCount == 10 && !manyRequests" class="header-text">
                Вы уверены, что хотите скачать: "{{ title }}"?
                
            </div>
            <div class="button-container" v-if="timerCount == 10 && !manyRequests">
                <button class="confirm-button" @click="confirmDownload">Да</button>
                <button class="close-button" @click="this.$emit('getClicked')">Нет</button>
            </div>
            <div class="loading-container" v-else-if="!manyRequests">
                <img class="loading" src="@/assets/icons/loading.svg" alt="">
                <span>Скачивание начнется через: {{ timerCount }}</span>
                <div class="none-container"></div>
            </div>
            <div v-if="manyRequests" class="loading-container">
                <span>Одновременно скачивается больше пяти файлов. Подождите.</span>
                <button class="close-button" @click="this.$emit('getClicked')">Закрыть</button>

            </div>
           
        </div>
    </div>

</template>

<script>


export default {

    name: 'ModalSafety',

    data() {
        return {
            click: true,
            timerEnabled: false,
            timerCount: 10
        }
    },

    
    props: {
        getClicked: Function,
        clicked: Boolean,
        download: Function,
        manyRequests: Boolean,
        title: {
            default: ''
        },
    },

    

    methods: {
        confirmDownload() {
            this.timerEnabled = true
        }
        
    },

    watch: {

        timerEnabled(value) {
            if (value) {
                setTimeout(() => {
                    this.timerCount--;
                }, 1000);
            }
        },

        timerCount: {
            handler(value) {

                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);

                }else if(value == 0){
                    this.$emit('download')
                    this.$emit('getClicked')
                    

                    this.timerEnabled = false
                    this.timerCount = 10

                } 

            },
        immediate: true 

    },
  },
}


</script>

<style scoped>
.container-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.34);
}

.container-modal-none {
    display: none;
}

.sub-container-modal {
    display: flex;
    max-width: 700px;
    padding-top: 20px;
    align-items: center;
    /*justify-content: center;*/
    flex-direction: column;
    width: 100%;
    justify-content: center;
    background-color: #FFF;
    border: 2px solid #C9C9C9;
    padding-bottom: 30px;
    border-radius: 15px;
    position: absolute;
}

.header-container {
    width: 100%;
    display: flex;
    justify-content: center;

}




.header-text {
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding-bottom: 40px;
}

h2 {
    text-transform: uppercase;
}

.button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70%;
    column-gap: 2vw;
}

.confirm-button{
    color: white;
    background-color: #1D71B8;
    height: 40px;
    width: 250px;
    border-radius: 7px;
    border: 2px solid #1D71B8;
    text-align: center;
    font-size:18px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%; 
    cursor: pointer;
    transition: 0.3s;

}

.confirm-button:hover{
    transform: scale(1.03);
    transition: 0.3s;
}

.close-button{
    color: #1D71B8;
    background-color: white;
    height: 40px;
    width: 250px;
    border-radius: 7px;
    border: 2px solid #1D71B8;
    text-align: center;
    font-size:18px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%; 
    cursor: pointer;
    transition: 0.3s;

}

.close-button:hover{
    transform: scale(1.03);
    transition: 0.3s;
}

.loading-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.loading, .none-container{
    height: 40px;
    width: 40px;
}




@media (max-width: 740px) {
    .sub-container-modal {
        width: 90%;
        /*height: 500px;*/
        justify-content: center;
    }

    .exit-container {


        padding-bottom: 10px;
    }

    .btn-container {
        padding-bottom: 2px;
        padding-top: -2px;
    }

    .header-text {
        row-gap: 10px;
    }

    h2, h3, .tel-text, .email-text, .zakaz, .btn-container, .exit-container {
        overflow: hidden;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
    }

    p {
        font-size: 14px;
        overflow: hidden;
    }

    .btn {
        height: 40px;
    }

}

@media (max-width: 320px) {
    h2 {
        font-size: 16px;
    }

    h3 {
        font-size: 14px;
    }
}
</style>
