<template>
    <div class="containerHistoryAbout">


      <div class="subContainerHistoryFlex">
        <div class="subContainerHistory">
            <div class="containerFlex">
              <img class="img" src="@/assets/aboutPage/historyAbout.webp"
                   alt=""
              >
              <div class="containerText">
                <h2>
                  {{$t('AboutPage.history.historyTitle')}}
                </h2>

                <div class="aboutText">
                  <p>
                    <span>
                        {{$t('AboutPage.history.historyText-first')}}
                    </span>
                    {{$t('AboutPage.history.historyText-two')}}
                  </p>

                  <p>
                    {{$t('AboutPage.history.historyText-three')}}
                  </p>

                  
                </div>
              </div>
            </div>

          <div class="bottomText">
            <p>{{$t('AboutPage.history.historyText-four')}}</p>
          </div>
          <img class="img_displayNone" src="@/assets/aboutPage/historyAbout.webp" alt="">
      </div>


        
    </div>


  </div>



</template>

<script>
export default {
  name: "HistoryAbout"
}
</script>

<style scoped>
    .containerHistoryAbout {
        width: 100vw;
        
        padding-top: 35px;
        display: flex;
        /*justify-content: center;*/
        align-items: center;
        color: var(--text);
        background: var(--about-bg);
        scroll-snap-align: center;
        flex-direction: column;
        padding-bottom: 40px;
        
    }
    .subContainerHistory {
        width: 100%;
        /*column-gap: 30px;*/
        display: flex;
        flex-direction: column;
        /*justify-content: center;*/
        

    }

    .subContainerHistoryFlex{
      display: flex;
      max-width: 1000px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .containerFlex {
      display: flex;
      flex-direction: row-reverse;
      
      column-gap: 20px;
    }
    h2 {
        font-size: 48px;
        text-transform: uppercase;
        font-weight: 400;
        margin-top: -1px;
    }
    .img {
        
        box-shadow: var(--about-page-box-shadow);
        height: 396px;
        margin: 10px 0;
        border-radius: 5px;
        width: 460px;
    }

    .img_displayNone{
      display: none;
      border-radius: 5px;
      box-shadow: var(--about-page-box-shadow);
    }

    .containerText {
      display: flex;
      width: 60%;
      flex-direction: column;
      row-gap: 20px;
      align-items: start;
    }





    .aboutText {
      row-gap: 20px;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }

    p {
      font-size: 16px;
    }
    span {
      color: var(--blue);
      font-size: 16px;
    }
    .bottomText {
      display: flex;
      
      width: 122%;
    }
    .bottomText > p {
      padding-top: 10px;
      width: 79%;
    }

    @media (max-width: 1020px){
      /* .subContainerHistoryFlex{
        padding-left: 20px;
        padding-right: 20px;
        
      }  */
      
    }

    @media (max-width: 1000px){
      .img{
        display: none;
      }

      .subContainerHistoryFlex{
     
      padding-left: 5vw;
    }

      .img_displayNone{
        display: block;
        margin-top: 20px;
        width: 90vw;
        height: 560px;
       
      }

      .containerText{
        width: 100%;
      }

      .containerHistoryFlex{
        width: 90vw;
      }

      
    }

    @media (max-width: 500px){
      .img_displayNone{
        height: 300px;
      }

      .containerHistoryAbout{
        margin-left: 0px;        
      }
    }

    
</style>