<template>
    <div class="newsPageContainer">
        <Header theme="light" page="undefined"/>

        <NewsSlider />

        <div class="containerButton" id="newsScroll">
            <div class="subContainerButton">
                <button :style="!borderButton ? {borderColor: `var(--text)`} : {}"
                        @click="borderButton= false">Последнее
                </button>
                <button :style="borderButton ? {borderColor: `var(--text)`} : {}" @click="borderButton = true">
                    Все
                </button>
            </div>
        </div>

        <SceletonNewsFullPreview
            v-if="newsAllArray?.length == 0 && borderButton"
            v-for="(title, index) in 3"
        />

        <NewsFullPreview
            v-else
            v-for="(item, index) in borderButton  ? newsAllArray : newsArray"
            v-intersect="(entry) => onIntersect(entry)" 
            :newsObject="item"
            :user="user"
        />

        <Pagination v-if="borderButton === true"
                    :totalPage="TotalPage"
                    @getPage="updatePagination"
                    @updatePage="updatePagination"
        />
        <div class="create-container">
          <router-link to="/createNews" class="create-sub-container"  v-if="user == 'admin'">
            <img
                src="@/assets/icons/add_product.svg"
                alt=""
                class="add-icon"

                >
            <img/>
          </router-link>
        </div>
        <Footer/>

    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import MicroSlider from "@/components/MicroSlider.vue";
import NewsPreview from "@/components/NewsPreview.vue";
import NewsFullPreview from "@/components/NewsFullPreview.vue";
import {HTTP} from "@/plugins/http-common";
import NewsSlider from "@/components/NewsSlider.vue";
import SceletonNewsSlider from "@/components/Sceletons/SceletonNewsSlider.vue";
import SceletonNewsFullPreview from "@/components/Sceletons/SceletonNewsFullPreview.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import {useHead} from "@vueuse/head"
import store from "@/store";

export default {
    name: "NewsPage",
    components: {
        Pagination,
        NewsSlider,
        SceletonNewsSlider,
        NewsFullPreview,
        SceletonNewsFullPreview,
        NewsPreview,
        Header,
        Footer,
        MicroSlider
    },
    data() {
        return {
            borderButton: false,
            currentPage: 1,
            TotalPage: 1,
            size: 1,
            reuestState: true,
            newsArray: [{}, {}, {}, {}],
            newsAllArray: [],
            user: 'user',
        }
    },
    props: {},
    created() {
        HTTP.get(`/news/get_pages`).then(response => {
                for (let i in response.data) {
                    this.TotalPage = response.data.pages
                    
                }
            }).catch(err => {}
        )
    },
    methods: {
        updatePagination(page) {
            this.newsAllArray = []
            HTTP.get(`/news/get_all_news/${(page - 1) * 3}/3`).then(response => {
                    this.newsAllArray = response.data.data
                    document.getElementsByClassName('newsPageContainer')[0].scrollTop = 700
                    for (let index = 0; index < this.newsAllArray.length; index++) {                        
                        const imagePath = JSON.parse(this.newsAllArray[index].image).image;
                        const formData = new FormData();
                        formData.append('image', imagePath);
                        HTTP.post('/get_image/download_image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        responseType: 'blob'
                        }).then(responseImage => {
                            const url = URL.createObjectURL(responseImage.data);  
                            this.newsAllArray[index].image = url
                                    
                        }).catch(error => {
                            console.error('Ошибка при загрузке изображения:', error);
                        });
                    }

                }
            ).catch()
        },

        onIntersect(entry) {
            if (this.reuestState && this.newsArray.find((e) => Object.keys(e).length === 0)) {
                if (Object.keys(this.newsArray[this.size -1])?.length === 0) {
                    this.reuestState = false
                    HTTP.get(`/news/get_all_news/${this.size - 1}/1`).then(response => {
                        this.newsArray[this.size -1] = response.data.data[0]
                        this.reuestState = true
                        this.size += 1;

                    }).catch(error => {
                        console.error('Error fetching news:', error);
                    });
                }
                
            }
            
        },
    },
    directives: {
        intersect: {
        beforeMount(el, binding) {
            const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
            };

            const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                binding.value(entry);
                }
            });
            }, options);

            el._observer = observer;
            observer.observe(el);
        },
        unmounted(el) {
            if (el._observer) {
            el._observer.disconnect();
            }
        },
        },
    },

    watch: {
        newsArray: {
        handler(newNews, oldNews) {
            const newsIndex = this.size - 2;
            const newsItem = newNews[newsIndex];  


            if (newsItem && Object.keys(newsItem).length !== 0) {
                try {
                    const image = JSON.parse(newsItem.image).image;

                    if (image) {
                        const imagePath = image;
                        const formData = new FormData();
                        formData.append('image', imagePath);
                        HTTP.post('/get_image/download_image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        responseType: 'blob'
                        }).then(responseImage => {
                        const url = URL.createObjectURL(responseImage.data);             
                        this.newsArray[newsIndex].image = url
                                    
                        }).catch(error => {
                        console.error('Ошибка при загрузке изображения:', error);
                        });

                    } else {
                        console.error('Image data is not valid:', newsItem);
                    }
                } catch (e) {
                    
                }
            }
        },
        deep: true
    },
        borderButton(newQuestion, oldQuestion) {
            if (newQuestion) {
                HTTP.get(`/news/get_all_news/0/3`).then(response => {
                    this.newsAllArray = response.data.data

                    for (let index = 0; index < this.newsAllArray.length; index++) {
                        const imagePath = JSON.parse(this.newsAllArray[index].image).image;
                        const formData = new FormData();
                        formData.append('image', imagePath);
                        HTTP.post('/get_image/download_image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        responseType: 'blob'
                        }).then(responseImage => {
                            const url = URL.createObjectURL(responseImage.data);  
                            this.newsAllArray[index].image = url
                                    
                        }).catch(error => {
                            console.error('Ошибка при загрузке изображения:', error);
                        });
                        
                    }

                }).catch(error => {
                    console.error('Error fetching news:', error);
                });
            }
        },

    },
    setup() {

        useHead({
            title: `Новости - ООО «Трансмаш»`,
            meta: [
                {
                    name: `description`,
                    content: `Следите за нашими новостями и обновлениями на сайте, чтобы быть в курсе последних разработок и достижений в производстве горно-шахтного оборудования.`
                },
                {
                    name: `keywords`,
                    content: `новости компании, актуальные новости, трансмаш, инновации, разработка, интересное, услуги, сервис`
                }
            ]

        })
    },

    mounted() {
        // store.commit('checkAdminStatus')
        // Очищаем массив при маунте страницы
        if(localStorage.user) this.user = localStorage.user;      

    },  

}
</script>

<style scoped>
.newsPageContainer {
    position: relative;
    overflow-y: scroll;
    height: 100vh;
    overflow-x: hidden;
}

.containerButton {
    width: 100vw;
    display: flex;
    padding-top: 50px;
    flex-direction: row;
    justify-content: center;
    height: 35px;
    align-items: center;
}

.subContainerButton {
    width: 920px;
    display: flex;
    column-gap: 20px;
    align-items: center;
}

.subContainerButton > button {
    font-weight: 400;
    width: 250px;
    border: none;
    border-bottom: 3px solid;
    border-color: transparent;
    font-size: 25px;
    background: none;
    color: var(--text);
    cursor: pointer;
}

@media (max-width: 1300px) {
    .subContainerButton {
        align-items: center;
        justify-content: center;
    }
}

.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 25px;
    padding-top: 15px;
    padding-bottom: 50px;
}

.ButtonPagination {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.03), 0px 0px 16px rgba(0, 0, 0, 0.01);
}

.page {
    border: none;
    background: none;
    font-size: 25px;
    width: 55px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page.active {
    background: var(--blue);
    width: 55px;
    height: 55px;
    color: white;
    border-radius: 50%;
}

.ButtonPagination.right > img {
    transform: rotate(180deg);
}

@media (max-width: 550px) {
    .subContainerButton {
        width: 90%;
    }
}

.create-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}


.create-sub-container {
        width: 100%;
        max-width: 920px;
        height: 60px;
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: center;
        

       margin: 20px;

        background: var(--card-bg);

        border-radius: 12px;

        border-style: solid;
        border-width: 1px;
        border-image: 
            linear-gradient(
            to bottom, 
            rgba(20, 20, 20, 0.1),
            #ccc, 
            rgba(20, 20, 20, 0.1)
            ) 1 100%;

        box-shadow: var(--card-box-shadow);
        
        
       
    }

</style>