<template>
    <div v-bind:style="{filter: theme == 'light' ? 'invert(0)' : 'invert(1)'}">
        <div @click="switchLanguage(); changeLanguage()" class="header-lang-switcher">{{ language }}</div>
    </div>
</template>

<script setup>
    import { useI18n } from "vue-i18n";
    const { t, locale} = useI18n({useScope: 'global'})

    const switchLanguage = () => {
        locale.value == 'en' ? locale.value = 'ru' : locale.value = 'en';
        localStorage.setItem('language', locale.value);
        // this.language == 'RU' ? this.language = 'EN' : this.language = 'RU'
    }
</script>

<script>
    import store from "@/store"
    
    export default {
        data(){
            return {
                language: localStorage.getItem('language')?.toUpperCase() || 'RU',
                dataTheme: JSON.parse(localStorage.getItem('theme')) || false,
            }
        },

        methods: {
            changeLanguage(){
                this.language == 'RU' ? this.language = 'EN' : this.language = 'RU'
                this.language == 'EN' ? store.commit('changeLanguageEn') : store.commit('changeLanguageRu')
            },
        },

        props: {
            theme: {
                type: String,
                default: 'light'
            }
        }
    }
</script>

<style>
    .header-lang-switcher {
        width: 50px;
        background-image: url(@/assets/icons/lang.png);
        background-size: 16px;

        background-position: center;
        background-position-x: 28px;

        background-repeat: no-repeat;

        color: white;

        cursor: pointer;

        user-select: none;
    }
</style>