<template>
  <button class="carousel-control left"
          @click="$emit('prev')"
  >

    <img class="buttonLeft"
         src="../../assets/icons/arrowLeft.png"
         alt=""
    >

  </button>
  <button class="carousel-control right"
          @click="$emit('next')"
  >

    <img class="buttonRight"
         src="../../assets/icons/arrowLeft.png"
         alt=""
    >

  </button>
</template>

<script>
export default {
  emits: ['prev', 'next'],
  name: "CarouselControls"
}
</script>

<style scoped>
  .carousel-control {
    background: #FFFFFF;
    border: none;
    position: absolute;
    height: 55px;
    width: 55px;
    top: calc(50% - 25px);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent; 
  }
  .left {
    left: 20px;
  }
  .right {
    right: 20px;
  }
  /*img {*/
  /*  width: 15px;*/
  /*  height: 15px;*/
  /*}*/

  .buttonRight {
    transform: rotate(180deg);
  }

</style>