<template>
  <div class="container">
      <div class="sub-container">

          <div class="containerHeader">
            <h2>
              {{ $t('ContactsPage.Requisites.header') }}
            </h2>
          </div>

          <div class="containerBody">

              <h3 v-show="!edit" class="subContainerHeader">
                {{ $store.state.lang == 'ru-RU' ? arrayRequisites.titleRU  : arrayRequisites.titleEN}}
              </h3>
              <input v-show="edit"
                     v-if="$store.state.lang == 'ru-RU'"
                     class="custom-input title"
                     v-model="arrayRequisites.titleRU"
              >
              <input v-show="edit"
                     v-else
                     class="custom-input title"
                     v-model="arrayRequisites.titleEN"
              >


            <div class="firstColumn">

              <div class="column">
                <div class="columnText">
                  <p>
                  {{$t('ContactsPage.Requisites.LegalAddress.name')}}
                </p>
                </div>
                
                <div class="subContainerElementHeader" v-show="!edit">
                  {{ $store.state.lang == 'ru-RU' ? arrayRequisites.legal_addrRU  : arrayRequisites.legal_addrEN}}
                </div>
                <input v-show="edit"
                       v-if="$store.state.lang == 'ru-RU'"
                       class="custom-input header"
                       v-model="arrayRequisites.legal_addrRU"
                >
                <input v-show="edit"
                       v-else
                       class="custom-input header"
                       v-model="arrayRequisites.legal_addrEN"
                >
              </div>

              <div class="column">
                <p>
                  {{$t('ContactsPage.Requisites.PostalAddress.name')}}
                </p>
                <div class="subContainerElementHeader" v-show="!edit">
                  {{ $store.state.lang == 'ru-RU' ? arrayRequisites.mail_addrRU  : arrayRequisites.mail_addrEN}}
                </div>
                <input v-show="edit"
                       v-if="$store.state.lang == 'ru-RU'"
                       class="custom-input header"
                       v-model="arrayRequisites.mail_addrRU"
                >
                <input v-show="edit"
                       v-else
                       class="custom-input header"
                       v-model="arrayRequisites.mail_addrEN"
                >
              </div>
              <!--      ИНН и т.п        -->
              <div class="column">
                <div class="row">
                  <p>
                    {{$t('ContactsPage.Requisites.OGRN')}}
                  </p>
                  <div class="subContainerElement"
                       v-show="!edit"
                  >
                    {{arrayRequisites.ogrn}}
                  </div>
                  <input v-show="edit"
                         class="custom-input"
                         v-model="arrayRequisites.ogrn"
                  >
                </div>

                <div class="row">
                  <p>
                    {{$t('ContactsPage.Requisites.INN')}}
                  </p>
                  <div class="subContainerElement"
                       v-if="!edit"
                  >
                    {{ arrayRequisites.inn }}
                  </div>
                  <input v-show="edit"
                         class="custom-input"
                         v-model="arrayRequisites.inn"
                  >
                </div>
              </div>

              <div class="column">
                <div class="row">
                  <p>
                    {{$t('ContactsPage.Requisites.OKPO')}}
                  </p>
                  <div class="subContainerElement"
                       v-if="!edit"
                  >
                    {{ arrayRequisites.okpo }}
                  </div>
                  <input v-show="edit"
                         class="custom-input"
                         v-model="arrayRequisites.okpo"
                  >
                </div>

                <div class="row">
                  <p>
                    {{$t('ContactsPage.Requisites.KPP')}}
                  </p>
                  <div class="subContainerElement"
                       v-if="!edit"
                  >
                    {{ arrayRequisites.kpp }}
                  </div>
                  <input v-show="edit"
                         class="custom-input"
                         v-model="arrayRequisites.kpp"
                  >
                </div>
              </div>

              <div class="column">
                <div class="row">
                  <p>
                    {{$t('ContactsPage.Requisites.OKVED')}}
                  </p>
                  <div class="subContainerElement"
                       v-show="!edit"
                  >
                    {{ arrayRequisites.okved }}
                  </div>
                  <input v-show="edit"
                         class="custom-input"
                         v-model="arrayRequisites.okved"
                  >
                </div>

                <div class="row">
                  <p>
                    {{$t('ContactsPage.Requisites.p/account')}}
                  </p>
                  <div class="subContainerElement"
                       v-show="!edit"
                  >
                    {{ arrayRequisites.check_acc }}
                  </div>
                  <input v-show="edit"
                         class="custom-input"
                         v-model="arrayRequisites.check_acc"
                  >
                </div>
              </div>
            </div>

              <h3 v-show="!edit" class="subContainerHeader">
                {{ $store.state.lang == 'ru-RU' ? arrayRequisites.bankRU  : arrayRequisites.bankEN}}
              </h3>
              <input v-show="edit"
                     v-if="$store.state.lang == 'ru-RU'"
                     class="custom-input title"
                     v-model="arrayRequisites.bankRU"
              >
              <input v-show="edit"
                     v-else
                     class="custom-input title"
                     v-model="arrayRequisites.bankEN"
              >

            <div class="firstColumnDown">
              <div class="columnDown">
                <p >
                  {{$t('ContactsPage.Requisites.SBER.subName')}}
                </p>
                <div class="subContainerElementDown"
                     v-show="!edit"
                >
                  {{ $store.state.lang == 'ru-RU' ? arrayRequisites.correspond_accRU  : arrayRequisites.correspond_accEN}}
                </div>
                <input v-show="edit"
                       v-if="$store.state.lang == 'ru-RU'"
                       class="custom-input"
                       v-model="arrayRequisites.correspond_accRU"
                >
                <input v-show="edit"
                       v-else
                       class="custom-input"
                       v-model="arrayRequisites.correspond_accEN"
                >
              </div>
            </div>
          </div>
      </div>
    <div v-show="user === 'admin'"
         class="container-button-delete-edit"
    >
      <button v-show="!edit"
              @click="edit = true"
      >
          <img src="@/components/ui/assets/pencil.png" alt="">
      </button>

      <button v-show="edit"
              @click="handleSendRequestRequisites"
      >
        <img src="@/assets/icons/save.svg" alt="">
      </button>
      <button v-show="edit"
              @click="handleCloseRequestRequisites"
      >
        <img src="@/assets/icons/exit.svg" alt="">
      </button>
    </div>
  </div>
</template>

<script>
import {HTTP} from "@/plugins/http-common";

export default {
  name: "ContactsRequisites",
  data() {
    return {
      arrayRequisites: [],
      user: "",
      edit: false,
    }
  },
  methods: {
    handleSendRequestRequisites() {
      HTTP.put(`/requisites/edit_requisites`, {
        title: JSON.stringify({"body_ru": this.arrayRequisites.titleRU, "body_eng": this.arrayRequisites.titleEN}),
        legal_addr: JSON.stringify({"body_ru": this.arrayRequisites.legal_addrRU, "body_eng": this.arrayRequisites.legal_addrEN}),
        mail_addr: JSON.stringify({"body_ru": this.arrayRequisites.mail_addrRU, "body_eng": this.arrayRequisites.mail_addrEN}),
        ogrn: this.arrayRequisites.ogrn,
        okpo: this.arrayRequisites.okpo,
        okved: this.arrayRequisites.okved,
        inn: this.arrayRequisites.inn,
        kpp: this.arrayRequisites.kpp,
        check_acc: this.arrayRequisites.check_acc,
        bank: JSON.stringify({"body_ru": this.arrayRequisites.bankRU, "body_eng": this.arrayRequisites.bankEN}),
        correspond_acc: JSON.stringify({"body_ru": this.arrayRequisites.correspond_accRU, "body_eng": this.arrayRequisites.correspond_accEN})
      }, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(response => {
        this.edit = false
      }).catch(err => {
      })
    },
    handleHttpGet() {
      HTTP.get('/requisites/get_all_requisites').then(response => {
        this.arrayRequisites = {
          titleRU: JSON.parse(response.data.data[0].title).body_ru,
          titleEN: JSON.parse(response.data.data[0].title).body_eng,

          legal_addrRU: JSON.parse(response.data.data[0].legal_addr).body_ru,
          legal_addrEN: JSON.parse(response.data.data[0].legal_addr).body_eng,

          mail_addrRU: JSON.parse(response.data.data[0].mail_addr).body_ru,
          mail_addrEN: JSON.parse(response.data.data[0].mail_addr).body_eng,

          ogrn: response.data.data[0].ogrn,
          okpo: response.data.data[0].okpo,
          okved: response.data.data[0].okved,
          inn: response.data.data[0].inn,
          kpp: response.data.data[0].kpp,
          check_acc: response.data.data[0].check_acc,
          bankRU: JSON.parse(response.data.data[0].bank).body_ru,
          bankEN: JSON.parse(response.data.data[0].bank).body_eng,

          correspond_accRU: JSON.parse(response.data.data[0].correspond_acc).body_ru,
          correspond_accEN: JSON.parse(response.data.data[0].correspond_acc).body_eng,
        }
      }).catch(err => {

      })
    },
    handleCloseRequestRequisites() {
      // this.arrayRequisites = []
      this.edit = false
      this.handleHttpGet()
    }
  },
  created() {
    this.user = localStorage.user
    this.handleHttpGet()
  }
}
</script>

<style scoped>
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--text);
      background: var(--about-bg);
      scroll-snap-align: center;
      column-gap: 10px;
    }

    .sub-container {
      width: 900px;
      column-gap: 30px;
      display: flex;
      border-radius: 15px;
      box-shadow: var(--card-box-shadow);
      background: var(--card-bg);
      flex-direction: column;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 40px;
    }

    h2 {
      font-size: 48px;
      text-transform: uppercase;
      font-weight: 400;
      overflow: hidden;
    }

    .containerHeader {
      width: 100%;
      /*height: 100px;*/
      display: flex;
      padding-top: 50px;
      justify-content: center;
    }

    .container-button-delete-edit {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    .container-button-delete-edit button {
      padding: 4px;
      border-radius: 5px;
      background-color: white;
      border: solid 1px #ccc;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .container-button-delete-edit button img{
      width: 20px;
      height: 20px;
    }
    .containerBody {
      padding: 40px 25px;
      /*height: 100%;*/
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }
    .firstColumn {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    .firstColumnDown{
      
      display: flex;
      flex-direction: column;
    }
    
    .subContainerHeader {
      padding: 5px 0;
      text-align: center;
      font-size: 18px;
      border: 2px solid var(--border-color);
      border-radius: 15px;
      /*background: #A3A3A4;*/
      /*mix-blend-mode: s;*/
    }

    .subContainerElement {
      padding: 5px 20px;
      border: 2px solid var(--border-color);
      border-radius: 15px;
      width: 250px;
      word-wrap: break-word;
      /*background: #A3A3A4;*/
      /*mix-blend-mode: s;*/
    }
    .columnDown .custom-input {
      width: 100%;
    }
    .custom-input.header {
      padding-top: 0;
      width: 90%;
    }
    .custom-input.title {
      text-align: center;
      font-weight: 600;
      font-size: 18px;
    }

    .row .custom-input {
      width: 250px;
    }

    .custom-input {
      border-radius: 15px;
      outline: none;
      padding: 5px 20px;
      font-size: 16px;
      border: 2px solid var(--border-color);
    }
    .subContainerElementHeader{
      padding: 6px 20px;
      border: 2px solid var(--border-color);
      border-radius: 15px;
      width: 90%;
      word-wrap: break-word;
      
    }

    .subContainerElementDown{
      padding: 5px 20px;
      border: 2px solid var(--border-color);
      border-radius: 15px;
      width: 100%;
      word-wrap: break-word;
    }

    .firstColumn > .column {
      display: flex;
      justify-content: space-between;
    }

    .columnDown{
      display: flex;
      align-items: center;
      flex-direction: row;
      column-gap: 10px;
    }

    
    .column > .row {
      display: flex;
      align-items: center;
      column-gap: 55px;
    }
    .row > p {
      overflow: visible;
      width: 50px;
    }

    @media (max-width: 900px){
      
      
      .sub-container{
        max-width: 90%;
        align-items: center;
        justify-content: center;
        
      }

      .firstColumn{
        align-self: center;
        justify-content: center;
      }
      
      .column{
        
        flex-direction: column;
      }

      .columnText{
      width: 100%;
    }

      
    .subContainerElementHeader{
        width: 90%;
        margin-left: 20px;
      }


      .subContainerElement{
        width: 90%;
        
      }
      .subContainerElementDown{
        width: 90%;
        
        
      }

      
    }

    @media (max-width: 760px){
      .subContainerElementHeader{
        width: 90%;
        margin-left: 0px;
      }
    }

    @media (max-width: 550px){

      .sub-container{
        width: 90%;
        
        
      }
      .subContainerElement{
        width: 85%;
        
      }

      .subContainerElementHeader{
        width: 87%;
        margin-left: 0px;
      }

      

      .containerBody{
        align-items: center;
        justify-content: center;
      }

      

      .row > p {
      overflow: visible;
      width: 20px;
    }

      .subContainerHeader{
        width: 100%;
      }

      
    }

    @media (max-width: 450px){
      .subContainerElementHeader{
        width: 85%;
        margin-left: 0px;
      }
    }

    @media (max-width: 400px){
      .subContainerElement{
        width: 50%;
        
      }

      .subContainerElementDown{
        width: 60%;
       
      }

      .firstColumnDown{
        margin-left: 50px;
      }

      .column{
        margin-left: 50px;
      }

      .subContainerHeader{
        width: 80%;
      }

      .subContainerElementHeader{
        width: 75%;
      }

    }

</style>