import axios from 'axios';

export const HTTP = axios.create({
  baseURL: `${  
                window.location.protocol + '//' +
                window.location.host + '/api' + '/v1'
            }`,
  headers: {
    Authorization: 'Bearer {token}'
  }
})
