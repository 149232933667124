<template>
    <div
        v-motion
        :initial="{
            opacity: 0,
        }"
        :visible="{
            opacity: 1,
            transition: {
                delay: 800,
                duration: 300
            }
        }"
        class="circle-container">
        <div class="circle">
            <img :style="{right: '0px'}" v-if="circleType == 'right'" src="@/assets/circles/rightCircle.png" alt="">
            <img :style="{left: '0px'}" v-if="circleType == 'left'" src="@/assets/circles/leftCircle.png" alt="">
            <img v-if="circleType == 'small1'" src="@/assets/circles/miniCircle.png" alt="">
            <img v-if="circleType == 'small2'" src="@/assets/circles/miniCircle2.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "Circle",

        data(){
            return {
                
            }
        },

        props: {
            positionRight: {
                default: 0,
            },
            positionLeft: {
                default: 0,
            },
            positionTop: {
                default: 0,
            },
            positionBottom: {
                default: 0,
            },
            circleType: {
                type: String,
                default: 'small1'
            }
        }
    }
</script>

<style scoped> 
    .circle-container {
        position: absolute;
        pointer-events: none;

        width: 100vw;
        height: 100vh;

    }

    img {
        position: absolute;
        opacity: 0.5;
    }
</style>