<template>
<!--    <button >-->
      <router-link class="button" :to="link">
        {{buttonText}}
        <slot></slot>
      </router-link>
<!--    </button>-->
</template>

<script>
    export default {
        name: "CustomButton",

        props: {
            buttonText: {
                type: String,
                default: () => "Nameless button",
            },
            link: {

              default: () => "404"
            }
        }
    }
</script>

<style scoped>
    .button {
        width: 256px;
        height: 48px;
        font-size: 14px;
        border-radius: 15px;
        
        color: var(--blue);
        border: solid 1px var(--blue);

        text-transform: uppercase;

        text-decoration: none;

        display: flex;
        justify-content: center;
        align-items: center;

        font-family: Geometria_regular;

        background: rgb(0,0,0,0);

        cursor: pointer;

        transition: 0.3s;
    }

    .button:hover {
        letter-spacing: 1.2px;
    }
</style>