<template>
  <div class="backgroundImage"
       :style="{backgroundImage: `url(${require('../assets/aboutPage/historyBackground.webp')})`}"
  >
    <p>
      {{$t('AboutPage.image.aboutHistoryText-bg')}}
    </p>
  </div>
</template>

<script>
export default {
  name: "AboutHistoryBackground"
}
</script>

<style scoped>
  .backgroundImage {
    background-repeat: no-repeat;
    height: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-position: center;
  }
  .backgroundImage p{
    width: 50%;
    font-size: 30px;
    color: #FFFFFF;
    text-align: center;
  }

  @media (max-width: 1000px){
    .backgroundImage p{
        font-size: 26px;
        width: 80%;
    }
  }
  @media (max-width: 600px){
    .backgroundImage p{
        font-size: 24px;
        
    }
  }
  @media (max-width: 450px){
    .backgroundImage p{
        font-size: 22px;
        
    }
  }
  
</style>