<template>
  <div class="container">
    <div class="sub-container">
      <div class="title-container">
        <h1 v-if="title != ''">
          {{title}}
        </h1>
        <div  v-else class="sceleton-title">

        </div>
      </div>
      
      <Carousel :slides="image"/>
      <button class="button" v-on:click="clicked = !clicked" >
        <img src="../../assets/icons/basket.png" alt="">
        {{ $t('Buttons.buy') }}
      </button>
    </div>
  </div>
  

  <Modal :clicked="clicked" :getClicked="getClickedParent" :title="title"/>

</template> 

<script>
import Carousel from "@/components/Slider/Carousel.vue";
import CustomButton from "@/components/ui/CustomButton.vue";
import Modal from "@/components/ui/Modal.vue"

export default {
  name: "HeaderProduct",
  data() {
    return {
     
      clicked: false
    }
  },
  components: {CustomButton, Carousel, Modal},
  props: {
    image: {
      
    },
    title: {
      default: ''
    },
    characteristic: {
      default: ""
    },

  },
  methods: {
    scrollTo(elementID) {
      let DOMelement = document.getElementById(elementID)
      DOMelement.scrollIntoView({block: "start", behavior: "smooth"});
      // this.picked = elementID
    },

    getClickedParent(clicked) {
      this.clicked=clicked
    }
    
  },
  

}
</script>

<style scoped>


  .container {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    align-items: start;
    color: var(--text);
    background: var(--default-bg);
    /*scroll-snap-align: center;*/
  }

  .sub-container {
    width: 1000px;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    row-gap: 20px;
    justify-content: center;
    column-gap: 30px;
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

  .sceleton-title{
    width: 100%;
    height: 48px;

    box-sizing: border-box;

    border-radius: 12px;
    

    cursor: pointer;

    background-position: center;

    background: #eee;
    background: var(--sceleton-card);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;

    box-shadow: 1px 2px 12px rgb(0,0,0,0.3);

    overflow: hidden;

    position: relative;
  }

  .title-container{
    width: 100%;
    display: flex;
  }

  .modal{
    display: none;
  }

  .modal_flex{
    display: flex;
    overflow-y: auto;
  }

  
  
  h1 {
    font-weight: 500;
    font-size: 48px;
    /*line-height: 120%;*/
    overflow: hidden;
  }

  .button {
    width: 50%;
    height: 48px;
    border-radius: 15px;
    font-size: 20px;
    margin: 0 auto;
    color: var(--blue);
    border: solid 1px var(--blue);

    text-transform: uppercase;

    text-decoration: none;
    display: flex;
    column-gap: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Geometria_regular;

    background: rgb(0,0,0,0);

    cursor: pointer;

    transition: 0.3s;
  }

  .button:hover {
    letter-spacing: 2px;
  }

  @media (max-width: 1300px){

    h2,h1{
      text-align: center;
      font-size: 5vh;
      width: 80%;
    }


    .title-container{
      justify-content: center;
    }
    
  }

  @media (max-width: 550px){
    .button{
      font-size: 3vh;
      width: 90%;
    }

    h2, h1{
      text-align: center;
      font-size: 4vh;
      width: 90%;
    }
  } 

  @media (max-width: 400px){
    .button{
      font-size: 2.5vh;
      width: 80%;
    }
  }

</style>