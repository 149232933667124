<template>
  <transition :name="transitionEffect">
    <div class="carousel-item" v-show="currentSlide === index">
<!--      <img src="@/assets/noImage.png" alt="1">-->
      <div
          class="image"
          :style="slide === 1
          ? {backgroundImage: `url(${require('@/assets/noImage.png')})`}
          : {backgroundImage: `url('${slide}')`}"
          :key="index"
      ></div>

    </div>
  </transition>

</template>

<script>
export default {
  name: "CarouselItem",
  props: {
    slide: {
      default: ''
    },
    currentSlide: {
      default: 0
    },
    index: {
      default: 0
    },
    direction: {
      default: ""
    }
  },
  computed: {
    transitionEffect() {
      return this.direction === "right" ? 'slide-out' : 'slide-in'
    }
  },
  updated() {
      // console.log(this.currentSlide)
  }
}
</script>

<style scoped>
.carousel-item {
  position: absolute;
  
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  bottom: 0;
}
img {
  width: 100%;
  border-radius: 15px;
}
.image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("@/assets/noImage.png");
  display: flex;
  justify-content: center;
  align-items: center;
}
.slide-in-enter-active,
.slide-in-leave-active,
.slide-out-enter-active,
.slide-out-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-in-enter-from {
  transform: translateX(-100%);
}
.slide-in-leave-to {
  transform: translateX(100%);
}
.slide-out-enter-from {
  transform: translateX(100%);
}
.slide-out-leave-to {
  transform: translateX(-100%);
}


</style>