<template>
    <div class="new">
        <div class="base64Image"></div>

        <div class="texts-container">
            <div class="sceleton-row"></div>
            <div class="sceleton-row"></div>
        </div>

     </div>

     <div class="new">
        <div class="base64Image"></div>

        <div class="texts-container">
            <div class="sceleton-row"></div>
            <div class="sceleton-row"></div>
        </div>

     </div>

</template>

<script>
    export default {
        name: 'SceletonMicroNews',

       

        data(){
            return {

            }
        }
    }
</script>

<style scoped>
    .new {
        width: 50%;
        
        
        box-sizing: border-box;

        display: flex;

    }

    .base64Image {
        min-width: 128px;
        height: 128px;

        box-sizing: border-box;

        border-radius: 12px;
        

        cursor: pointer;

        background-position: center;

        background: #eee;
        background: var(--sceleton-card);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;

        box-shadow: 1px 2px 12px rgb(0,0,0,0.3);

        overflow: hidden;

        position: relative;
    }

    

    .texts-container {
        display: flex;
        flex-direction: column;
        
        row-gap: 10px;
        padding-left: 24px;
        padding-bottom: 36px;
        margin-right: 20px;
    }

    .sceleton-row{
        width: 370px;
        height: 20px;
        background: #eee;
        background: var(--sceleton-card);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;

    }
    
    @keyframes shine {
  to {
    background-position-x: -200%;
  }
}

    @media (max-width: 800px){
        .new {
        width: 100%;
        height: 100%;
        margin-top: 20px;
    }

    }

    @media (max-width: 400px){
        .sceleton-row{
            width: 200px;
        }
    }
</style> 
