<template>
    <div
        v-bind:style="{filter: theme == 'light' ? 'invert(0)' : 'invert(1)'}"
    >
        <div v-if="isDarkMode"  @click="toggleTheme(false)" class="header-light-switcher"></div>
        <div v-else             @click="toggleTheme(true)" class="header-dark-switcher"></div>
    </div>
</template>

<script>
    import store from "@/store"

    export default {
        data(){
            return {
                isDarkMode: false,
            }
        },

        methods: {
            toggleTheme(theme){
                // console.log(theme);
                this.isDarkMode = theme;
                // console.log('document.documentElement.className = ', this.isDarkMode ? "dark" : "light");
                document.documentElement.className = this.isDarkMode ? "dark" : "light";
                localStorage.setItem('theme', theme);
                this.isDarkMode == true ? store.commit('changeThemeDark') : store.commit('changeThemeLight')
            },
        },

        mounted(){
            this.toggleTheme(JSON.parse(localStorage.getItem('theme')));
            // console.log(JSON.parse(localStorage.getItem('theme')))
        },

        props: {
            theme: {
                type: String,
                default: 'light'
            }
        }
    }
</script>

<style scoped>
    .header-dark-switcher {
        width: 32px;
        height: 32px;

        background-image: url(@/assets/icons/moon.png);
        background-size: 16px;

        background-repeat: no-repeat;
        background-position: center;

        filter: invert(1);

        cursor: pointer;
    }

    .header-light-switcher {
        width: 32px;
        height: 32px;

        background-image: url(@/assets/icons/sun.png);
        background-size: 20px;

        background-repeat: no-repeat;
        background-position: center;

        filter: invert(1);

        cursor: pointer;
    }
</style>