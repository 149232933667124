<template>
  <div ref="sliderContainer" class="slides-container">
    <Header theme="light" page="undefined" :category_type="category_type" />
    <MicroSlider />

    <div class="chess-bg">
      <CatalogCategories
        @category_type_btn="handleCategoryTypeBtn"
        :category_type="category_type"
        :method="category_type"
        :categoriesText="categoriesText"
        @categoryUpdated="getCategories"
      />
    </div>

    <div v-show="category_type !== ''">
      <div class="chess-bg">
        <AllCards :cards="allCardArray" />
      </div>
    </div>

    <div v-show="category_type == ''" v-for="(sliderCard, index) in sliderCardsArray">
      <div class="chess-bg" v-intersect="(entry) => onIntersect(entry, sliderCard)">
        <CardCarousel
          ref="cardCarousel"
          :title="$store.state.lang == 'ru-RU' ? sliderCard.name.replace('_' , ' ') : sliderCard.name_eng.replace('_' , ' ')"
          :description="''"
          :cards="sliderCard.data"
          :link="`/product/`"
          @next-card="handleNextCard(sliderCard)"
          @prev-card="handlePrevCard(sliderCard)"
        />
      </div>
    </div>

    <Footer @categoryEmit="categoryBtn" />
  </div>
</template>

<script>
import { HTTP } from "@/plugins/http-common";

import Header from "@/components/Header.vue";
import MicroSlider from "@/components/MicroSlider.vue";
import CatalogCategories from "@/components/CatalogCategories.vue";
import CardCarousel from "@/components/CardCarousel.vue";
import Footer from "@/components/Footer.vue";
import Modal from "@/components/ui/Modal.vue";
import CustomCard from "@/components/ui/CustomCard.vue";
import AllCards from "@/components/AllCards.vue";
import Breadcrumbs from "@/components/ui/Breadcrumbs.vue";

import { useHead } from "@vueuse/head";
import store from "@/store";

export default {
  components: {
    Header,
    MicroSlider,
    CatalogCategories,
    CardCarousel,
    Footer,
    Modal,
    CustomCard,
    AllCards,
    Breadcrumbs,
  },

  data() {
    return {
      allCategoriesInfoArray: [],
      categoriesText: [],
      category_type: "",
      category_types: "",
      thisCategory: "",
      allCardArray: [],
      sliderCardsArray: [],
      observer_Slider: false,
    };
  },
  props: ["categories"],

  methods: {
    handleCategoryTypeBtn(method) {
      this.category_type = method;
    },

    categoryBtn(categoryType) {
      this.category_type = categoryType;
      document.getElementsByClassName("slides-container")[0].scrollTop = 700;
    },

    getCards(category) {
      this.allCardArray = []
      HTTP.get(`/catalog/get_by_category${category}`)
        .then((response) => {
          this.allCardArray = response.data
          for (let index = 0; index < response.data.length; index++) {
            const imagePath = JSON.parse(response.data[index].image).image[0];
            const formData = new FormData();
            formData.append('image', imagePath);
            HTTP.post('/get_image/download_image', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              responseType: 'blob'
            }).then(responseImage => {
              const url = URL.createObjectURL(responseImage.data);             
              this.allCardArray[index].image = url
                          
            }).catch(error => {
              console.error('Ошибка при загрузке изображения:', error);
            });
          }

        })
        .catch((e) => {
          console.log("нет товара категории видеонаблюдения");
        });
    },

    getCategories() {
      this.sliderCardsArray = []
      HTTP.get("/category/categories").then((response) => {
        this.categoriesText = response.data;
        for (let index = 0; index < response.data.length; index++) {
          this.sliderCardsArray.push({
            name: response.data[index].name_ru,
            name_eng: response.data[index].name_eng,
            data: [],
            arrayPage: 4,
            arrayCount: null
          });
        }
      });
    },

    onIntersect(entry, sliderCard) {
      const FindCategory = this.categoriesText.find((e) => e.name_ru === sliderCard.name)
      if (this.sliderCardsArray.find((e) => e.name === sliderCard.name).data.length === 0) {

        HTTP.get(`/catalog/get_by_category${FindCategory?.name_eng}?page=1&size=3`)
        .then((response) => {
          this.sliderCardsArray.find((e) => e.name === sliderCard.name).data = response.data

          for (let index = 0; index < response.data.length; index++) { 
            const imagePath = JSON.parse(sliderCard.data[index].image).image[0];
            const formData = new FormData();
            formData.append('image', imagePath);
            
            HTTP.post('/get_image/download_image', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              responseType: 'blob'
            }).then(responseImage => {
              const url = URL.createObjectURL(responseImage.data);             
              this.sliderCardsArray.find((e) => e.name === sliderCard.name).data[index].image = url;  
                          
            }).catch(error => {
              console.error('Ошибка при загрузке изображения:', error);
            });
          }
          this.createSliderCards(sliderCard)
        })
        .catch((e) => {
          console.log("нет товара");
        });
      }
    },

    createSliderCards(sliderCard) {
      const FindCategory = this.categoriesText.find((e) => e.name_ru === sliderCard.name)
      
      HTTP.get(`/catalog/count_catalog_by_category/${FindCategory.name_eng}`).then((response) => {

        for (let index = 0; index < response.data.count - 3; index++) {
          this.sliderCardsArray.find((e) => e.name === sliderCard.name).data.push({});
          this.sliderCardsArray.find((e) => e.name === sliderCard.name).arrayCount = response.data.count;
        }
        
        
      });
    },

    handleNextCard(sliderCard) {
      const FindCategory = this.categoriesText.find((e) => e.name_ru === sliderCard.name);
      const FindSliderArray = this.sliderCardsArray.find((e) => e.name === sliderCard.name);

      if (FindSliderArray.data.find((item) => Object.keys(item).length === 0)) {
        const currentPage = FindSliderArray.arrayPage;
        FindSliderArray.arrayPage += 1;
        HTTP.get(`/catalog/get_by_category${FindCategory.name_eng}?page=${currentPage}&size=1`)
          .then(response => {
            if (currentPage >= 0 && currentPage <= FindSliderArray.data.length) {
              this.sliderCardsArray.find((e) => e.name === sliderCard.name).data[currentPage - 1] = response.data[0];

              const imagePath = JSON.parse(this.sliderCardsArray.find((e) => e.name === sliderCard.name).data[currentPage - 1].image).image[0];
              const formData = new FormData();
              formData.append('image', imagePath);
              HTTP.post('/get_image/download_image', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                responseType: 'blob'
              }).then(responseImage => {
                const url = URL.createObjectURL(responseImage.data);             
                this.sliderCardsArray.find((e) => e.name === sliderCard.name).data[currentPage - 1].image = url
                            
              }).catch(error => {
                console.error('Ошибка при загрузке изображения:', error);
              });

            }
          })
          .catch(error => {
            console.error("Error fetching data:", error);
            FindSliderArray.arrayPage -= 1;
          });
      }
    },

    async handlePrevCard(sliderCard) {
      const FindCategory = this.categoriesText.find((e) => e.name_ru === sliderCard.name);
      const FindSliderArray = this.sliderCardsArray.find((e) => e.name === sliderCard.name);

      if (FindSliderArray.data.find((item) => Object.keys(item).length === 0)) {
        const currentCount = FindSliderArray.arrayCount;
        FindSliderArray.arrayCount -= 1;
        HTTP.get(`/catalog/get_by_category${FindCategory.name_eng}?page=${currentCount}&size=1`)
          .then(response => {
            if (currentCount >= 0 && currentCount <= FindSliderArray.data.length) {
              this.sliderCardsArray.find((e) => e.name === sliderCard.name).data[currentCount ] = response.data[0];

              const imagePath = JSON.parse(this.sliderCardsArray.find((e) => e.name === sliderCard.name).data[currentCount].image).image[0];
              const formData = new FormData();
              formData.append('image', imagePath);
              HTTP.post('/get_image/download_image', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                responseType: 'blob'
              }).then(responseImage => {
                const url = URL.createObjectURL(responseImage.data);             
                this.sliderCardsArray.find((e) => e.name === sliderCard.name).data[currentCount].image = url
                            
              }).catch(error => {
                console.error('Ошибка при загрузке изображения:', error);
              });

            }
          })
          .catch(error => {
            console.error("Error fetching data:", error);

          });
      }
    },
  },
  directives: {
    intersect: {
      beforeMount(el, binding) {
        const options = {
          root: null,
          rootMargin: '0px',
          threshold: 0.1,
        };

        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              binding.value(entry);
            }
          });
        }, options);

        el._observer = observer;
        observer.observe(el);
      },
      unmounted(el) {
        if (el._observer) {
          el._observer.disconnect();
        }
      },
    },
  },


  updated() {
    if (this.category_type !== "") {
      this.category_types = "";
    }
  },

  created() {
    const queryParams = new URLSearchParams(window.location.search);
    this.category_type = queryParams.has("categories")
      ? queryParams.get("categories")
      : "";

    this.getCategories();
  },

  watch: {
    category_type(newQuestion, oldQuestion) {
          this.getCards(newQuestion)
        },
  },

  setup() {
    useHead({
      title: `Каталог - ООО «Трансмаш»`,
      meta: [
        {
          name: `description`,
          content: `На странице "каталог" нашего сайта вы найдете широкий выбор горно-шахтного оборудования высокого качества. Каждый товар произведен с использованием новейших технологий и инноваций в отрасли`,
        },
        {
          name: `keywords`,
          content: `горно-шахтное оборудование, выбор оборудования, обслуживание, надежность, эффективность, безопасность, сервис, гарантия качества, трансмаш, товар, каталог`,
        },
      ],
    });
  },
};
</script>

<style scoped>
.slides-container {
  position: relative;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chess-bg {
  display: flex;

  justify-content: center;
}

.chess-bg:nth-child(2n) {
  background: var(--about-bg) !important;
}

.chess-bg:nth-child(2n + 1) {
  background: var(--default-bg) !important;
}
</style>
