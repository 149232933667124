<template>
  <div class="wrapper">
      <Header style="position: relative;" :theme="$store.state.theme == 'light' ? 'dark' : 'light'"/>

      <div class="constructor-container">
          <div class="title">ДОБАВИТЬ ВАКАНСИЮ</div>
          <div class="sub-container">
              <input v-if="language == 'ru'" v-model="vacancyName" type="text" placeholder="Наименование вакансии"
                     class="title-input">
              <input v-else v-model="productNameEng" type="text" placeholder="Product name" class="title-input">

              <div class="pay-count-container">
                <h1>Заработная плата от</h1>
                <input v-model="minPayOut" type="text" placeholder="0"
                  :style="{fontSize: '20px', height: '48px', marginTop: '8px'}" class="title-input">
                <h1>до</h1>
                <input v-model="maxPayOut" type="text" placeholder="0"
                  :style="{fontSize: '20px', height: '48px', marginTop: '8px'}" class="title-input">
                <input type="text" class="other-parameters-select" v-model="salaryType" style="height: 48px; font-size: 21px; margin-top: 10px;" placeholder="В месяц"/>
              </div>

              <div class="other-parameters-container">
                <input type="text" class="other-parameters-select" v-model="workType" placeholder="Тип работы"/>
                <input type="text" class="other-parameters-select" v-model="city" placeholder="Город"/>
                <input type="text" class="other-parameters-select" v-model="timeType"  placeholder="Тип занятости"/>
                <input type="text" class="other-parameters-select" v-model="exp" placeholder="Опыт работы"/>
              </div>

              <CustomCheckbox 
                :style="{padding: '10px 0px 10px 0px'}"
                label="Возможность работы удаленно"
                :value="isDistant" 
                @change="changeIsDistant"
              />
              
              <div class="text-input-container">
                  <div class="text-types-container">
                      <button class="type-pick-button" @click="() => {
                          language == 'ru' ?
                          parseArray.push(['header', ''])
                          :
                          parseArrayEng.push(['header', ''])
                      }">
                          <img src="./assets/header-icon.png" alt="">
                          Заголовок
                      </button>
                      <button class="type-pick-button" @click="() => {
                          language == 'ru' ?
                          parseArray.push(['paragraph', ''])
                          :
                          parseArrayEng.push(['paragraph', ''])
                      }">
                          <img src="./assets/par-icon.png" alt="">
                          Абзац
                      </button>
                      <button class="type-pick-button" @click="() => {
                          language == 'ru' ?
                          parseArray.push(['list', []])
                          :
                          parseArrayEng.push(['list', []])
                      }">
                          <img src="./assets/list-icon.png" alt="">
                          Список
                      </button>
                      <button class="type-pick-button" @click="() => {
                          language == 'ru' ? 
                          parseArray.push(['image', ''])
                          :
                          parseArrayEng.push(['image', ''])
                      }">
                          <img src="./assets/pic-icon.png" alt="">
                          Изображение
                      </button>
                      <button class="type-pick-button" @click="() => {
                          language == 'ru' ? 
                          parseArray.push(['table', []])
                          :
                          parseArrayEng.push(['table', []])
                      }">
                          <img src="./assets/table-icon.png" alt="">
                          Таблица
                      </button>

                  </div>
                  <div class="blocks">

                      <div
                              class="block"
                              v-for="(block, index) in language == 'ru' ? parseArray : parseArrayEng"
                      >

                          <div v-if="block[0] == 'header'">
                              <div class="block-type">Заголовок</div>
                              <textarea v-model="block[1]"></textarea>
                              <button class="delete-button" @click="() => {
                              language == 'ru' ? 
                              parseArray.splice(index, 1) : parseArrayEng.splice(index, 1)
                          }"></button>
                          </div>

                          <div v-if="block[0] == 'paragraph'">
                              <div class="block-type">Абзац</div>
                              <textarea v-model="block[1]"></textarea>
                              <button class="delete-button" @click="() => {
                              language == 'ru' ? 
                              parseArray.splice(index, 1) : parseArrayEng.splice(index, 1) 
                          }"></button>
                          </div>

                          <div v-if="block[0] == 'list'">
                              <div class="block-type">Список</div>
                              <div class="list-array">

                                  <div v-for="(listText, index) in block[1]">
                                      <input type="text" v-model="block[1][index]"
                                             :style="{width: 'calc(100% - 132px)'}">
                                      <button @click="block[1].splice(index, 1)" :style="{width: '128px'}">Удалить
                                      </button>
                                  </div>


                                  <button @click="() => {block[1].push('')}">Добавить пункт</button>
                              </div>
                              <button class="delete-button" @click="() => {
                              language == 'ru' ? 
                              parseArray.splice(index, 1) : parseArrayEng.splice(index, 1) 
                          }"></button>
                          </div>

                          <div v-if="block[0] == 'image'">
                              <div class="block-type">Изображение</div>
                              <input type="file" @change="handleMiniFileInput">
                              <button class="delete-button" @click="() => {
                              language == 'ru' ? 
                              parseArray.splice(index, 1) : parseArrayEng.splice(index, 1)
                          }"></button>
                          </div>

                          <div v-if="block[0] == 'table'">
                              <div class="block-type">Таблица</div>

                              <table class="product-table">
                                  <tr v-for="(tr, index) in block.slice(1)">
                                      <td v-for="(td, jindex) in block.slice(1)[index]">
                                          <input type="text" v-model="block.slice(1)[index][jindex]">
                                          <button class="delete-cell"
                                                  @click="block.slice(1)[index].splice(jindex, 1)"></button>
                                      </td>

                                      <!-- v-if="i == 0" @click="block[1][i].push('')" -->
                                      <button @click="block.slice(1)[index].push('')">+</button>
                                      <button class="delete-cell" @click="block.splice(index+1, 1)"></button>

                                  </tr>
                                  <button @click="block.push([])">+</button>
                              </table>


                              <button class="delete-button" @click="() => {
                              language == 'ru' ? 
                              parseArray.splice(index, 1) : parseArrayEng.splice(index, 1)
                          }"></button>
                          </div>

                      </div>
                  </div>
              </div>

              <!-- <div class="tag-input-container">
                  <div class="tag-list">
                      <div v-for="(tag, index) in tagList" class="tag">
                          <input type="text" v-model="tagList[index]"/>
                          <button class="delete-tag" @click="tagList.splice(index, 1)"></button>
                      </div>
                      <button class="add-tag-button" @click="tagList.push('Новый тэг')">+ Добавить тэг</button>
                  </div>
              </div> -->
          </div>
      </div>

      <div :style="{display: 'flex', justifyContent: 'center', marginBottom: '20px'}">
          <div class="title">ПРЕДПРОСМОТР</div>

      </div>

      <div class="preview-container">
          <div class="sub-preview-container">
              <Header style="position: relative;" :theme="$store.state.theme == 'light' ? 'dark' : 'light'"/>
              <div :style="{marginTop: '20px'}">
                <ParserVacancyFull :data="{
                  id: 1,
                  title: vacancyName,
                  city: city,
                  work_time: timeType,
                  experience: exp,
                  salary_min: minPayOut,
                  salary_max: maxPayOut,
                  created_at: (new Date()).toLocaleDateString(),
                  remote_work: isDistant ? true : null,
                  salary_type: this.salaryType,
                }"/>

                <Parser :header="parseArray"/>
              </div>
              <Footer/>
          </div>
      </div>

      <div class="buttons-container" style="margin-top: -150px;">
          <button class="publicate" @click="public">Опубликовать</button>
          <button class="publicate cancel">Отмена</button>
      </div>


      <Footer/>
  </div>


</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Parser from "@/components/Parser/Parser.vue";
import HeaderProduct from "@/components/Product/HeaderProduct.vue";
import {HTTP} from '@/plugins/http-common'
import CustomCheckbox from '../../components/ui/CustomCheckbox.vue';
import store from "@/store";
import ParserVacancyFull from "@/components/Parser/ParserVacancyFull.vue";

export default {
  name: "VacancyCreator",
  components: {
      Header,
      Footer,
      Parser,
      HeaderProduct,
      CustomCheckbox,
      ParserVacancyFull
  },

  data() {
      return {
          vacancyName: 'Наименование вакансии',
          productNameEng: 'Vacancy name',

          headerImageBase64: [],
          uploadedNames: [],
          isDragging: false,
          parseArray: [],
          parseArrayEng: [],
          category: '',
          language: 'ru',
          tagList: [],
          pageDescription: '',
          minPayOut: null,
          maxPayOut: null,
          isDistant: false,
          city: '',
          workType: '',
          timeType: '',
          exp: '',
          salaryType: 'В месяц',
      }
  },

  methods: {

      public() {

          // this.console.log({
          //     image: JSON.stringify({"image": JSON.parse(JSON.stringify(this.headerImageBase64))}),
          //     title: JSON.stringify({"title_ru": this.productName, "title_eng": this.productNameEng}),
          //     category: JSON.stringify({"category_eng": this.category, "category_ru": this.category}),
          //     body: JSON.stringify({"body_ru": JSON.parse(JSON.stringify(this.parseArray)), "body_eng": JSON.parse(JSON.stringify(this.parseArrayEng))}),
          //     description: JSON.stringify({"description": this.pageDescription}),
          //     keywords: JSON.stringify({"keywords": JSON.parse(JSON.stringify(this.tagList))}),
          // })

          HTTP.post('/vacancies/create_vacancy', {
              title: this.vacancyName,
              salary_min: this.minPayOut,
              salary_max: this.maxPayOut,
              salary_type: this.salaryType,
              work_type: this.workType,
              city: this.city,
              work_time: this.timeType,
              experience: this.exp,
              body: JSON.stringify(this.parseArray),
              remote_work: this.isDistant ? 1 : null,
          }, {
              headers: {
                  'Content-Type': 'multipart/form-data',
              }
          })
          .then(function (response) {
              console.log("Опубликовано!");
              window.open('/vacancy', '_self')
          })
      },

      changeIsDistant(){
        // alert(1);
        this.isDistant = !this.isDistant;
      },

      handleDrop(event) {
          // console.log(JSON.stringify(event.dataTransfer.files[0]))
          console.log('drop!')
          event.preventDefault();
          // console.log(event.dataTransfer.files[0]);
          const files = event.dataTransfer.files;
          console.log('Files!: ', files);
          for (let i = 0; i < files.length; i++) {
              const file = files[i];
              if (file.type.startsWith('image/')) {
                  console.log('yep, thats an image')
                  const reader = new FileReader();
                  reader.onload = (e) => {
                      console.log('alredy upload!')
                      this.uploadedNames.push(file.name)
                      this.headerImageBase64.push(e.target.result)

                  };
                  reader.readAsDataURL(file);
              }
          }
          this.isDragging = false;
      },

      handleDragEnter(event) {
          event.preventDefault();
          this.isDragging = true;
      },

      handleDragLeave(event) {
          event.preventDefault();
          this.isDragging = false;
      },

      triggerFileInput() {
          this.$refs.fileInput.click(); // вызов события click на скрытом инпуте файла
      },

      handleFileInput(event) {
          const files = event.target.files;
          this.uploadFiles(files);
      },

      handleMiniFileInput(event) {
          const files = event.target.files;
          this.uploadMiniFiles(files);
      },

      uploadFiles(files) {
          for (let i = 0; i < files.length; i++) {
              const file = files[i];
              if (file.type.startsWith('image/')) {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                      // console.log(file);
                      this.uploadedNames.push(file.name)
                      this.headerImageBase64.push(e.target.result)
                  };
                  reader.readAsDataURL(file);
              }
          }
      },

      uploadMiniFiles(files) {
          for (let i = 0; i < files.length; i++) {
              const file = files[i];
              if (file.type.startsWith('image/')) {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                      // console.log(file);
                      // this.uploadedNames.push(file.name) 
                      // this.headerImageBase64.push(e.target.result) 
                      for (let j = 0; j < this.parseArray.length; j++) {
                          if (this.parseArray[j][0] == 'image' && this.parseArray[j][1] == '') {
                              this.parseArray[j][1] = e.target.result
                          }
                      }
                      // this.console.log(e.target.result)
                  };
                  reader.readAsDataURL(file);
              }
          }
      }
  },

  mounted() {
      store.commit('checkAdminStatus')

      function checkUser() {
          if (localStorage.getItem('user') != 'admin') {
              window.open("/admin", "_self");
          }
      }

      checkUser();
  },

  computed: {
      console: () => console,
      window: () => window,
  }

}


</script>

<style scoped>

.pay-count-container {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 22px;
  white-space: nowrap;
}

.pay-count-container > .title-input {
  max-width: 100%;
}

.other-parameters-container {
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;
  gap: 22px;
}

.other-parameters-select {
  width: 100%;
  padding: 5px;

  border: solid 1px #C9C9C9;
  border-radius: 5px;

  box-sizing: border-box;
}

.select-category {
  width: 100%;
  font-size: 20px;
  margin-top: 8px;
  padding: 5px 16px;
  border: solid 1px #C9C9C9;

  background: white;
}

.buttons-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 32px;

  margin-top: -200px;

  padding: 32px;
}

.publicate {
  width: 25%;
  height: 56px;

  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 20px;

  background: #1D71B8;
  color: #FFFFFF;
  border-radius: 10px;
}

.cancel {
  background: rgb(0, 0, 0, 0);
  border: solid 1px #C9C9C9;
  color: black;
}

.wrapper {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}

.constructor-container {
  width: 100vw;
  /* height: calc(100vh - 78px); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;

}

.title {
  font-size: 50px;
  font-family: geometria_light;
  padding: 28px;
  overflow: hidden;
}

.sub-container {
  width: 1300px;
  box-shadow: 0px 0px 12px rgb(0, 0, 0, 0.3);
  overflow: hidden;

  background-color: #F5F7F9;

  padding: 32px;

  /* gap: 32px; */
}

.title-input {
  width: 100%;
  height: 74px;

  border-radius: 12px;
  border: solid 1px #C9C9C9;
  outline: none;
  padding-left: 20px;

  font-size: 50px;
  font-weight: 1000;
  box-sizing: border-box;
}

.image-input-container {
  box-sizing: border-box;
  width: 100%;
  height: 460px;
  border-radius: 12px;
  border: dashed 2px #C9C9C9;
  margin-top: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 8px;
}

.input-image-text {
  font-size: 20px;
  color: rgb(0, 0, 0, 0.4);
}

.input-image-button {
  background-color: #1D71B8;
  font-size: 20px;
  color: white;

  width: 420px;
  padding: 8px 0px;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
}

.text-input-container {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 200px;
  border-radius: 12px;
  border: solid 2px #C9C9C9;
  background: white;
  margin-top: 16px;

}

.preview-container {
  width: 100%;
  height: auto;
  margin-top: -180px;

  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;

}

.sub-preview-container {
  width: 100%;
  height: calc(100% - 80px);
  box-shadow: 0px 0px 12px rgb(0, 0, 0, 0.3);
  transform: scale(0.71);

}

.drag-over {
  transition: 0.2s;
  border-width: 3px !important;
  background-color: rgba(0, 166, 255, 0.1);
  border-color: #1D71B8;
}

.uploaded-images {
  width: 100%;
  height: 64px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  /* background-color: red; */
}

.uploaded-images > div {
  width: 128px;
  height: 32px;

  background: white;
  border-radius: 10px;

  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.3);
  text-overflow: ellipsis;
  white-space: nowrap;

  align-items: center;
  padding-right: 32px;
  padding-top: 8px;
  padding-left: 8px;
  overflow: hidden;
  position: relative;
}

.delete-button {
  width: 16px;
  height: 16px;

  cursor: pointer;

  background-color: rgb(0, 0, 0, 0);
  border: none;
  outline: none;
  background-image: url('./assets/close.png');
  background-size: cover;

  position: absolute;
  right: 10px;
  top: 12px;
}

.text-types-container {
  width: 100%;

  display: flex;
  padding: 16px;

  gap: 16px;

  flex-wrap: wrap;
}

.type-pick-button {
  font-size: 20px;
  background-color: #F5F7F9;
  border: none;
  outline: none;

  padding: 5px 16px;

  border-radius: 100px;

  cursor: pointer;
}

.type-pick-button > img {
  /* width: 20px; */
  height: 14px;

  pointer-events: none;
}

.blocks {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0px 16px;
  gap: 16px;
  position: relative;
  padding-bottom: 16px;
}

.blocks > div {
  position: relative;
}

.block {
  width: calc(100% - 64px);
  padding: 16px;
  background-color: #F5F7F9;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
}

textarea {
  border: none;
  background: none;
  outline: none;
  padding: 16px;
  font-size: 16px;
  resize: none;
  padding-left: 0;
  width: 100%;
}

.block-type {
  font-size: 20px;
  color: #A6A6AC;
}

.list-array {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.product-table {
  gap: 0px;
  margin-top: 8px;
}

.product-table button {
  padding: 8px;
}

.product-table td {
  border: solid 1px #ccc;
  padding: 8px;

}

tr {
  display: flex;
}

td {
  display: flex;
}

.delete-cell {
  background-image: url('@/components/ui/assets/delete.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: solid 0px rgb(0, 0, 0, 0);
  background-color: rgb(0, 0, 0, 0);
}

td > input {
  width: 100%;
  /* padding: 5px; */
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
}

@media (max-width: 1300px) {
  .sub-container {
      width: calc(100vw - 128px) !important;
  }
}

.selects-container {
  width: 100%;
  display: flex;
  gap: 8px;
}

.tag-input-container {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 80px;
  border-radius: 12px;
  border: solid 2px #C9C9C9;
  background: white;
  margin-top: 16px;
  padding: 16px;
  width: 100%;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.tag-list {
  width: 100%;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.add-tag-button {
  background-color: #1D71B8;
  font-size: 20px;
  color: white;

  width: 180px;
  padding: 8px 0px;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;

}

.tag {
  color: #000000;
  background-color: #1d72b81f;
  padding: 8px 32px;
  border-radius: 100px;
  overflow: hidden;
  padding-right: 8px;
  position: relative;
}

.tag::before {
  content: '#';
  position: absolute;
  color: #002c50;
  font-size: 22px;
  left: 10px;
  top: 8px;
}

.tag > input {
  font-size: 20px;
  overflow: hidden;
  width: 140px;
  background: none;
  color: #002c50;
  border: none;
  outline: none;
}

.delete-tag {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-image: url('@/components/ui/assets/delete.png');
  background-size: 19px;
  background-color: rgb(0, 0, 0, 0);
  background-position: center;
  background-repeat: no-repeat;
  border: solid 1px rgb(0, 0, 0, 0);

  position: absolute;
  right: 8px;
}
</style>
