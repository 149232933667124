<template>
  <div class="container">
    <iframe class="map" src="https://yandex.ru/map-widget/v1/?um=constructor%3Aa81891ea5a0e60efc5b6409c1f49649da7897ae85f19c153dfe40d045ba02b5b&amp;source=constructor" width="900" height="400" allowfullscreen="true" frameborder="0"></iframe>
<!--    <MapboxMap-->
<!--        style="height: 400px; width: 900px"-->
<!--        :access-token="MAPBOX_API_KEY"-->
<!--        map-style="mapbox://styles/pimen213/cle58opdw000n01qtyun5sg4f"-->
<!--        :center="[86.197496, 54.719617]"-->
<!--        :zoom="17">-->
<!--      <MapboxMarker :lng-lat="[86.197496, 54.719617]" popup>-->
<!--        <template v-slot:popup>-->
<!--          <p>ООО «ТРАНСМАШ»</p>-->
<!--          <p>652523, г. Ленинск-Кузнецкий, ул. Топкинская, 184</p>-->
<!--        </template>-->
<!--      </MapboxMarker>-->
<!--    </MapboxMap>-->
  </div>
 

</template>

<script>

export default {
  name: "YandexMap",
}
</script>

<style scoped>
p {
  color: #333;
}

.container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.map {
  border-radius: 10px;
  box-shadow: var(--card-box-shadow);
}
@media (max-width: 950px){
  .map{
    width: 90%;
    
  } 


}

</style>
