<template>
    <div class="certificate-container">
        <Header style="position: relative" :theme="$store.state.theme == 'light' ? 'dark' : 'light'"/>
        <CertificateAboutPage :is-download="true" :is-button="false" :array-element="certificates"/>
        <Footer/>
    </div>
</template>

<script>
import CertificateAboutPage from "@/components/CertificateAboutPage.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import {HTTP} from "@/plugins/http-common";

export default {
    name: "Certificate",
    components: {
        CertificateAboutPage,
        Footer,
        Header
    },
    data() {
        return {
            certificates: []
        }
    },
    created() {
        HTTP.get(`/certificate/get_certificate_number/4`).then(response => {
            for (let i = 0; i < response.data.length; i++) {
                this.certificates[i] = {
                    // image: '@/assets/certificate.jpeg',
                    id: response.data[i].id,
                    count: response.data[i].count,
                    titleRU: JSON.parse(response.data[i].device).title_ru,
                    titleEN: JSON.parse(response.data[i].device).title_eng
                }
            }

        })
    }
}
</script>

<style scoped>
.certificate-container {
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100vh;
}
</style>