<template>
    <input ref="el" >
</template>
  
<script>
import { useIMask } from 'vue-imask';

export default {
    
    setup (props) {
    const { el, masked } = useIMask({
        mask: '+ {7}-(000)-000-00-00',
        radix: '.',
    });
    
    return { el };
    },
    
    name: "PhoneInput",
    
}
</script>