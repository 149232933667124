import { createApp } from 'vue'
import VueYandexMetrika from 'vue3-yandex-metrika'
import App from './App.vue'
import router from './router'
import UIComponents from "@/components/ui";
import store from "@/store"
import { MotionPlugin } from "@vueuse/motion"

import { languages, defaulLocale } from './i18n'

import Vue3TouchEvents from 'vue3-touch-events'

import {createI18n, useI18n} from 'vue-i18n'

import { createHead } from '@vueuse/head';


const localStorageLanguage = localStorage.getItem('language');

const messages = Object.assign(languages);
const i18n = createI18n({
    legacy: false,
    locale: localStorageLanguage || defaulLocale, 
    fallbackLocale: 'ru',
    messages
})

const app = createApp(App, {
    setup(){
        const {t} = useI18n()
    }
})

const head = createHead()

UIComponents.forEach(component => {
    app.component(component.name, component)
})

app
    .use(router)
    .use(store)
    .use(i18n)
    .use(MotionPlugin)
    .use(head)
    .use(Vue3TouchEvents)
    .use(VueYandexMetrika, {
        id: 94121453,
        router: router,
        options: {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true,
            ecommerce:"dataLayer"
        },
        //API calls are performed only if env is "production"
        env: 'production'
    })
    

app.mount('#app')


