<template>
  
    <div >
      <div class="container" :style="isDarkTheme ? {background: 'var(--about-bg)'} : {background: 'var(--default-bg)'}"
           v-show="names.length > 0">
        <div class="sub-container">
    
          <div class="sub-container-header">
            <h2>
              {{ title }}
            </h2>
          </div>
    
          <div class="sub-container-body">
    
            <div class="info-block" v-for="(element, index) in names ">
              <div class="image" 
                   :style="{backgroundImage: `url(${require('../assets/pdf.svg')})`}"></div>
              <div class="container-text">
                <p class="text-element" >
                  {{ element }}
                </p>
                
    
              </div>
              <CustomLinkDownload
                  @click="setClicked(index)"
              />
            </div>
            
     
     
          </div>
        </div>
    
      </div>
      <div v-show="arrayElement.length === 0"></div>
      <ModalSafety :clicked="isClicked" 
                   :title="names[this.indexElement]"
                   @getClicked="getClicked"
                   @download="download"
                   :manyRequests="manyRequests"
      />
        
    </div>
   
       
    </template>
    
    <script>
    
    import CustomLinkDownload from "@/components/ui/CustomLinkDownload.vue";
    import ModalSafety from '@/components/ui/ModalSafety.vue'
    import {HTTP} from "@/plugins/http-common";
    import WorkerCreator from "@/pages/AdminPages/WorkerCreator.vue";
    import AddSafetyModal from "./AddSafetyModal.vue";
    
    
    
    export default {
      name: "documentationProductPage",
      components: {
        CustomLinkDownload,
        ModalSafety,
        WorkerCreator,
        AddSafetyModal
    },
      props: {
        title: {
          type: String,
          default: "",
        },
    
        type: {
          type: String,
          default: "pdf"
        },
    
        arrayElement: {
          type: Array,
          default: []
        },

        names: {
          type: Array,
          default: []
        },
    
        isDarkTheme: {
          type: Boolean,
          default: false
        },

    
    
        id: {
            type: Number
        }
      },
      data() {
        return {
          isClicked: false,
          indexElement: -1,
          certificate: "",
          pdf: "",
          user: 'user',
          exitState: false,
          manyRequests: false, 
    
    
        }
      },
      methods: {
        setClicked(index) {
          this.isClicked = true
          this.indexElement = index
        },
        exit(){
          this.exitState = false
        },
        getClicked() {
          this.isClicked = false
          this.manyRequests = false
        },
        
        download() {
            const a = document.createElement('a');
            a.href = this.arrayElement[this.indexElement]
            a.download = this.names[this.indexElement] ;
            document.body.appendChild(a);
            a.click();
            a.remove();
         
    
        },
    
        
    
      },
    
      mounted() {
        // Очищаем массив при маунте страницы
        
        if(localStorage.user) this.user = localStorage.user;
    
      },
    
      updated() {
        // console.log(this.sefetyPdf)
      },

      created() {
        // console.log(this.arrayElement);
      }
    
    
    }
    </script>
    
    <style scoped>
    
    @keyframes shine {
      to {
        background-position-x: -200%;
      }
    }
    .sceleton-container{
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .sceleton-sub-container {
      background: var(--sceleton-card);
      border-radius: 5px;
      background-size: 200% 100% !important;
      animation: 1.5s shine linear infinite;
    
      height: 340px;
      width: 20%;
      margin-top: 20px;
      margin-bottom: 70px;
      box-shadow: 1px 2px 12px rgb(0, 0, 0, 0.3);
    
    }
    
    .sceleton-sub-container-title {
      background: var(--sceleton-card);
      border-radius: 5px;
      background-size: 200% 100% !important;
      animation: 1.5s shine linear infinite;
    
      height: 60px;
      width: 40%;
      margin-top: 20px;
      margin-bottom: 70px;
      box-shadow: 1px 2px 12px rgb(0, 0, 0, 0.3);
    
    }
    
    .text-element {
      word-break: break-word;
      height: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }
    
    .container {
      width: 100vw;
      display: flex;
      justify-content: center;
    
      align-items: center;
      color: var(--text);
      /* background: var(--default-bg); */
      padding-bottom: 30px;
    }
    
    .sub-container {
      width: 1000px;
      column-gap: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    
    .sub-container-header {
      width: 100%;
      min-height: 70px;
      height: 100%;
      max-height: 150px;
      display: flex;
      justify-content: center;
      padding-top: 2vh;
    }
    
    .sub-container-body {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-top: 30px;
      justify-content: center;
    }
    
    .container-text {
      padding: 20px 0;
      height: 60px;
      width: 80%;
    }
    
    .info-block {
      width: 30%;
      min-width: 300px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
    }
    
    .image {
      background-size: contain;
      height: 120px;
      width: 160px;
      margin-top: 45px;
      background-position: center;
      /*background-size: 100% 100%;*/
      background-repeat: no-repeat;
    }
    
    .add-container {
      width: 240px;
        height: 280px;
      
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px;
        justify-content: center;
        background: #E9EDF0;
      
        border-radius: 22px;
        cursor: pointer;
        
        padding-top: 0px;
        padding-bottom: 24px;
    }
    
    .delete-icon{
      cursor: pointer;
      margin-left: 240px;
      margin-top: 2vh;
    }
    
    h2 {
      font-size: 48px;
      color: var(--text);
      font-weight: 500;
      text-align: center;
      overflow: hidden;
    }
    
    @media (max-width: 950px) {
    
    }
    
    </style>