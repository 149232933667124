<template>
  <router-view/>
</template>

<script>

import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head';
import store from "@/store";

export default {
  setup() {
    const siteData = reactive({
      title: `Производство горно-шахтного оборудования - ООО «Трансмаш»`,
      discription: `Выбирая горно-шахтное оборудование у нас, вы можете быть уверены в его качестве и надежности. Мы постоянно совершенствуем нашу продукцию, чтобы соответствовать самым высоким требованиям наших клиентов`,
      keywords: `трансмаш, шахта, оборудование для шахт, горно-шахтное оборудование, производство, надежность, долговечность, эффективность, технологии, инновации, качество, услуги, установка, обслуживание, ремонт, сервис, поддержка`
    })

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.discription)
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords)
        },
      ],
    })
  },
 
  mounted() {
  }

}

</script>

<style>

@import '@/themes/dark.css';
@import '@/themes/light.css'; 


@font-face {
  font-family: geometria_regular;
  src: url(@/assets/fonts/Geometria-Regular.ttf);
}

@font-face {
  font-family: geometria_bold;
  src: url(@/assets/fonts/Geometria-Bold.ttf);
}

@font-face {
  font-family: geometria_light;
  src: url(@/assets/fonts/Geometria-Light.ttf);
}

@font-face {
  font-family: geometria_extrabold;
  src: url(@/assets/fonts/Geometria-ExtraBold.ttf);
}

@font-face {
  font-family: geometria_extralight;
  src: url(@/assets/fonts/Geometria-ExtraLight.ttf);
}

* {
  margin: 0;
  padding: 0;

  font-family: geometria_regular, sans-serif;

  /*overflow-x: hidden;*/
}

::-webkit-scrollbar {
  width: 4px;
  margin-right: 4px;

  position: absolute;
} 

::-webkit-scrollbar-thumb {
  background: rgb(219, 219, 219);
  border-radius: 10px;
  border: none;
}

::-webkit-scrollbar-track {
  background: none;
}

html, body {
  width: 100%;
  height: 100%;

  background-color: var(--default-bg);

  scrollbar-width: thin;
  overflow: hidden;
}

@media (max-width: 700px) {
  * {
    scrollbar-width: none;
    
  }

  *::-webkit-scrollbar { width: 0; }
  
}

</style>
