<template>
    <div class="container">
        <div class="sub-container">
            <h2></h2>
            <div class="slider"></div>
            <button class="button">
                <img src="../../assets/icons/basket.png" alt="">
                {{ $t('Buttons.buy') }}
            </button>
        </div>
    </div>

</template>

<script>
import Carousel from "@/components/Slider/Carousel.vue";
import CustomButton from "@/components/ui/CustomButton.vue";
import Modal from "@/components/ui/Modal.vue"

export default {
    name: "SkeletonHeaderProduct",
    data() {
        return {
            // image: [
            //   'https://picsum.photos/id/1032/1300/450',
            //   'https://picsum.photos/id/1033/1300/450',
            //   'https://picsum.photos/id/1020/1300/450',
            //   'https://picsum.photos/id/1031/1300/450',
            // ],
            clicked: false
        }
    },
    components: {CustomButton, Carousel, Modal},
    props: {
        image: {
            type: Array,
            default: [
                'https://picsum.photos/id/1032/1300/450',
                'https://picsum.photos/id/1032/1300/450',
                'https://picsum.photos/id/1032/1300/450',
                'https://picsum.photos/id/1032/1300/450',
            ]
        },
        title: {
            type: String,
            default: ''
        },
        characteristic: {
            type: String,
            default: ""
        },

    },
    methods: {
        scrollTo(elementID) {
            let DOMelement = document.getElementById(elementID)
            DOMelement.scrollIntoView({block: "start", behavior: "smooth"});
            // this.picked = elementID
        },

        getClickedParent(clicked) {
            this.clicked=clicked
        }

    },


}
</script>

<style scoped>
.container {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    align-items: start;
    color: var(--text);
    background: var(--default-bg);
    /*scroll-snap-align: center;*/
}

.sub-container {
    width: 1000px;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    row-gap: 20px;
    justify-content: center;
    column-gap: 30px;
}

.modal{
    display: none;
}

.modal_flex{
    display: flex;
    overflow-y: auto;
}

.slider {
    background: var(--sceleton-card);
    animation: 1.5s shine linear infinite;
    background-size: 200% 100%;
    height: 500px;
}

h2 {
    font-weight: 500;
    font-size: 48px;
    /*line-height: 120%;*/
    overflow: hidden;
    background: var(--sceleton-card);
    animation: 1.5s shine linear infinite;
    background-size: 200% 100%;
    height: 50px;
}

.button {
    width: 50%;
    height: 48px;
    border-radius: 15px;
    font-size: 20px;
    margin: 0 auto;
    color: var(--blue);
    border: solid 1px var(--blue);
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    column-gap: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    /*font-family: Geometria_regular;*/

    background: rgb(0,0,0,0);

    /*cursor: pointer;*/

    transition: 0.3s;
}

.button:hover {
    letter-spacing: 2px;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

</style>