<template>
  <div>
    <a class="customLink"
      @click="getClicked"
    >
        {{ $t('Buttons.download') }}
    </a>
  </div>
</template>

<script>


export default {
  name: "CustomLinkDownload",
  data () {
    return {
      
    }
  },
  
  
  updated() {
      // console.log(this.type);
  },
  created() {

  },

  methods: {
    getClicked() {
      this.isClicked = true
    },

      
  }
}
</script>

<style scoped>
.customLink {
  width: 256px;
  height: 48px;

  border-radius: 15px;

  color: var(--blue);
  border: solid 1px var(--blue);

  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  font-family: Geometria_regular;

  background: rgb(0,0,0,0);

  cursor: pointer;

  transition: 0.3s;
}

.customLink:hover {
  letter-spacing: 2px;
}
</style>