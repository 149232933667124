<template>
    <div
        class="select"
    >
        <p class="title"
           @click="isOptionsVisible = !isOptionsVisible"
           :class="isOptionsVisible ? `active` : `none`"
           :style="heightTitle !== '' ? {height: heightTitle} : {}"
        >
            {{ selected }}
            <img src="../../assets/icons/arrow.png"
                 class="iconsArrow"
                 :style="$store.state.theme === 'light' ? {} : {filter: `invert(1)`}"
            >
        </p>
        <div class="options"
             v-if="isOptionsVisible"
             :style="widthOption !== '' ? {width: widthOption} : {}"
             :class="isOptionsVisible ? `active` : `none`"
        >
            <p v-for="(option, index) in options"
               :key="index"
               :style="isFullHover ? {width: `calc(100% - 6px)`} : {width:`50px`}"
               @click="selectOption(option)"
            >
                {{option}}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "DropBox",
    data () {
        return {
            isOptionsVisible: false
        }
    },
    props: {
        options: {
            // default: 1
        },
        selected: {
            // default: 0
        },
        widthOption: {

        },
        heightTitle: {

        },
        isFullHover: {
          default: false
        }
    },
    methods: {
        selectOption(option) {
            this.$emit('select', option)
            this.isOptionsVisible = false
        },
        hideSelect(e) {
            if (!document.querySelector(".title").contains(e.target)) {
                // this.isOptionsVisible = false;
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.hideSelect.bind(this), true)

    },
    beforeDestroy() {
        document.addEventListener('click', this.hideSelect)

    }
}
</script>

<style scoped>
    .select {
        position: relative;
        width: clamp(60px, 4vw, 100px);
        text-align: center;
        /*overflow: hidden;*/
        color: var(--text);

        cursor: pointer;
        overflow: visible;
        //z-index: 10;
    }
    .select > p {
        margin: 0;
    }
    .options {
        border: 1px solid #D9D9D9;
        border-top: none;
        /*position: absolute;*/
        /*top: 22px;*/
        /*right: 0;*/
        position: absolute;
        overflow-y: scroll;
        max-height: 120px;
        width: clamp(48px, calc(4vw - 2px), 100px);;
        z-index: 15;
        border-radius: 0 0 10px 10px;
        background: var(--default-bg);
    }
    .options.active {

    }
    .options.none {
        opacity: 1;
    }
    .options > p {
        margin-left: 3px;
        margin-bottom: 3px;
        font-size: 20px;
    }
    .options > p:hover{
        color: white;
        border-radius: 6px;
        background: var(--blue);
    }
    .title {
        display: flex;
        align-items: center;
        position: relative;
        border-radius: 10px;
        border: 1px solid #D9D9D9;
        font-size: 20px;
        padding-left: 14px;
    }
    .title.active {
        border-bottom: none;
      border-radius: 10px 10px 0 0;
    }
    .title.none {
        border-bottom: 1px solid #D9D9D9;
    }

    .iconsArrow {
        width: 10px;
        right: 10px;
        position: absolute;
    }
</style>