<template>

    <div class="backgound-image-container" :style="{backgroundImage: `url(${require('../assets/backgrounds/adminbackground.png')})`}">
        <div class="sub-container" v-bind:class="{ 'shake': wrongData }">
            <h2>ВХОД В АККАУНТ</h2>

            <div class="loginStatus" :style="{opacity: wrongData ? '1' : '0'}">
                Неверный логин или пароль
            </div>

            <form action="" @submit.prevent="onSubmit">
                <input v-model="login" type="text" placeholder="Логин">
                <img v-if="isFlag == true" @click="clickBtnEye" class="eye-off" src="../assets/icons/eye-off.svg" alt="">
                <img v-else="isFlag == true" @click="clickBtnEye" class="eye-on" src="../assets/icons/eye-on.svg" alt="">
                <input v-model="pass" :type="isFlag ? 'password' : 'text'" type="password" placeholder="Пароль">
                <button  @click="submitButton" class="button" type="submit">Войти</button>
            </form>
        </div>
    </div>

     
 </template>
 
 <script>

import { HTTP } from '@/plugins/http-common'
import store from "@/store";

     export default {

        name: 'AdminLogin',
 
         data() {
             return {
                inputType: '',
                isFlag: true,
                pass: '',
                login: '',
                wrongData: false
             }
         },

     

         methods: {
            clickBtnEye() {
                this.isFlag = !this.isFlag
                // console.log(this.isFlag)
                
            },

            submitButton() {
                HTTP.post('/auth/login', {
                    "username": this.login,
                    "password": this.pass
                },{headers: {
                    'Content-Type': 'application/json'
                }})
                .then(function (response) {
                    // console.log("Успешная авторизация", response.data);
                    localStorage.setItem('token', response.data.user.refresh);
                    localStorage.setItem('user', 'admin');
                    window.open("/", "_self");
                }).catch(() => {
                    this.wrongData = true;
                })
            }
          
        },
        created() {
        },


     }
 </script>
 
 <style scoped>
    .backgound-image-container{
        max-width: 100%;
        height: 100vh;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow-x: hidden;
        background-position: center;
        background-size:cover;
        
    }

    @keyframes shake {
        0%, 20%, 40%, 60%, 80% {
            transform: translateX(12px);
        }
        10%, 30%, 50%, 70%, 90% {
            transform: translateX(-12px);
        }
    }

    .shake {
        animation: shake 0.3s ease-in-out;
    }

    .sub-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 570px;
        width: 100%;
        height: 400px;
        background: linear-gradient(180.26deg, rgba(29, 113, 184, 0.15) 0.41%, rgba(29, 113, 184, 0.15) 18.9%, rgba(90, 168, 234, 0.15) 61.43%, rgba(217, 217, 217, 0) 109.3%), rgba(0, 0, 0, 0.75);
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25), 0px 0px 33px rgba(0, 0, 0, 0.09), 37px 109px 46px rgba(0, 0, 0, 0.01), 21px 61px 39px rgba(0, 0, 0, 0.03), 9px 27px 29px rgba(0, 0, 0, 0.04), 2px 7px 16px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0.05), inset 2px 4px 6px rgba(255, 255, 255, 0.08);
        border-radius: 10px;
    }

    h2{
        font-weight: 400;
        font-size: 50px;
        /*line-height: 92%;*/
        overflow: hidden;
        padding-bottom: 20px;
        text-align: center;
        color: #FFFFFF;
    }

    .loginStatus {
        padding-bottom: 20px;
        color: red;
    }

    form{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        overflow: visible;
    }

    input{
        width: 300px;
        height: 40px;
        border-radius: 44px;
        background: transparent;
        border: solid 2px #fff;
        padding-left: 50px;
        outline: none;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        /*line-height: 150%;*/
        background-image: url('../assets/icons/user.svg');
        background-repeat: no-repeat;
        background-position-x:12px ;
        background-position-y:6px ;
        color: #c5c5d6;
    }

    input:nth-child(3){
        background-image: none;
    }

    .eye-off{
        width: 22px;
        height: 16px;
        position: absolute;
        padding-top: 80px;
        padding-left: 12px;
        cursor: pointer;
    }

    .eye-on{
        width: 26px;
        height: 20px;
        position: absolute;
        padding-top: 76px;
        padding-left: 10px;
        cursor: pointer;
    }

    .button{
        margin-top: 20px;
        height: 40px;
        background: transparent;
        border-radius: 15px;
        border: solid 2px #008AFF;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        /*line-height: 123%;*/
        text-align: center;
        color: #008AFF;
        cursor: pointer;
        transition: 0.15s;
    }

    .button:hover {
        transform: scale(1.05);
    }

    @media (max-width: 620px){
        .sub-container{
            width: 90%;
        }

        input{
            width: 200px;
        }

        h2{
            font-size: 34px;
        }
    }
    

 </style>