<template>
 <div
     class="pagination"
  >
   <button class="button left"
           @click="buttonClick(page > 1 ? page -= 1 : page)"
   >
   <img src="../../assets/icons/arrow.png"
        alt="<"
        :style="$store.state.theme === 'light' ? {} : {filter: `invert(1)`}"
   >
   </button>
   <DropBox :options="totalPage"
            @select="optionSelect"
            :selected="page"
            @click="selectedPage(page)"
   />
   <p>из</p>
   <p>{{totalPage}}</p>
   <button class="button right"
           @click="buttonClick(page < totalPage ? page += 1  : page)"

   >
    <img src="../../assets/icons/arrow.png"
         alt=">"
         :style="$store.state.theme === 'light' ? {} : {filter: `invert(1)`}"
    >
   </button>

 </div>
</template>

<script>
import DropBox from "@/components/ui/DropBox.vue";

export default {
    name: "pagination",
    components: {DropBox},
    data () {
        return {
         page: 1,
         lastPage: 1
        }
    },
    props: {
       totalPage: {
         type: Number,
         default: 1
       },

    },
    watch: {
      totalPage() {
        this.page = 1
      }
    },
    methods: {
       optionSelect (option) {
         this.page = option
       },

       selectedPage (page) {
        if (page === this.lastPage) {
          this.lastPage = page
        }
        else {
          this.$emit('getPage', page)
          this.lastPage = page

        }
        // this.isOptionsVisible = false
       },

       buttonClick (page) {
         this.$emit('updatePage', page)
       },

    },

}
</script>

<style scoped>
   .pagination {
     display: flex;
     flex-direction: row;
     justify-content: center;
     align-items: center;
     column-gap: 25px;
     padding-top: 15px;
     padding-bottom: 50px;
     color: var(--text);
   }
   .button {
    width: 55px;
    background: none;
    cursor: pointer;
    border: 1px solid rgba(217, 217, 217, 0.5);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.03), 0px 0px 16px rgba(0, 0, 0, 0.01);
    border-radius: 50%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
   }
   .button > img {
    width: 15px;
    transform: rotate(90deg);
   }
   .button.right > img {
    transform: rotate(270deg);
   }
   p {
    font-size: 20px;
   }
</style>