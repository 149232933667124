<template>
    <div class="container">

    </div>
</template>

<script>
    export default {
        name: 'Burger',
        data(){
            return {
                opened: false
            }
        }
    }
</script>

<style scoped>
    .container {
        display: none;
        position: absolute;

        width: 50px;
        height: 50px;

        top: 20px;
        right: 60px;

        background-color: red;
    }
</style>