 
<template>
  
    <div  class="container">
        

        <div class="image-container">
           
        </div>
        <div class="text-container">
            
        </div>
        
         
            
          
    </div>

</template>

<script>

import {HTTP} from "@/plugins/http-common";


export default {
name: "SkeletonFiltCard",
components: {

},
data() {
return {
// viewIndex: 1,

}
},




} 

</script>

<style scoped>
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.container {
width: 280px;
height: 426px;


}



.image-container {
width: 240px;
height: 300px;

overflow: hidden;


background: var(--sceleton-card);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;

}



.text-container {
    width: 240px;
    height: 40px;
    margin-top: 40px;
    background: var(--sceleton-card);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite;
}




</style>